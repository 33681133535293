import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'nordic/image';

const getSrc = picture => picture?.template?.replace('{id}', picture.id)?.replace('{sanitizedTitle}', '');

const GalleryFallback = ({ accessibility_text, picture }) => (
  <Image className="ui-pdp-gallery ui-pdp-gallery--fallback-boundary" src={getSrc(picture)} alt={accessibility_text} />
);

GalleryFallback.propTypes = {
  accessibility_text: PropTypes.string,
  picture: PropTypes.shape({
    template: PropTypes.string,
    id: PropTypes.string,
  }),
};
export default GalleryFallback;
