import { bool, string, shape, number, arrayOf, func, node } from 'prop-types';
import { Action, Label, Remaining } from '../../../prop-types';

// Namespace
export const namespace = 'ui-pdp-shipping';

// ShippingSummary
export const shippingSummaryPropTypes = {
  action: shape({
    icon: shape({
      id: string,
      color: string,
    }),
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string.isRequired,
    mobileShippingCoachTracks: shape({}),
  }),
  action_tooltip: shape({
    id: string.required,
    content: string.required,
    visible: bool.required,
  }),
  className: string,
  information_tooltip: shape({
    label: Label,
    primary_button: shape({
      Label,
      action_type: string,
    }),
    primary_link: shape({ Action }),
    time_frequency: number,
  }),
  id: string,
  isFetching: bool,
  remaining: Remaining,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      values: shape({
        price: shape({
          type: string,
          value: number,
          original_value: number,
          currency_symbol: string,
        }),
      }),
    }),
  ),
  text: string,
  title: shape({
    text: string.isRequired,
    color: string,
    values: shape(),
    text_icon: shape(),
  }).isRequired,
  onShippingPromiseUpdate: func,
  custom_message: shape({
    state: string,
    body: shape({ test: string }),
    closeable: bool,
    hierarchy: string,
    message_type: string,
  }),
  tooltip: shape({
    side: string,
    content: node,
  }),
  num_font_size: number,
  onCloseModal: func,
  meliplusUpsellingOnSuscription: func,
  meliplusOnDeliveryDayChange: func,
  noTitle: bool,
  shipping_disclaimer: shape({
    id: string,
    closeable: bool,
    title: shape({}),
    has_shadow: bool,
    arrow_type: string,
    storable: bool,
    component_id: string,
  }),
  runCatchErrorBoundary: func,
};
