import componentEnhance from '../../../../lib/component-enhance';

export const getLabelElementSelector = (index, quantitySelector, hasOptions, hasBulkCustomOptions) => {
  if (hasOptions) {
    const {
      selector: {
        template: { options },
      },
    } = quantitySelector;
    return componentEnhance.jsx(options[index - 1].text, options[index - 1].values);
  }

  if (hasBulkCustomOptions) {
    const {
      selector: {
        template: { custom_options },
      },
    } = quantitySelector;

    return componentEnhance.jsx(
      custom_options[index - 1]?.display_value?.quantity?.text || '',
      custom_options[index - 1]?.display_value?.quantity?.values || {},
    );
  }

  return quantitySelector?.selector?.template[(index === 1 ? 'singular' : 'plural')]?.replace('{quantity}', index);
};
