import React, { useState, useContext } from 'react';
import { string, arrayOf, shape, bool, func } from 'prop-types';
import classnames from 'classnames';
import Modal from '@andes/modal';
import StaticPropsContext from '../context/static-props';
import ComponentList from '../component-list';
import ImageComponent from '../image/image-component';
import StyledLabel from '../styled-label/index';
import Separator from '../grid/separator';
import Media from '../media/media';
import Actions from '../buybox/actions/actions';
import { hyphenatedbem } from '../../lib/hyphenatedbem';
import { trackEvent } from '../../lib/tracking';
import withMappedProps from '../../utils/withMappedProps';
import useViewCounter from '../../hooks/use-view-counter';
import { arrayIncludes } from '../../lib/includes';
import { imageToProps } from '../image/image-to-props';
import labelToProps from '../styled-label/label-to-props';
import { mediaToProps } from '../media/media-to-props';
import { emptySeparatorToProps } from '../grid/separator/jsonToProps';

const namespace = 'onboarding';

const HIDDEN_CLOSE_BUTTON_IDS = ['cbt_modal', 'long_term_rental_modal'];

const availableComponents = {};
availableComponents.image_component = withMappedProps(ImageComponent)(imageToProps);
availableComponents.label_component = withMappedProps(StyledLabel)(labelToProps);
availableComponents.separator = withMappedProps(Separator)(emptySeparatorToProps);
availableComponents.generic_info_row = withMappedProps(Media)(mediaToProps);

const Onboarding = ({ id, components, track, storable, state, ariaLabel, type, runCatchErrorBoundary }) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { siteId } = useContext(StaticPropsContext);
    const [isVisible, setIsVisible] = useState(state !== 'TRACK_HIDDEN');
    const initialState = { components };

    const updateCloseStatus = useViewCounter({
      id,
      visible: state === 'TRACK_HIDDEN' ? true : isVisible,
      storable,
      cookieName: 'modal-configuration',
    });

    const handleClose = () => {
      setIsVisible(false);
      if (storable) {
        updateCloseStatus();
      }
      if (track) {
        trackEvent(track);
      }
    };

    const actionsToProps = props => ({
      ...props,
      actions: props.actions.map(action => ({ ...action, onClick: action.target ? null : handleClose })),
    });

    availableComponents.modal_actions = withMappedProps(Actions)(actionsToProps);

    return (
      <div key={id} className={namespace}>
        <Modal
          className={classnames(`${namespace}__modal`, hyphenatedbem(id), {
            [`cbt-modal--short`]: id === 'cbt_modal' && siteId === 'MLB',
          })}
          onClose={handleClose}
          open={isVisible}
          showCloseButton={!arrayIncludes(HIDDEN_CLOSE_BUTTON_IDS, id)}
          srLabel={ariaLabel}
          type={type}
          centsType="superscript"
        >
          <form className={`${namespace}__container`}>
            <ComponentList
              availableComponents={availableComponents}
              initialState={initialState}
              modifier="onboarding"
            />
          </form>
        </Modal>
      </div>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Onboarding.propTypes = {
  id: string.isRequired,
  components: arrayOf(shape({})).isRequired,
  track: shape({}),
  storable: bool,
  state: string,
  actions: arrayOf(shape({})).isRequired,
  ariaLabel: string,
  type: string,
  runCatchErrorBoundary: func,
};

Onboarding.defaultProps = {
  track: null,
  storable: true,
  state: '',
  ariaLabel: '',
  type: 'small',
  runCatchErrorBoundary: () => {},
};

export default React.memo(Onboarding);
