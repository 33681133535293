import React from 'react';
import { loadable } from 'nordic/lazy';
import { PROVIDER, MULTIMEDIA_PROVIDERS } from '../../../../figure/constants';
import Picture from '../picture';

const Clip = loadable(() => import('../clip'));
const Multimedia = loadable(() => import('../multimedia'));

const namespace = 'ui-pdp-gallery';

const getFilteredFigures = ({ filterFiguresForMobile, clipsConfig, galleryType, minHeight, onTapFigure, maxHeight }) =>
  filterFiguresForMobile.map((item, index) => {
    const { provider, id, ...restItem } = item;
    const onClick = e => onTapFigure(item, e);

    if (MULTIMEDIA_PROVIDERS.includes(provider)) {
      return (
        <Multimedia
          key={String(id || index)}
          id={id}
          item={restItem}
          onClick={onClick}
          namespace={namespace}
          galleryType={galleryType}
          provider={provider}
        />
      );
    }
    if (provider === PROVIDER.CLIP) {
      return (
        <Clip
          key={String(id || index)}
          id={id}
          item={restItem}
          onClick={onClick}
          namespace={namespace}
          clipsConfig={clipsConfig}
        />
      );
    }
    if (provider === PROVIDER.IMAGE) {
      return (
        <Picture
          key={String(id || index)}
          id={id}
          item={restItem}
          namespace={namespace}
          index={index}
          galleryType={galleryType}
          maxHeight={maxHeight}
          minHeight={minHeight}
          onClick={onClick}
        />
      );
    }
    return null;
  });
export default getFilteredFigures;
