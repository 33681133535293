import React from 'react';
import { arrayOf, bool, string, shape, func, oneOfType, node, number } from 'prop-types';
import classnames from 'classnames';
import CouponSummary from './coupons';
import TagPills from './_coupon-desktops-pills';
import AwarenessRow from '../coupons-awareness/_awareness-row';
import handleAwarenessInteraction from './_handle-awareness-interaction';

const namespace = 'ui-vpp-coupons';
const CouponsDesktop = ({
  action,
  className,
  onCouponUpdate,
  onCouponActivate,
  showSnackbar,
  icon,
  subtitles,
  title,
  coupon_tags,
  isFetching,
  id,
  snackbar,
  plus_button,
  max_pills_to_show,
  awareness,
  runCatchErrorBoundary,
}) => {
  try {
    const actionModalRef = React.useRef(null);

    const openModal = () => {
      actionModalRef?.current?.openModal();
    };

    return (
      <CouponSummary
        {...{
          action,
          className: classnames(className, {
            [`${namespace}__awareness`]: awareness,
          }),
          onCouponUpdate,
          showSnackbar,
          coupon_tags,
          icon,
          subtitles,
          title,
          isFetching,
          id,
          snackbar,
          handleOnSubtitleClick: () =>
            handleAwarenessInteraction({
              onCouponActivate,
              openModal,
              awareness,
              actionTarget: action?.target,
              isFetching,
            }),
          device: 'desktop',
          actionModalRef,
          actionModalProps: awareness
            ? {
                className: `${namespace}-action-modal__awareness`,
                maxWidth: 576,
                maxHeight: 566,
              }
            : {},
        }}
      >
        {awareness ? (
          <AwarenessRow
            {...{
              onCouponActivate: () =>
                handleAwarenessInteraction({
                  onCouponActivate,
                  openModal,
                  awareness,
                  actionTarget: action?.target,
                  isFetching,
                }),
              awareness,
              checkboxLabelClassName: classnames({ [`${namespace}--cursor-pointer`]: awareness?.is_tappable }),
              pills: coupon_tags,
            }}
          />
        ) : (
          <TagPills
            {...{
              className: `${namespace}__carousel`,
              pills: coupon_tags,
              pillOnClick: openModal,
              id: 'coupons-pills',
              plus_button,
              openModal,
              max_pills_to_show,
            }}
          />
        )}
      </CouponSummary>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

CouponsDesktop.propTypes = {
  coupon_tags: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string,
      onClick: func,
    }),
  ),
  snackbar: shape({}),
  onCouponUpdate: func.isRequired,
  onCouponActivate: func.isRequired,
  showSnackbar: func,
  action: oneOfType([
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string.isRequired,
      onClick: func,
    }),
    node,
    func,
  ]),
  plus_button: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
    onClick: func,
    track: shape({}),
  }),
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  isFetching: bool,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      values: shape({
        price: shape({
          type: string,
          value: number,
          original_value: number,
          currency_symbol: string,
        }),
      }),
    }),
  ),
  title: shape({}).isRequired,
  max_pills_to_show: number.isRequired,
  id: string.isRequired,
  awareness: shape({}),
  runCatchErrorBoundary: func,
};

CouponsDesktop.defaultProps = {
  action: null,
  plus_button: null,
  className: null,
  subtitles: null,
  icon: null,
  isFetching: null,
  onCouponUpdate: null,
  onCouponActivate: null,
  showSnackbar: null,
  snackbar: null,
  max_pills_to_show: null,
  runCatchErrorBoundary: () => {},
};

export default React.memo(CouponsDesktop);
