import React from 'react';
import { string, shape, func } from 'prop-types';

import StyledLabel from '../styled-label';
import MediaAction from '../media/media-action';
import componentEnhance from '../../lib/component-enhance';

const namespace = 'ui-vpp-denounce';

const Denounce = ({ item_info, action, label: codeInternalLabel, runCatchErrorBoundary }) => {
  try {
    return (
      <div className={namespace}>
        {codeInternalLabel && (
          <StyledLabel
            key={codeInternalLabel.text}
            as="p"
            className={`${namespace}__code-internal`}
            {...codeInternalLabel}
          />
        )}
        <StyledLabel
          as="p"
          color={item_info.color}
          className={`${namespace}__info`}
          text={componentEnhance.jsx(item_info.text, item_info.values)}
        />
        {action && (
          <MediaAction
            target={action.target}
            htmlTarget="_blank"
            track={action.track}
            className={`${namespace}__action`}
            label={{ text: action.label.text }}
            accessibilityText={action.accessibility_text}
          />
        )}
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Denounce.propTypes = {
  item_info: shape({
    text: string.isRequired,
    color: string,
    values: shape({}).isRequired,
  }).isRequired,
  label: shape({
    text: string,
    color: string,
  }),
  action: shape({
    target: string.isRequired,
    accessibility_text: string,
    track: shape({}),
    label: shape({
      text: string,
    }).isRequired,
  }),
  runCatchErrorBoundary: func,
};

Denounce.defaultProps = {
  action: null,
  runCatchErrorBoundary: () => {},
};

export default Denounce;
