/**
 * Module dependencies
 */
import React from 'react';

import { UserConsumer } from './user';
import getDisplayName from '../../../lib/get-display-name';

/**
 * withUser
 */
const withUser = Component => {
  const WithUser = props => <UserConsumer>{user => <Component {...props} currentUser={user} />}</UserConsumer>;
  WithUser.displayName = `WithUser(${getDisplayName(Component)})`;
  return WithUser;
};

/**
 * Expose withUser
 */
export default withUser;
