import { CalculatorDesktop, CalculatorMobile } from '@hi-components-web/calculator';
import React, { useContext } from 'react';
import { UserConsumer } from '../context/user';
import PropTypes from 'prop-types';

const CalculatorWrapper = ({ runCatchErrorBoundary, ...props }) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { device, redirectToLogin, item_id } = props;
    const userContext = useContext(UserConsumer);
    const isLoggedIn = Boolean(userContext?.nickname);
    const handleClick = e => {
      if (!isLoggedIn) {
        e.preventDefault();
        e.stopPropagation();
        redirectToLogin(item_id);
      }
    };
    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        handleClick(e);
      }
    };
    const Calculator = device === 'desktop' ? CalculatorDesktop : CalculatorMobile;
    return (
      <div
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        className={!isLoggedIn ? 'calculator-wrapper--not-logged' : ''}
      >
        <div className={!isLoggedIn ? 'calculator-wrapper__calculator--not-logged' : ''}>
          <Calculator {...props} runCatchErrorBoundary={runCatchErrorBoundary} />
        </div>
      </div>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};
CalculatorWrapper.propTypes = {
  device: PropTypes.string.isRequired,
  redirectToLogin: PropTypes.func.isRequired,
  item_id: PropTypes.string.isRequired,
  redirect_context: PropTypes.string,
  runCatchErrorBoundary: PropTypes.func,
};

CalculatorWrapper.defaultProps = {
  runCatchErrorBoundary: () => {},
};

export default CalculatorWrapper;
