import React, { useContext, startTransition } from 'react';
import { number, string, func, shape, objectOf, bool } from 'prop-types';
import classnames from 'classnames';
import customPropTypes from '../utils/proptypes';
import ThumbnailWithTooltip from './thumbnail-tooltip';
import { DECORATION_BLOCKED, DECORATION_SELECTED, DECORATION_NONE } from '../utils/types';
import { onMouseEnter, onMouseLeave, handleOnSelectVariation, namespace } from '../utils';
import { VariationsContext } from '../context/variations-context';
import getProductDecoration from '../utils/getProductDecoration';

const ProductVariationPicker = ({
  product,
  picker,
  pickerLabelId,
  pickerSelectedSubtitleId,
  platform,
  onSelectVariation,
  selectedAttributes,
  selectedQuantity,
  hasDeferredErrors,
  setHovered,
  index,
  setIsLoading,
}) => {
  const { selectedProduct, setSelectedProduct } = useContext(VariationsContext);

  const isBlocked = product.decoration_type === DECORATION_BLOCKED;

  const modifier = getProductDecoration(product, selectedProduct, DECORATION_SELECTED, DECORATION_NONE);

  return (
    <>
      <ThumbnailWithTooltip
        className={`${namespace}--thumbnail`}
        data-testid="thumbnail-variarions-desktop"
        dataTestId="thumbnail-variarions-desktop"
        onMouseLeave={() =>
          onMouseLeave(
            pickerLabelId,
            pickerSelectedSubtitleId,
            picker.error_message && !picker.error_message.is_deferred ? null : picker.selected_option,
            picker.selected_subtitle,
            !!product.picture,
            setHovered,
          )
        }
        onMouseEnter={() =>
          onMouseEnter(
            pickerLabelId,
            pickerSelectedSubtitleId,
            product.label,
            product.selected_subtitle,
            product.picture?.src,
            product.picture?.src2x,
            setHovered,
          )
        }
        blocked={isBlocked} // BLOCKED UNTIL "SIN STOCK" GOES CLICKEABLE
        modifier={modifier}
        modifierTooltip={product.disabled_text}
        label={product.label ? product.label.text : null}
        ariaLabel={
          product.label?.accessibility_text
            ? `${product.label.accessibility_text}, Boton ${index + 1} de ${picker.products.length}`
            : null
        }
        size={picker.products.length > 20 ? 'SMALL' : 'LARGE'}
        picture={
          product.picture
            ? {
                id: product?.picture.id,
                alt: product.label.text,
                src: product?.picture?.thumbnail?.src,
                src2x: product?.picture?.thumbnail?.src2x,
              }
            : null
        }
        href={product.permalink}
        title={product.label.text}
        subtitle={product.subtitle}
        price={product.price}
        onClick={e => {
          e.preventDefault();
          if (!isBlocked) {
            setSelectedProduct(product);
            startTransition(() => {
              handleOnSelectVariation(
                product,
                selectedQuantity,
                selectedAttributes,
                picker.id,
                hasDeferredErrors,
                onSelectVariation,
                platform,
                setIsLoading,
              );
            });
          }
        }}
        style={{ minWidth: picker.min_width, maxWidth: picker.max_width }}
      />
    </>
  );
};

const ProductsPicker = ({
  picker,
  pickerLabelId,
  pickerSelectedSubtitleId,
  limitedVariations,
  platform,
  onSelectVariation,
  selectedQuantity,
  selected_attributes,
  hasDeferredErrors,
  setHovered,
  setIsLoading,
}) => (
  <div
    className={classnames(`${namespace}__picker-default-container`, {
      [`${namespace}__picker-default-container-error`]: !!picker.error_message && !picker.error_message.is_deferred,
    })}
  >
    {picker.products
      .slice(0, limitedVariations?.is_collapsed ? limitedVariations.show_limit : picker.products.length)
      .map((product, index) => (
        <ProductVariationPicker
          key={`${product.id}-${product?.label?.text}-${product?.picture?.id}`}
          product={product}
          picker={picker}
          pickerLabelId={pickerLabelId}
          pickerSelectedSubtitleId={pickerSelectedSubtitleId}
          platform={platform}
          onSelectVariation={onSelectVariation}
          selectedAttributes={selected_attributes}
          selectedQuantity={selectedQuantity}
          hasDeferredErrors={hasDeferredErrors}
          setHovered={setHovered}
          index={index}
          setIsLoading={setIsLoading}
        />
      ))}
  </div>
);

ProductsPicker.propTypes = {
  picker: customPropTypes.picker,
  pickerLabelId: string,
  pickerSelectedSubtitleId: string,
  platform: string,
  selectedQuantity: number,
  onSelectVariation: func.isRequired,
  limitedVariations: shape(),
  selected_attributes: objectOf(string),
  hasDeferredErrors: bool,
  setHovered: func,
  setIsLoading: func,
};

ProductVariationPicker.propTypes = {
  product: customPropTypes.product,
  picker: customPropTypes.picker,
  pickerLabelId: string,
  pickerSelectedSubtitleId: string,
  platform: string,
  onSelectVariation: func.isRequired,
  selectedAttributes: objectOf(string),
  selectedQuantity: number,
  hasDeferredErrors: bool,
  setHovered: func,
  index: number,
  setIsLoading: func,
};

export default ProductsPicker;
