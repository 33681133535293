import React from 'react';
import { string, shape } from 'prop-types';
import Image from '../../image';

const namespace = 'pdp-enhanced-content';

const EnhancedImage = props => {
  const { media } = props;
  const { alt, src, src2x, id } = media;
  return (
    <div className={`${namespace}__image-wrapper`}>
      <Image id={id} alt={alt} src={src} src2x={src2x} {...media} className={`${namespace}__image`} />
    </div>
  );
};

EnhancedImage.propTypes = {
  media: shape({
    id: string.isRequired,
    alt: string.isRequired,
    src: string.isRequired,
    src2x: string,
  }).isRequired,
};
export default EnhancedImage;
