/* Questions */
const CREATE_QUESTION_START = 'CREATE_QUESTION_START';
const CREATE_QUESTION_COMPLETE = 'CREATE_QUESTION_COMPLETE';
const CREATE_QUESTION_FROM_AI_COMPLETE = 'CREATE_QUESTION_FROM_AI_COMPLETE';
const CREATE_QUESTION_ERROR = 'CREATE_QUESTION_ERROR';

const FETCH_START = 'FETCH_START';
const FETCH_COMPLETE = 'FETCH_COMPLETE';
const FETCH_END = 'FETCH_END';
const FETCH_ERROR = 'FETCH_ERROR';
const FETCH_ERROR_SNACK = 'FETCH_ERROR_SNACK'; // NO SE USA EN EL ROOT REDUCER
const FETCH_ERROR_SNACK_HIDE = 'FETCH_ERROR_SNACK_HIDE'; // NO SE USA EN EL ROOT REDUCER
const FETCH_DEFERRED_START = 'FETCH_DEFERRED_START';
const FETCH_DEFERRED_SUCCESS = 'FETCH_DEFERRED_SUCCESS';
const FETCH_DEFERRED_ERROR = 'FETCH_DEFERRED_ERROR';

/* Quantity */
const FETCH_ON_SET_QUANTITY_START = 'FETCH_ON_SET_QUANTITY_START';
const FETCH_ON_SET_QUANTITY_COMPLETE = 'FETCH_ON_SET_QUANTITY_COMPLETE';
const QUANTITY_SHOW_INPUT_CHANGE = 'QUANTITY_SHOW_INPUT_CHANGE';

/* A2C */
const ADD_TO_CART_UPDATE_START = 'ADD_TO_CART_UPDATE_START';
const ADD_TO_CART_UPDATE_COMPLETE = 'ADD_TO_CART_UPDATE_COMPLETE';
const ADD_TO_CART_UPDATE_ERROR = 'ADD_TO_CART_UPDATE_ERROR';

/* Shipping */
const FETCH_SHIPPING_PROMISE_START = 'FETCH_SHIPPING_PROMISE_START';
const FETCH_SHIPPING_PROMISE_COMPLETE = 'FETCH_SHIPPING_PROMISE_COMPLETE';

/* Variations */
const FETCH_PRODUCT_VARIATIONS_DETAILS = 'FETCH_PRODUCT_VARIATIONS_DETAILS';
const TOGGLE_BOOKMARK_OPTIMISTICALLY = 'TOGGLE_BOOKMARK_OPTIMISTICALLY';
const SHOW_QUANTITY_ERROR = 'SHOW_QUANTITY_ERROR';
const SHOW_VARIATIONS_ERROR_DESKTOP = 'SHOW_VARIATIONS_ERROR_DESKTOP';
const SHOW_VARIATIONS_ERROR_MOBILE = 'SHOW_VARIATIONS_ERROR_MOBILE';

const FETCH_ON_SET_BOOKMARK_COMPLETE = 'FETCH_ON_SET_BOOKMARK_COMPLETE';
const LIKE_REVIEW_OPTIMISTICALLY = 'LIKE_REVIEW_OPTIMISTICALLY'; // NO SE USA EN EL ROOT REDUCER
const LIKE_REVIEW_ROLLBACK = 'LIKE_REVIEW_ROLLBACK'; // NO SE USA EN EL ROOT REDUCER
const DISLIKE_REVIEW_OPTIMISTICALLY = 'DISLIKE_REVIEW_OPTIMISTICALLY'; // NO SE USA EN EL ROOT REDUCER
const DISLIKE_REVIEW_ROLLBACK = 'DISLIKE_REVIEW_ROLLBACK'; // NO SE USA EN EL ROOT REDUCER

const TOGGLE_BOOKMARKED = 'TOGGLE_BOOKMARKED';
const SET_PICKERS_COLLAPSED = 'SET_PICKERS_COLLAPSED';

/* Followers */
const TOGGLE_FOLLOW_OPTIMISTICALLY = 'TOGGLE_FOLLOW_OPTIMISTICALLY';
const FETCH_ON_SET_FOLLOW_COMPLETE = 'FETCH_ON_SET_FOLLOW_COMPLETE';

/* Recommendations */
const SET_RECOMMENDATIONS_CONTEXT = 'SET_RECOMMENDATIONS_CONTEXT';

/* On demand iframe */
const CART_CONGRATS_UPDATE = 'CART_CONGRATS_UPDATE';
const ON_DEMAND_IFRAME = 'ON_DEMAND_IFRAME';
const FETCH_ON_DEMAND_IFRAME = 'FETCH_ON_DEMAND_IFRAME';

/* Coupons */
const FETCH_ON_COUPON_UPDATE_START = 'FETCH_ON_COUPON_UPDATE_START';
const FETCH_ON_COUPON_UPDATE_COMPLETE = 'FETCH_ON_COUPON_UPDATE_COMPLETE';
const FETCH_ON_COUPON_UPDATE_ERROR = 'FETCH_ON_COUPON_UPDATE_ERROR';
const ON_COUPON_BUYBOX_OFFERS_UPDATE = 'ON_COUPON_BUYBOX_OFFERS_UPDATE';

/* Meliplus */
const FETCH_ON_MELIPLUS_UPDATE_START = 'FETCH_ON_MELIPLUS_UPDATE_START';
const FETCH_ON_MELIPLUS_UPDATE_COMPLETE = 'FETCH_ON_MELIPLUS_UPDATE_COMPLETE';

/* Trade in */
const FETCH_ON_CHANGE_TRADE_IN_START = 'FETCH_ON_CHANGE_TRADE_IN_START';
const FETCH_ON_CHANGE_TRADE_IN_COMPLETE = 'FETCH_ON_CHANGE_TRADE_IN_COMPLETE';

/* Installation service */
const FETCH_ON_CHANGE_INSTALLATION_START = 'FETCH_ON_CHANGE_INSTALLATION_START';
const FETCH_ON_CHANGE_INSTALLATION_END = 'FETCH_ON_CHANGE_INSTALLATION_END';
const FETCH_ON_CHANGE_INSTALLATION_COMPLETE = 'FETCH_ON_CHANGE_INSTALLATION_COMPLETE';

const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

/* 1pay4all */
const FETCH_SEQUENCER = 'FETCH_SEQUENCER';
const PRELOAD_IFRAME = 'PRELOAD_IFRAME';

/* Wishlist */
const WISHLIST_SHOW_BOTTOMSHEET_MODAL = 'WISHLIST_SHOW_BOTTOMSHEET_MODAL';
const WISHLIST_CLOSE_BOTTOMSHEET_MODAL = 'WISHLIST_CLOSE_BOTTOMSHEET_MODAL';
const UPDATE_GIFT_REGISTRY = 'UPDATE_GIFT_REGISTRY';
const UPDATE_GIFT_REGISTRY_CHECKBOX = 'UPDATE_GIFT_REGISTRY_CHECKBOX';

/* Questions AI */
const FETCH_QUESTION_AI_START = 'FETCH_QUESTION_AI_START';
const FETCH_QUESTION_AI_COMPLETE = 'FETCH_QUESTION_AI_COMPLETE';
const FETCH_UPDATE_QUANTITY = 'FETCH_UPDATE_QUANTITY'; // NO SE USA EN EL ROOT REDUCER
const FETCH_UPDATE_CARD = 'FETCH_UPDATE_CARD'; // NO SE USA EN EL ROOT REDUCER
const FETCH_QUESTION_AI_ERROR = 'FETCH_QUESTION_AI_ERROR';
const INITIAL_QUESION_AI = 'INITIAL_QUESION_AI';

const FETCH_COMPATS_SEARCH_START = 'FETCH_COMPATS_SEARCH_START';
const FETCH_COMPATS_SEARCH_STICKY_START = 'FETCH_COMPATS_SEARCH_STICKY_START';
const FETCH_COMPATS_DELETE_START = 'FETCH_COMPATS_DELETE_START';
const FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE = 'FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE';
const FETCH_COMPATS_SEARCH_STICKY_COMPLETE = 'FETCH_COMPATS_SEARCH_STICKY_COMPLETE';
const FETCH_COMPATS_SEARCH_COMPLETE = 'FETCH_COMPATS_SEARCH_COMPLETE';
const FETCH_COMPATS_SEARCH_COMPLETE_MOBILE = 'FETCH_COMPATS_SEARCH_COMPLETE_MOBILE';
const FETCH_COMPATS_DELETE_COMPLETE = 'FETCH_COMPATS_DELETE_COMPLETE';
const FETCH_COMPATS_DELETE_COMPLETE_MOBILE = 'FETCH_COMPATS_DELETE_COMPLETE_MOBILE';
const FETCH_COMPATS_SEARCH_ERROR = 'FETCH_COMPATS_SEARCH_ERROR';
const FETCH_COMPATS_DELETE_ERROR = 'FETCH_COMPATS_DELETE_ERROR';
const FETCH_COMPATS_FEEDBACK_CLOSE = 'FETCH_COMPATS_FEEDBACK_CLOSE';

const FETCH_MELIPLUS_INFO_START = 'FETCH_MELIPLUS_INFO_START';
const FETCH_MELIPLUS_INFO_COMPLETE = 'FETCH_MELIPLUS_INFO_COMPLETE';
const FETCH_MELIPLUS_INFO_ERROR = 'FETCH_MELIPLUS_INFO_ERROR';

export {
  CREATE_QUESTION_START,
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_FROM_AI_COMPLETE,
  CREATE_QUESTION_ERROR,
  FETCH_START,
  FETCH_COMPLETE,
  FETCH_END,
  FETCH_ERROR,
  FETCH_ERROR_SNACK,
  FETCH_ERROR_SNACK_HIDE,
  FETCH_PRODUCT_VARIATIONS_DETAILS,
  FETCH_ON_SET_QUANTITY_START,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  FETCH_SHIPPING_PROMISE_START,
  FETCH_SHIPPING_PROMISE_COMPLETE,
  QUANTITY_SHOW_INPUT_CHANGE,
  ADD_TO_CART_UPDATE_START,
  ADD_TO_CART_UPDATE_COMPLETE,
  ADD_TO_CART_UPDATE_ERROR,
  TOGGLE_BOOKMARK_OPTIMISTICALLY,
  FETCH_ON_SET_BOOKMARK_COMPLETE,
  LIKE_REVIEW_OPTIMISTICALLY,
  LIKE_REVIEW_ROLLBACK,
  DISLIKE_REVIEW_OPTIMISTICALLY,
  DISLIKE_REVIEW_ROLLBACK,
  TOGGLE_BOOKMARKED,
  SET_RECOMMENDATIONS_CONTEXT,
  FETCH_DEFERRED_START,
  FETCH_DEFERRED_SUCCESS,
  FETCH_DEFERRED_ERROR,
  SHOW_QUANTITY_ERROR,
  SHOW_VARIATIONS_ERROR_DESKTOP,
  SHOW_VARIATIONS_ERROR_MOBILE,
  FETCH_ON_COUPON_UPDATE_START,
  FETCH_ON_COUPON_UPDATE_COMPLETE,
  FETCH_ON_COUPON_UPDATE_ERROR,
  ON_COUPON_BUYBOX_OFFERS_UPDATE,
  FETCH_ON_MELIPLUS_UPDATE_START,
  FETCH_ON_MELIPLUS_UPDATE_COMPLETE,
  FETCH_ON_CHANGE_TRADE_IN_START,
  FETCH_ON_CHANGE_TRADE_IN_COMPLETE,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  CART_CONGRATS_UPDATE,
  ON_DEMAND_IFRAME,
  FETCH_ON_DEMAND_IFRAME,
  FETCH_SEQUENCER,
  PRELOAD_IFRAME,
  TOGGLE_FOLLOW_OPTIMISTICALLY,
  FETCH_ON_SET_FOLLOW_COMPLETE,
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  WISHLIST_CLOSE_BOTTOMSHEET_MODAL,
  FETCH_QUESTION_AI_START,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_UPDATE_QUANTITY,
  FETCH_UPDATE_CARD,
  FETCH_QUESTION_AI_ERROR,
  UPDATE_GIFT_REGISTRY,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
  INITIAL_QUESION_AI,
  FETCH_ON_CHANGE_INSTALLATION_COMPLETE,
  FETCH_ON_CHANGE_INSTALLATION_START,
  FETCH_ON_CHANGE_INSTALLATION_END,
  FETCH_COMPATS_SEARCH_START,
  FETCH_COMPATS_SEARCH_STICKY_START,
  FETCH_COMPATS_DELETE_START,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE,
  FETCH_COMPATS_SEARCH_COMPLETE,
  FETCH_COMPATS_SEARCH_COMPLETE_MOBILE,
  FETCH_COMPATS_DELETE_COMPLETE,
  FETCH_COMPATS_DELETE_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_ERROR,
  FETCH_COMPATS_DELETE_ERROR,
  FETCH_COMPATS_FEEDBACK_CLOSE,
  SET_PICKERS_COLLAPSED,
  FETCH_MELIPLUS_INFO_START,
  FETCH_MELIPLUS_INFO_COMPLETE,
  FETCH_MELIPLUS_INFO_ERROR,
};
