import React from 'react';
import { string, shape, arrayOf, func } from 'prop-types';
import { Image } from 'nordic/image';
import { Card } from '@andes/card';
import { Thumbnail } from '@andes/thumbnail';
import CarouselSnapped, { CarouselSnappedSlide } from '@andes/carousel-snapped';
import classnames from 'classnames';
import StyledLabel from '../styled-label';
import { generateKeyToComponent } from '../../utils/validators';
import IconFactory from '../icons/factory-builder';
import useTrackView from '../../hooks/use-track-view';

const namespace = 'navigation-official-stores';

const Container = ({ carousel, title, navigation_action, view_carousel_track, runCatchErrorBoundary }) => {
  try {
    const viewRef = useTrackView(view_carousel_track, { threshold: 0.45 });

    return (
      <div ref={viewRef} className={classnames(namespace, 'row container')}>
        <div className={`${namespace}__header`}>
          <StyledLabel {...title} />
          <a href={navigation_action.target}>
            <StyledLabel className={`${namespace}__view-more`} {...navigation_action.label} />
          </a>
        </div>
        <div className={`${namespace}__carousel`}>
          <CarouselSnapped
            pagination={false}
            slidesPerView={4}
            spacing={16}
            arrowsVisible={false}
            lazyload
            strictBoundaries={false}
          >
            {carousel.map(slide => (
              <CarouselSnappedSlide key={generateKeyToComponent(slide)}>
                <a href={slide.target}>
                  <Card>
                    <div className={`${namespace}__background`}>
                      <Image alt="" src={slide.banner.url.src} />
                    </div>
                    <div className={`${namespace}__divider`} />

                    <Thumbnail className={`${namespace}__logo`} modifier="square" size="80">
                      <Image alt={slide.title.text} src={slide.logo.url.src} />
                    </Thumbnail>
                    <div className={`${namespace}__description`}>
                      <div className={`${namespace}__description__title`}>
                        <StyledLabel {...slide.title} />
                        {slide.official_store_icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
                      </div>
                      <StyledLabel {...slide.subtitle} />
                    </div>
                  </Card>
                </a>
              </CarouselSnappedSlide>
            ))}
          </CarouselSnapped>
        </div>
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Container.propTypes = {
  title: shape({ text: string, color: string, font_family: string, font_size: string }).isRequired,
  carousel: arrayOf(
    shape({
      state: string,
      title: shape({ text: string, color: string, font_family: string, font_size: string }),
      subtitle: shape({ text: string, color: string, font_family: string, font_size: string }),
      target: string,
      logo: shape({
        url: shape({ src: string }),
      }),
      banner: shape({
        url: shape({ src: string }),
      }),
    }).isRequired,
  ),
  navigation_action: shape({
    target: string,
    label: shape({ text: string, color: string, font_family: string, font_size: string }),
  }),
  view_carousel_track: shape({
    melidata_event: shape({
      path: string,
      event_data: shape({
        product_id: string,
        item_id: string,
        official_stores: arrayOf(string),
      }),
    }),
  }),
  runCatchErrorBoundary: func,
};

Container.defaultProps = {
  title: null,
  carousel: null,
  navigation_action: null,
  view_carousel_track: null,
  runCatchErrorBoundary: () => {},
};

export default Container;
