import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import jsonToProps from '../../../../components/gift-registry/jsonToProps';
import * as actions from '../../../../actions/vip';

const WISHLIST = 'wishlist_save_button';
const BOOKMARK = 'bookmark';

const mapStateToProps = state => {
  const {
    components: { [WISHLIST]: wishlist, [BOOKMARK]: bookmark },
    id,
  } = state;

  return jsonToProps({
    id,
    wishlist,
    isBookmarked: bookmark?.is_bookmarked,
  });
};

const mapDispatchToProps = dispatch => ({
  updateGiftRegistry: check => dispatch(actions.fetchUpdatedWishlists(check)),
  removeElementFromList: element => dispatch(actions.removeElementFromList(element)),
  addElementToList: element => dispatch(actions.addElementToList(element)),
  fetchBookmark: () => dispatch(actions.fetchBookmark()),
  toggleGiftRegistryCheckbox: check => dispatch(actions.toggleGiftRegistryCheckbox(check)),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    'gift-registry',
  ),
);

const giftRegistry = GiftRegistry => enhance(GiftRegistry);

export default giftRegistry;
