import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import mapStateToProps from '../../../../components/recommendations/state-mapper-base';

const FEATURE_NAME = 'advertising_pdp_right_mshops_bottom';

const RecommendationsShops = loadable(() => import('../../../../components/recommendations/shops'));

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps(),
    null,
    'recos_shops_bottom',
  ),
);

export default enhance(RecommendationsShops);
