import React from 'react';
import { arrayOf, bool, func, number, string, shape } from 'prop-types';
import classNames from 'classnames';
import SnackBarError from '../snackbar';
import Rating from './components/rating';
import Comments from './components/comments/comments.desktop';
import RatingLevels from '../rating-levels';
import componentEnhance from '../../lib/component-enhance';
import StyledLabel from '../styled-label';
import ReviewAction from './components/review-actions/review-actions.desktop';

const namespace = 'ui-pdp-reviews';

const Reviews = ({
  className,
  comments,
  rating,
  snackError,
  title,
  emptySubtitle,
  onCloseModal,
  onDislikeComment,
  onOpenModal,
  onLikeComment,
  newReview,
  allReviews,
  runCatchErrorBoundary,
}) => {
  try {
    return (
      <section className={!allReviews ? `${namespace}__empty-reviews` : classNames(namespace, className)}>
        <header className={`${namespace}__header`}>
          <h2 className={`${namespace}__header__title`}>{title}</h2>
          {emptySubtitle && (
            <StyledLabel
              className={`${namespace}__title`}
              as="h2"
              color={emptySubtitle.color}
              font_family={emptySubtitle.font_family}
              font_size={emptySubtitle.font_size}
              text={componentEnhance.jsx(emptySubtitle.text, emptySubtitle.values)}
            />
          )}
          {rating && <Rating {...rating}>{rating.levels && <RatingLevels levels={rating.levels} rightAlign />}</Rating>}
        </header>
        {comments.tabs && (
          <Comments
            action={comments.action}
            tabs={comments.tabs}
            title={title}
            onLikeComment={onLikeComment}
            onDislikeComment={onDislikeComment}
            onCloseModal={onCloseModal}
            onOpenModal={onOpenModal}
            newReview={newReview}
          />
        )}
        <ReviewAction allReviews={allReviews} newReview={newReview} title={title} />
        {snackError && <SnackBarError show={snackError.show} key={snackError.key} message={snackError.message} />}
      </section>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Reviews.propTypes = {
  className: string,
  comments: shape({
    action: shape({
      target: string.isRequired,
    }),
    label: shape({
      text: string,
    }),
    onDislikeComment: func,
    onLikeComment: func,
  }),
  rating: shape({
    average: string.isRequired,
    label: string,
    levels: arrayOf(
      shape({
        label: shape({
          text: string,
        }),
        percentage: number.isRequired,
        value: number,
      }),
    ).isRequired,
  }),
  snackError: shape({
    show: bool,
    message: string,
  }),
  title: string,
  emptySubtitle: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
    value: shape({
      black_text: shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
    }),
  }),
  onCloseModal: func,
  onDislikeComment: func,
  onLikeComment: func,
  onOpenModal: func,
  allReviews: shape({
    type: string,
    target: string,
    label: shape({
      text: string,
      color: string,
    }),
  }),
  newReview: shape({
    type: string,
    target: string,
    label: shape({
      text: string,
      color: string,
    }),
  }),
  runCatchErrorBoundary: func,
};

Reviews.defaultProps = {
  className: '',
  comments: {},
  rating: {},
  snackError: {
    show: false,
    message: '',
  },
  title: '',
  emptySubtitle: null,
  onCloseModal: null,
  onDislikeComment: null,
  onLikeComment: null,
  onOpenModal: null,
  allReviews: null,
  newReview: null,
  runCatchErrorBoundary: () => {},
};

export default Reviews;
