import { string, shape, number } from 'prop-types';

export const quantityInputCustomLabelPropTypes = {
  label: shape({
    text: string,
    values: shape({
      equivalence: shape({
        text: string,
        color: string,
        font_size: string,
      }),
    }),
  }),
  amount: shape({
    type: string,
    value: number,
    currency_id: string,
    decimal_style: string,
    suffix: shape({
      text: string,
      accessibility_text: string,
    }),
  }).isRequired,
  suffix: string,
  namespace: string.isRequired,
};
