import React from 'react';
import useEnhanceVideo from '../hooks/use-enhance-video';
import { Icon } from '@vpp-frontend-components/icon';
import { string, shape } from 'prop-types';

export const EnhancedVideo = props => {
  const namespace = 'pdp-enhanced-content';

  const placeholderRef = React.useRef(null);

  const { media } = props;
  const { play, isPlaying, isMounting, accesibilityPlayText } = useEnhanceVideo(media, 'desktop', placeholderRef);

  return (
    <div className={`${namespace}__wrapper`} ref={placeholderRef} style={{ position: 'relative' }}>
      {!isMounting && !isPlaying && (
        <button type="button" onClick={play} className={`${namespace}__play-button`} title={accesibilityPlayText}>
          <Icon id="play_button" className={`${namespace}__play-icon`} />
        </button>
      )}
    </div>
  );
};

EnhancedVideo.propTypes = {
  media: shape({
    id: string.isRequired,
    alt: string.isRequired,
    src: string.isRequired,
    src2x: string,
  }).isRequired,
};

export default EnhancedVideo;
