import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';

const DynamicComponentWrapper = loadable(() => import('../../../../components/dynamic-component-wrapper'));

const mapStateToProps = ({ components }, { spot, renderDynamicComponent, className }) => ({
  components,
  spot,
  renderDynamicComponent,
  className,
});

const enhance = compose(
  connect(
    mapStateToProps,
    null,
    'dynamic_component_wrapper',
  ),
);

export default enhance(DynamicComponentWrapper);
