import React from 'react';
import { ListItem } from '@andes/list';
import { MoneyAmount } from '@andes/money-amount';
import { quantityListItemPropTypes } from '../model';

const QuantityListItem = ({
  index,
  offsetIndex,
  localQuantity,
  quantitySelector,
  onClickListItem,
  closeTooltip,
  labelElement,
  showTitle,
  amount,
  quantity,
  hasBulkCustomOptions,
}) => {
  const restProps = {};
  if (index === localQuantity) {
    restProps['data-autofocus'] = true;
  }

  return (
    <ListItem
      key={`list-item-quantity-${index}`}
      data-testid={`quantity-selector-item-${index}`}
      selected={offsetIndex === localQuantity}
      disabled={index > quantitySelector.available_quantity}
      onClick={event => onClickListItem({ event, quantity: offsetIndex, selected: localQuantity })}
      title={showTitle ? quantity.text : labelElement(index)}
      tabIndex={0}
      rightContent={
        (showTitle || hasBulkCustomOptions) && (
          <MoneyAmount
            size={16}
            value={{ fraction: amount.fraction, cents: amount.cents ?? undefined }}
            suffix={amount.suffix?.text}
            currencyId={amount.currency_id}
            centsType="superscript"
          />
        )
      }
      {...restProps}
      onKeyUp={closeTooltip}
    />
  );
};

QuantityListItem.propTypes = quantityListItemPropTypes;

export default QuantityListItem;
