export const DEFAULT_LOCATE = 'pt-BR';

export function formatMoneyByLocale(number, locale) {
  let inputNumber = number;
  const internalLocale = locale?.replace('_', '-') || DEFAULT_LOCATE;

  if (typeof inputNumber === 'string') {
    inputNumber = Number(number?.replace(',', '.'));
  }

  const formatter = new Intl.NumberFormat(internalLocale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).formatToParts(inputNumber);

  const parts = formatter.reduce(
    (acc, part) => {
      if (part.type === 'fraction') {
        if (Number(part.value) >= 1) {
          return {
            ...acc,
            cents: part.value,
          };
        }

        return acc;
      }

      if (part.type === 'decimal') {
        return {
          ...acc,
          decimalSeparator: part.value,
        };
      }

      return {
        ...acc,
        fraction: acc.fraction.concat(part.value),
      };
    },
    { fraction: '', cents: undefined, decimalSeparator: '' },
  );

  return {
    totalValueFormatted: `${parts.fraction}${parts.cents ? parts.decimalSeparator.concat(parts.cents) : ''}`,
    ...parts,
  };
}
