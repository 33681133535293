import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import Coachmarks from '../../../../components/coachmarks';

const FEATURE_NAME = 'price_per_quantity_coach_mark';

const mapStateToProps = ({ components: { [FEATURE_NAME]: price_per_quantity_coach_mark } }, props) => ({
  ...price_per_quantity_coach_mark,
  ...props,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Coachmarks);
