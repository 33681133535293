import getSelectedAttributes from '../../utils/get-selected-attributes';

const getCheckOnBulkSaleSetQuantity = (data, getState) => {
  const {
    id,
    platform,
    app,
    filters: pdp_filters,
    vip_filters,
    selectedShippingOptionId,
    components,
    hasDeferredErrors,
  } = getState();

  const { bulk_sale_quantity, sale_unit_quantity, item_id, variation_id } = data;
  return {
    variation_id,
    item_id,
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    selectedShippingOptionId,
    components,
    hasDeferredErrors,
    bulk_sale_quantity,
    sale_unit_quantity,
  };
};

const getFetchItemOnSetQuantity = (quantity, getState) => {
  const {
    id,
    platform,
    app,
    filters: pdp_filters,
    vip_filters,
    selectedShippingOptionId,
    components,
    hasDeferredErrors,
  } = getState();
  const attributes = getSelectedAttributes(components);
  return {
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    selectedShippingOptionId,
    attributes,
    hasDeferredErrors,
    quantity,
  };
};

export { getCheckOnBulkSaleSetQuantity, getFetchItemOnSetQuantity };
