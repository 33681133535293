import React from 'react';
import classnames from 'classnames';
import { arrayOf, string, shape, number, bool } from 'prop-types';
import useTrackView from '../../hooks/use-track-view';
import EnhancedVideoDesktop from './enhanced-video/enhanced-video.desktop';
import EnhancedVideoMobile from './enhanced-video/enhanced-video.mobile';
import useExpandable from '../../hooks/use-expandable';
import IconFactory from '../icons/factory-builder';
import { trackEvent } from '../../lib/tracking';
import EnhancedImage from './enhanced-image/enhanced-image';

const namespace = 'pdp-enhanced-content';

const defaultObjProp = {};

const EnhancedContent = ({
  title,
  heading_label,
  media,
  action = defaultObjProp,
  viewPortTrack = defaultObjProp,
  maxShowPixels = 1800,
  isMobile = false,
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const viewRef = useTrackView(viewPortTrack, { threshold: 0 });
    const showTitle = title.text && title.text.length > 0;

    const hasMediaItems = media?.length > 0;

    const trackAction = () => trackEvent(action.track);

    const { handleExpanse, expandableRef, isExpanded } = useExpandable({ maxShowPixels, action: trackAction });

    return (
      <div className={`${namespace}__content`} ref={viewRef}>
        {showTitle && (
          <h2 data-testid="title" className={`${namespace}__title`} title={title.text}>
            {title.text}
          </h2>
        )}
        <div ref={expandableRef} className={`${namespace}__expandable`}>
          {hasMediaItems && (
            <div className={`${namespace}__pictures`}>
              {media.map(mediaItem => {
                if (mediaItem.type === 'image') {
                  return <EnhancedImage media={mediaItem} key={mediaItem.id} />;
                }
                if (mediaItem.type === 'video' && isMobile) {
                  return <EnhancedVideoMobile media={mediaItem} key={mediaItem.id} />;
                }
                if (mediaItem.type === 'video' && !isMobile) {
                  return <EnhancedVideoDesktop media={mediaItem} key={mediaItem.id} />;
                }
                return null;
              })}
            </div>
          )}
          {!isExpanded && <div className={`${namespace}__gradient`} />}
        </div>
        {!isExpanded && (
          <button type="button" onClick={handleExpanse} className={`${namespace}__expande-btn`}>
            {heading_label.text} {IconFactory({ id: 'chevron' }, classnames(`${namespace}__chevron`))}
          </button>
        )}
      </div>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

EnhancedContent.propTypes = {
  title: shape({
    text: string.isRequired,
  }).isRequired,
  action: shape({
    timeout: number,
    duration: number,
    label: shape({
      text: string,
      color: string,
    }),
    track: shape({
      melidata_event: shape({
        type: string,
        path: string,
        event_data: shape({
          item_id: string,
          category_id: string,
          brand_id: string,
          domain_id: string,
          catalog_product_id: string,
          has_full_enhanced_descriptions: bool,
        }),
      }),
    }),
  }),
  media: arrayOf(
    shape({
      id: string.isRequired,
      type: string.isRequired,
      alt: string,
      src: string,
      src2x: string,
      width: number,
      height: number,
      ratio: number,
      buttons: shape({
        play: shape({
          id: string.isRequired,
          color: string.isRequired,
        }),
        mute: shape({
          id: string.isRequired,
          color: string.isRequired,
        }),
      }),
    }),
  ).isRequired,
  viewPortTrack: shape({
    melidata_event: shape({}),
  }),
  maxShowPixels: number,
  heading_label: shape({
    text: string.isRequired,
  }).isRequired,
  isMobile: bool,
};

export default EnhancedContent;
