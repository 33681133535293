import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import { ProductSuggestions } from '@vpp-frontend-components/product-suggestions';

const FEATURE_NAME = 'repurchase_header';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }, ownProps) => ({ ...props, ...ownProps });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(ProductSuggestions);
