import React, { useContext } from 'react';
import { bool, string, shape, number, func } from 'prop-types';
import classnames from 'classnames';
import StaticPropsContext from '../context/static-props';
import Message from '../message';
import getAction from '../../utils/getActionModal';

const namespace = 'ui-pdp-shipping-message';
const ShippingMessage = ({
  className,
  body,
  message_type,
  closeable,
  hierarchy,
  primary_action,
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { deviceType } = useContext(StaticPropsContext);

    return (
      <Message
        className={classnames(namespace, className)}
        actions={primary_action && getAction(primary_action, deviceType, namespace)}
        type={message_type}
        closeable={closeable}
        hierarchy={hierarchy}
      >
        <div className={`${namespace}__text`}>{body.text}</div>
      </Message>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

ShippingMessage.propTypes = {
  className: string,
  body: shape({
    text: string.isRequired,
  }).isRequired,
  message_type: string,
  closeable: bool,
  hierarchy: string,
  primary_action: shape({
    target: string.isRequired,
    timeout: number,
    duration: number,
    label: shape({
      text: string,
      color: string,
    }),
    icon: shape({
      id: string,
      color: string,
    }),
  }),
  runCatchErrorBoundary: func,
};

ShippingMessage.defaultProps = {
  className: '',
  message_type: 'warning',
  closeable: false,
  hierarchy: 'quiet',
  primary_action: null,
  runCatchErrorBoundary: () => {},
};

export default ShippingMessage;
