import React from 'react';
import { arrayOf, shape, string, number, func } from 'prop-types';
import classNames from 'classnames';
import colornames from '../../lib/colornames';
import { PriceFontSize } from '../../lib/num-font-size-enum';
import { trackEvent } from '../../lib/tracking';
import { generateKeyToComponent } from '../../utils/validators';
import { componentEnhance } from '@vpp-frontend-components/component-enhance';
import SeoAnchor from '@seo-frontend-components/seo-anchor';

const namespace = 'ui-pdp-products';

const OtherProducts = ({ title, className, products, runCatchErrorBoundary }) => {
  try {
    return (
      <div className={classNames(namespace, className)}>
        {title && <p className={classNames(`${namespace}__label`, colornames(title))}>{title.text}</p>}
        <ul className={`${namespace}__list`}>
          {products.map(product => (
            <li key={generateKeyToComponent(product)} className={`${namespace}__item`}>
              <SeoAnchor
                className={`${namespace}__button`}
                href={product.action.target}
                onClick={() => {
                  trackEvent(product.action.track);
                }}
              >
                {componentEnhance.jsx(product.title.text, product.title.values, null, PriceFontSize.OTHER_PRODUCT)}
              </SeoAnchor>
            </li>
          ))}
        </ul>
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

OtherProducts.propTypes = {
  className: string,
  products: arrayOf(
    shape({
      title: shape({
        text: string.isRequired,
        color: string,
        values: shape({
          price: shape({
            type: string,
            value: number,
          }),
        }),
      }),
      action: shape({
        target: string.isRequired,
        track: shape({}),
      }),
    }),
  ).isRequired,
  title: shape({
    text: string.isRequired,
    color: string,
  }).isRequired,
  runCatchErrorBoundary: func,
};

OtherProducts.defaultProps = {
  className: null,
  runCatchErrorBoundary: () => {},
};

export default OtherProducts;
