import React from 'react';
import { shape, string, node, bool, func } from 'prop-types';
import classNames from 'classnames';
import StyledLabel from '../styled-label';
import TooltipLegal from '../tooltip-legal/tooltip-legal.desktop';

const namespace = 'ui-pdp-moq-information';

const MoqInformation = ({ className, collapsible_tooltip, subtitle, runCatchErrorBoundary }) => {
  try {
    return (
      <div className={classNames(`${namespace}`, className)}>
        {collapsible_tooltip ? (
          <TooltipLegal collapsibleTooltip={collapsible_tooltip}>
            {subtitle && <StyledLabel className={`${namespace}__subtitle`} {...subtitle} />}
          </TooltipLegal>
        ) : (
          subtitle && <StyledLabel className={`${namespace}__subtitle`} {...subtitle} />
        )}
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

MoqInformation.propTypes = {
  className: string,
  collapsible_tooltip: shape({
    icon: shape({
      id: string,
      color: string,
      size: string,
    }),
    opened: bool,
    tooltip: shape({
      arrow_type: string,
      background_color: string,
      change_color: bool,
      close_color: string,
      closeable: bool,
      closed: bool,
      has_shadow: bool,
      state: string,
      storable: bool,
      subtitles: shape([
        {
          color: string,
          font_family: string,
          font_size: string,
          text: string,
        },
      ]),
      title: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
      type: string,
    }),
  }),
  subtitle: node.isRequired,
  runCatchErrorBoundary: func,
};

MoqInformation.defaultProps = {
  className: null,
  collapsible_tooltip: null,
  subtitle: null,
  runCatchErrorBoundary: () => {},
};

export default MoqInformation;
