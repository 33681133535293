import { arrayOf, bool, func, node, number, oneOf, oneOfType, shape, string } from 'prop-types';

export const namespaceUnregistered = 'ui-pdp-questions-unregistered';
export const namespace = 'ui-pdp-questions';

export const questionsPropTypes = {
  className: string,
  disabled: bool,
  form: shape({
    title: shape({ text: string.isRequired }),
    confirm: shape({
      label: shape({
        text: string.isRequired,
      }),
      target: string.isRequired,
      item_id: string.isRequired,
    }).isRequired,
    focus_track: shape({}),
    default_question_text: string,
    subtitles: arrayOf(shape({ text: string.isRequired })).isRequired,
    placeholder: shape({ text: string.isRequired }).isRequired,
  }),
  id: string.isRequired,
  messages: shape({
    text: string,
    type: oneOf(['success', 'error']),
  }),
  children: node,
  title: shape({ text: string.isRequired }),
  show_track: shape({}),
  url: string.isRequired,
  stockModal: shape({}),
  quick_access: shape({
    actions: arrayOf(
      shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
      }),
    ),
    title: shape({ text: string.isRequired }).isRequired,
  }),
  question_message: shape({
    title: shape({ text: string.isRequired }).isRequired,
    subtitle: shape({ text: string.isRequired }).isRequired,
  }),
  track: shape({}).isRequired,
  onCreateQuestion: func.isRequired,
  condition: shape({
    text: string.isRequired,
    values: shape(),
  }),
  message_prevention_fraud: shape({
    hierarchy: string,
    icon: shape({ id: string, color: string }),
    message_type: string,
    title: shape({ text: string }),
    type: string,
  }),
  message_location: shape({
    hierarchy: string,
    icon: shape({ id: string, color: string }),
    message_type: string,
    title: shape({ text: string }),
    type: string,
  }),
  focus: bool,
  onRequestFocus: func,
  enableRedirectAction: bool,
  onScrollComponent: func,
  closeModalLabel: string,
  showSnackbar: func,
};

export const questionsUnregisteredPropTypes = {
  className: string,
  form: shape({
    title: shape({ text: string.isRequired }),
    internal_subtitle: shape({
      text: string.isRequired,
    }),
    subtitles: arrayOf(
      shape({
        text: string.isRequired,
        icon: shape({
          id: string,
          color: string,
        }),
      }),
    ),
    confirm: shape({
      label: shape({
        text: string.isRequired,
      }),
    }).isRequired,
    name: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
    last_name: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([number, bool]),
          error_message: string,
        }),
      ),
    }),
    email: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
    phone: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: string,
          error_message: string,
        }),
      ),
    }),
    question: shape({
      label: string,
      label_fixed: bool,
      placeholder: string,
      message_show: bool,
      input_type: string,
      has_validation_error: bool,
      validations: arrayOf(
        shape({
          type: string,
          value: oneOfType([bool, number]),
          error_message: string,
        }),
      ),
    }),
    message_prevention_fraud: shape({
      body: shape({
        text: string,
        values: shape({ bold_text: shape({ text: string, font_family: string }) }),
      }),
      closeable: bool,
      hierarchy: string,
      message_type: string,
    }),
  }).isRequired,
  onCreateQuestion: func.isRequired,
  onChange: func.isRequired,
  onEnableStorageData: func.isRequired,
  onSetFormErrors: func,
  url: string.isRequired,
  messages: shape({
    text: string,
    type: oneOf(['success', 'error']),
    form: shape({
      title: shape({ text: string.isRequired }),
      subtitles: arrayOf(
        shape({
          text: string.isRequired,
          icon: shape({
            id: string,
            color: string,
          }),
        }),
      ),
      conditions: shape({
        text: string.isRequired,
        values: shape(),
      }),
      confirm: shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
        item_id: string.isRequired,
      }).isRequired,
      name: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      last_name: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      email: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      phone: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
      question: shape({
        label: string,
        label_fixed: bool,
        placeholder: string,
        message_show: bool,
        input_type: string,
        has_validation_error: bool,
        validations: arrayOf(
          shape({
            type: string,
            value: string,
            error_message: string,
          }),
        ),
      }),
    }),
  }),
  itemId: string.isRequired,
  conditions: shape({
    text: string.isRequired,
    values: shape(),
  }),
  key: string,
  track: shape({}).isRequired,
  collapsible_info: shape({
    title: shape({
      text: string.isRequired,
    }),
    edit_link: shape({
      text: string.isRequired,
    }),
  }),
  can_edit: bool,
  isEditing: bool,
  lgpd_confirmation: shape({
    text: string.isRequired,
    values: shape({}),
  }),
  recaptchaPublicKey: string,
  recaptchaSiteKey: string,
  focus: bool,
  onRequestFocus: func,
  isModal: bool,
  isFetchingData: bool,
};
