/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { number, string, func, objectOf, bool } from 'prop-types';
import debounce from 'lodash/debounce';
import classnames from 'classnames';
import { namespace } from '../utils';
import customPropTypes from '../utils/proptypes';
import ApparelSizeSpecsComponents from './apparel-size-specs-components';
import PickerTitle from './picker-title';
import ProductsPicker from './products-picker';
import SeeMoreVariations from './see-more-variations';
import ModalGradings from '../../grading-button-item/modal-gradings';

const DefaultPicker = ({
  picker,
  pickerLabelId,
  pickerSelectedSubtitleId,
  platform,
  onSelectVariation,
  selectedQuantity,
  setPickersCollapse,
  selected_attributes,
  hasDeferredErrors,
}) => {
  const loadingNamespace = 'ui-pdp-fetching-component';
  const limitedVariations = picker.display_info;
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const setHovered = useCallback(debounce(setIsHovered, 200), []);

  useEffect(() => {
    const loadingDiv = document.getElementById('loading');

    if (loadingDiv && isLoading) {
      loadingDiv?.classList.add(loadingNamespace);
    } else {
      loadingDiv?.classList.remove(loadingNamespace);
    }
  }, [isLoading]);

  return (
    <div
      key={picker.id}
      className={classnames(`${namespace}__picker`, {
        [`${namespace}__picker-size-specs-chart`]: picker.size_specs_chart,
      })}
      data-testid="picker-container"
    >
      <PickerTitle
        picker={picker}
        pickerLabelId={pickerLabelId}
        pickerSelectedSubtitleId={pickerSelectedSubtitleId}
        isHovered={isHovered}
      />
      <ProductsPicker
        picker={picker}
        pickerLabelId={pickerLabelId}
        pickerSelectedSubtitleId={pickerSelectedSubtitleId}
        limitedVariations={limitedVariations}
        platform={platform}
        onSelectVariation={onSelectVariation}
        selectedQuantity={selectedQuantity}
        selected_attributes={selected_attributes}
        hasDeferredErrors={hasDeferredErrors}
        setHovered={setHovered}
        setIsLoading={setIsLoading}
      />

      {!!limitedVariations?.is_collapsed && (
        <SeeMoreVariations
          picker={picker}
          limitedVariations={limitedVariations}
          setPickersCollapse={setPickersCollapse}
        />
      )}

      {picker.apparel_size_specs && <ApparelSizeSpecsComponents picker={picker} />}

      {picker.grading_button_item && (
        <ModalGradings
          label={picker.grading_button_item.action.label}
          target={picker.grading_button_item.action.target}
          track={picker.grading_button_item.action.track}
        />
      )}
    </div>
  );
};

DefaultPicker.propTypes = {
  picker: customPropTypes.picker,
  pickerLabelId: string,
  pickerSelectedSubtitleId: string,
  platform: string,
  selectedQuantity: number,
  onSelectVariation: func.isRequired,
  setPickersCollapse: func,
  selected_attributes: objectOf(string),
  hasDeferredErrors: bool,
};

export default DefaultPicker;
