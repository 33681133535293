import React from 'react';
import { Action } from '../../prop-types';
import { trackEvent } from '../../lib/tracking';
import { func } from 'prop-types';

const namespace = 'ui-pdp-syi';

const SellYourItem = ({ action, runCatchErrorBoundary }) => {
  try {
    return (
      <div className={namespace}>
        <a
          href={action.target}
          className={`${namespace}__link`}
          onClick={() => {
            trackEvent(action.track);
          }}
        >
          {action.label.text}
        </a>
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

SellYourItem.propTypes = {
  action: Action.isRequired,
  runCatchErrorBoundary: func,
};

SellYourItem.defaultProps = {
  runCatchErrorBoundary: () => {},
};

export default SellYourItem;
