import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import classnames from 'classnames';
import colornames from '../../lib/colornames';

import IconFactory from '../icons/factory-builder';

import Media from '../media';

const namespace = 'ui-pdp-benefits';

const Benefits = ({ benefits, className, runCatchErrorBoundary }) => {
  try {
    return (
      <ul className={classnames(namespace, className)}>
        {benefits.map(benefit => (
          <li className={`${namespace}__item`} key={benefit.text}>
            <Media
              tag="div"
              className={classnames(colornames(benefit.icon))}
              figure={IconFactory(benefit.icon)}
              title={benefit}
            />
          </li>
        ))}
      </ul>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Benefits.propTypes = {
  benefits: arrayOf(
    shape({
      text: string.isRequired,
      icon: shape({
        id: string.isRequired,
        color: string,
      }),
      color: string,
      values: shape(),
    }),
  ).isRequired,
  className: string,
  runCatchErrorBoundary: func,
};

Benefits.defaultProps = {
  className: null,
  runCatchErrorBoundary: () => {},
};

export default React.memo(Benefits);
