import APIService from '../../../services/api';
import { getterCompatsProductServiceActions } from '../../getters/vip/compats';
import trackEventCompatsInResponse from '../../utils/track-event-compats-in-response';
import {
  FETCH_COMPATS_DELETE_ERROR,
  FETCH_COMPATS_DELETE_START,
  FETCH_COMPATS_SEARCH_ERROR,
  FETCH_ERROR,
} from '../../vip/types';

const ID_COMPATS_WIDGET_MESSAGE = 'COMPATS_MESSAGE';
const COMPATS_MESSAGE_TOP = 'COMPATS_MESSAGE_TOP';
const ID_COMPATS_WIDGET = 'compats_widget';
const SELLER = 'seller';
const COMPATS = 'compats';

const execGetCompatsProductServiceActions = (dispatch, args) => {
  const {
    id,
    app,
    domain_id,
    params,
    fetchTypeStart,
    fetchTypeComplete,
    compats_type,
    isMobile,
    updateState,
    updateWidgetData,
    isSticky,
    selectedInput,
    user_selection_path,
  } = args;

  dispatch({ type: fetchTypeStart, id, params });
  APIService.getCompatsProduct(params)
    .then(response => {
      dispatch({ type: fetchTypeComplete, payload: response });
      if (!isSticky || compats_type === ID_COMPATS_WIDGET_MESSAGE) {
        updateState(updateWidgetData(response));
      } else {
        trackEventCompatsInResponse(response, isMobile);
      }
      sessionStorage.removeItem(COMPATS_MESSAGE_TOP);
      const event = new StorageEvent('storage', {
        key: COMPATS_MESSAGE_TOP,
        newValue: 'false',
        storageArea: sessionStorage,
      });
      window.dispatchEvent(event);
      window.scroll({ top: 0, behavior: 'smooth' });
    })
    .catch(e => {
      dispatch({ type: FETCH_COMPATS_SEARCH_ERROR, error: e });
    });

  const paramsPost = {
    domain_id,
    app,
    user_selection_path,
  };

  // optimistic POST
  APIService.postCompats(selectedInput, paramsPost)
    .then(() => {})
    .catch(e => {
      dispatch({ type: FETCH_COMPATS_SEARCH_ERROR, error: e });
    });
};

const execGetCompatsProductDesktop = (dispatch, args) => {
  const {
    compats_widget_block,
    fetchStickyComplete,
    fetchComplete,
    selectedInput,
    updateState,
    feature_name,
    type,
    stateComponents,
    getState,
  } = args;

  const updateWidgetData = response => {
    trackEventCompatsInResponse(response, false, feature_name);
    /* istanbul ignore next */
    if (feature_name === SELLER || feature_name === COMPATS) {
      if (response?.components?.short_description) {
        return {
          ...response.components.short_description
            .find(it => it.id === feature_name)
            .components.find(it => it.id === ID_COMPATS_WIDGET).widget_data,
        };
      }
      return {
        ...response.components.find(it => it.id === feature_name).components.find(it => it.id === ID_COMPATS_WIDGET)
          .widget_data,
      };
    }
    if (feature_name === ID_COMPATS_WIDGET) {
      if (response?.components?.head) {
        return {
          ...response.components.head.find(item => item.id === ID_COMPATS_WIDGET).widget_data,
        };
      }
      return {
        ...response.components.find(item => item.id === ID_COMPATS_WIDGET).widget_data,
      };
    }
    /* istanbul ignore next */
    if (response?.components?.content_left) {
      return {
        ...response.components.content_left
          .find(it => it.id === feature_name)
          .components.find(it => it.id === ID_COMPATS_WIDGET).widget_data,
        modal: {
          ...compats_widget_block.find(it => it.id === ID_COMPATS_WIDGET).widget_data.modal,
        },
      };
    }
    return {
      ...response.components.find(it => it.id === feature_name).components.find(it => it.id === ID_COMPATS_WIDGET)
        .widget_data,
      modal: {
        ...compats_widget_block.find(it => it.id === ID_COMPATS_WIDGET).widget_data.modal,
      },
    };
  };

  const isMobile = false;

  execGetCompatsProductServiceActions(
    dispatch,
    getterCompatsProductServiceActions(
      selectedInput,
      updateState,
      stateComponents,
      feature_name,
      fetchStickyComplete,
      fetchComplete,
      type,
      updateWidgetData,
      isMobile,
      getState,
    ),
  );
};

const execGetCompatsProductWebmobile = (dispatch, args) => {
  const {
    stateComponents,
    compats_widget_block,
    fetchStickyComplete,
    fetchComplete,
    selectedInput,
    updateState,
    feature_name,
    type,
    getState,
  } = args;

  const updateWidgetData = response => {
    trackEventCompatsInResponse(response, true, feature_name);
    /* istanbul ignore next */
    if (feature_name === SELLER || feature_name === COMPATS) {
      return {
        ...response.components.find(it => it.id === feature_name).components.find(it => it.id === ID_COMPATS_WIDGET)
          .widget_data,
      };
    }
    /* istanbul ignore next */
    return {
      ...response.components.find(it => it.id === feature_name).components.find(it => it.id === ID_COMPATS_WIDGET)
        .widget_data,
      modal: {
        ...compats_widget_block.find(it => it.id === ID_COMPATS_WIDGET).widget_data.modal,
      },
    };
  };

  const isMobile = true;

  execGetCompatsProductServiceActions(
    dispatch,
    getterCompatsProductServiceActions(
      selectedInput,
      updateState,
      stateComponents,
      feature_name,
      fetchStickyComplete,
      fetchComplete,
      type,
      updateWidgetData,
      isMobile,
      getState,
    ),
  );
};

const execCleanFormCompats = (dispatch, args) => {
  const {
    id,
    app,
    domain_id,
    params,
    fetchCompatsDeleteComplete,
    updateState,
    feature_name,
    deviceType,
    user_selection_path,
  } = args;
  const updateWidgetData = response => {
    /* istanbul ignore next */
    if (feature_name === SELLER || feature_name === COMPATS) {
      return {
        ...response.components.find(it => it.id === feature_name).components.find(it => it.id === ID_COMPATS_WIDGET)
          .widget_data,
      };
    }
    /* istanbul ignore next */
    if (feature_name === ID_COMPATS_WIDGET) {
      if (response?.components?.head) {
        return {
          ...response.components.head.find(item => item.id === ID_COMPATS_WIDGET).widget_data,
        };
      }
      return {
        ...response.components.find(item => item.id === ID_COMPATS_WIDGET).widget_data,
      };
    }
    return false;
  };

  dispatch({ type: FETCH_COMPATS_DELETE_START, id, params });
  APIService.getCompatsProduct(params)
    .then(response => {
      sessionStorage.removeItem(COMPATS_MESSAGE_TOP);
      dispatch({ type: fetchCompatsDeleteComplete, payload: response });
      const updateWidget = updateWidgetData(response);
      if (updateState && updateWidget) {
        updateState(updateWidget);
      }
      window.scroll({ top: 0, behavior: 'smooth' });
      trackEventCompatsInResponse(response, deviceType === 'mobile', null, true);
    })
    .catch(e => {
      dispatch({ type: FETCH_COMPATS_DELETE_ERROR, error: e });
    });

  const paramsDelete = {
    domain_id,
    app,
    user_selection_path,
  };

  // optimistic DELETE
  APIService.deleteCompats(paramsDelete)
    .then(response => response)
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

export {
  execGetCompatsProductServiceActions,
  execGetCompatsProductDesktop,
  execGetCompatsProductWebmobile,
  execCleanFormCompats,
};
