/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Image } from 'nordic/image';
import { loadable } from 'nordic/lazy';
import useZoomOptions from './hooks/use-zoom-options';
import getGallerySlide from './events/get-gallery-slide';
import StaticPropsContext from '../context/static-props';
import { BLANK_IMAGE, DEFAULT_GALLERY_LIMIT, DEFAULT_GALLERY_SELECTED, DEFAULT_LIGHTBOX_SETTINGS } from './constants';
import { constants } from '../../utils/constants';
import GalleryPropsContext from './context/gallery.contex';

const namespace = 'ui-pdp-gallery';
const { DEVICE_TYPE } = constants;
const ClipsWorkerComponent = loadable(() => import('./components/desktop/clips-worker-component-desktop'), {
  resolveComponent: components => components.ClipsWorkerComponent,
});
const Lightbox = loadable(() => import('../lightbox'));
const GalleryDesktop = props => {
  const {
    runCatchErrorBoundary,
    accessibility_text,
    app,
    clipsConfig,
    defaultSelected,
    limit,
    figures,
    multimediaConfig,
    showSnackbar,
    title,
    track,
    overlayAlpha,
  } = props;
  try {
    const zoomRef = useRef();
    const { deviceType } = useContext(StaticPropsContext);
    const { setCurrentIndex, setIsFullscreen, position } = useContext(GalleryPropsContext);
    const { zoomOptions, galleryOptions } = useZoomOptions(zoomRef, namespace);
    const [modalVisibility, setModalVisibility] = useState(false);
    const clipsRef = useRef(null);

    const handleBeforeOpen = () => {
      /* istanbul ignore next */
      setIsFullscreen(true);
    };

    const handleLightboxOnClose = () => {
      /* istanbul ignore block */
      setIsFullscreen(false);
      setModalVisibility(false);
    };

    const clipsWorkerComponentProps = {
      clipsConfig,
      showSnackbar,
      figures,
      ref: clipsRef,
    };

    const nameItem = figures[0]?.alt || '';
    const attributeRole = accessibility_text ? 'group' : null;
    const attributeAriaLabel = useMemo(
      () => (accessibility_text ? `${accessibility_text}${nameItem && `, ${nameItem}`}` : null),
      [accessibility_text, nameItem],
    );
    useEffect(() => {
      setCurrentIndex(defaultSelected);
    }, [defaultSelected, figures, setCurrentIndex]);
    const gallerySlide = useMemo(
      () =>
        getGallerySlide({
          position,
          deviceType,
          figures,
          zoomOptions,
          limit,
          namespace,
          clipsConfig,
          zoomRef,
          title,
          clipsRef,
          app,
          track,
          overlayAlpha,
          setModalVisibility,
          setCurrentIndex,
        }),
      [
        deviceType,
        figures,
        zoomOptions,
        limit,
        clipsConfig,
        app,
        overlayAlpha,
        title,
        track,
        position,
        setCurrentIndex,
      ],
    );

    const handleOnTouch = (...params) => clipsRef.current && clipsRef.current?.handleTouch(...params);

    return (
      <div className={namespace} role={attributeRole} aria-label={attributeAriaLabel}>
        <div
          ref={zoomRef}
          className={`${namespace}__zoom-container`}
          style={{ top: 0, left: 0, transform: `translate(${galleryOptions.left}px, ${galleryOptions.top}px)` }}
        />
        <div className={`${namespace}__column`}>
          <div className={`${namespace}__column__variation-picture`}>
            <Image
              id="variation-gallery"
              alt="variation-gallery"
              className={`${namespace}__column__variation-gallery`}
              src={BLANK_IMAGE}
              lazyload="off"
              decoding="async"
            />
          </div>
          {gallerySlide}
        </div>
        {modalVisibility && (
          <Lightbox
            pictures={figures}
            deviceType={DEVICE_TYPE.DESKTOP}
            onBeforeOpen={handleBeforeOpen}
            onClose={handleLightboxOnClose}
            onTouch={handleOnTouch}
            settings={DEFAULT_LIGHTBOX_SETTINGS.desktop(namespace)}
            videoConfig={multimediaConfig}
          />
        )}
        {clipsConfig?.hasClips && <ClipsWorkerComponent {...clipsWorkerComponentProps} />}
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};
GalleryDesktop.propTypes = {
  track: shape({}),
  clipsConfig: shape({
    gallery_position: number,
    autoplay: bool,
    snackbars: arrayOf(
      shape({
        id: string.isRequired,
        message: string,
        delay: number,
      }),
    ),
  }),
  defaultSelected: number,
  hasClips: bool,
  limit: number,
  figures: arrayOf(
    shape({
      id: string.isRequired,
      alt: string,
      src: string,
      src2x: string,
    }),
  ).isRequired,
  title: string,
  multimediaConfig: shape({
    alt: string,
    title: string,
    allow: string,
    frameBorder: number,
    showSpinner: bool,
  }),
  accessibility_text: string,
  app: string,
  showSnackbar: func,
  overlayAlpha: string,
  runCatchErrorBoundary: func,
};

GalleryDesktop.defaultProps = {
  track: null,
  limit: DEFAULT_GALLERY_LIMIT,
  defaultSelected: DEFAULT_GALLERY_SELECTED,
  title: null,
  multimediaConfig: null,
  accessibility_text: '',
  app: null,
  hasClips: false,
  clipsConfig: {},
  showSnackbar: null,
  overlayAlpha: null,
  runCatchErrorBoundary: () => {},
};
export default GalleryDesktop;
