import {
  VPP_BOUNDARY_DATADOG_KEY_PREFIX,
  VPP_BOUNDARY_DATADOG_KEY_CASES,
} from '../services/frontend-statsd/config/allowed-keys';
import { UNKNOWN_VALUE } from '../services/frontend-statsd/config/allowed-tags';

const CATCH_ACTION_FALLBACK = 'catch_action_fallback';
const CATCH_ACTION_CRASH = 'catch_action_crash';
const CATCH_ACTION_SKIP = 'catch_action_skip';

const catchErrorBoundary = ({ error, catchErrorBoundaryConfig = {} } = {}) => {
  const {
    app,
    deviceType,
    componentId,
    isCritical,
    catchContext,
    fallbackProps,
    FallbackComponent,
    ownership,
  } = catchErrorBoundaryConfig;

  const hasFallback = fallbackProps && FallbackComponent;
  const isClientSide = catchContext === 'client_side';

  const getCatchAction = () => {
    if (hasFallback) {
      return CATCH_ACTION_FALLBACK;
    }
    if (!isCritical || isClientSide) {
      return CATCH_ACTION_SKIP;
    }
    return CATCH_ACTION_CRASH;
  };

  const metricDataConfig = {
    key: `${VPP_BOUNDARY_DATADOG_KEY_PREFIX}.${VPP_BOUNDARY_DATADOG_KEY_CASES.ERROR_BOUNDARY_TRY_CATCH}`,
    tags: {
      referer_app: app,
      device_type: deviceType,
      catch_context: catchContext,
      component_id: componentId,
      is_critical: isCritical,
      catch_action: getCatchAction() || UNKNOWN_VALUE,
      ownership,
    },
    info: { error: error.stack },
    messageTitle: 'Vpp-ErrorBoundary - TryCatch',
  };

  // Send metrics and logs:
  // SSR Case: This use statsd lib on the server/node using global
  // CSR Case: This use the api on client using window
  if (global?.catchErrorBoundary && typeof global?.catchErrorBoundary === 'function') {
    global.catchErrorBoundary(metricDataConfig);
  }

  // Scenario #1 = Have the props + Have a fallback component
  // SSR Action to do: Render a fallback
  // CSR Action to do: Render a fallback
  if (hasFallback) {
    return <FallbackComponent {...fallbackProps} />;
  }

  // Scenario #2 = NOT have a fallback + Is a NON Critical component
  // SSR Action to do: Return null // skip the component
  // CSR Action to do: Return null // skip the component
  if (!isCritical) {
    return null;
  }

  // Scenario #3 = NOT have a fallback + Is a Critical component
  // CSR - Action to do: Return null // skip the component
  // NOTE: We do this in order to replicate the error-boundary behavior
  if (isClientSide) {
    return null;
  }
  // SSR - Action to do: Throw an exception in order to...Get catched by Error Middleware >> Crash and show an ErrorPage
  throw new Error(
    `Critical component crashes (without a fallback). Error on (${componentId}) component. Info: ${error}`,
  );
};

export default catchErrorBoundary;
