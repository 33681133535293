import a2cMapper from './a2c';
import couponsMapper from './coupons';
import followersMapper from './followers';
import meliplusMapper from './meliplus';
import onePay4AllMapper from './1pay4all';
import wishlistMapper from './wishlist';
import installationsMapper from './installations';
import tradeInMapper from './trade-in';
import quantityMapper from './quantity';
import onDemandIframeMapper from './on-demand-iframe';
import questionsMapper from './questions';
import recommendationsMapper from './recommendations';
import questionAiMapper from './question-ai';
import compatsWidgetMapper from './compats-widget';
import qadbMapper from './qadb';
import collapsibleCardMeliplusMapper from './collapsable-card-meliplus';

const featureMappers = {
  ...a2cMapper,
  ...couponsMapper,
  ...followersMapper,
  ...meliplusMapper,
  ...onePay4AllMapper,
  ...wishlistMapper,
  ...installationsMapper,
  ...tradeInMapper,
  ...quantityMapper,
  ...onDemandIframeMapper,
  ...questionsMapper,
  ...recommendationsMapper,
  ...questionAiMapper,
  ...compatsWidgetMapper,
  ...qadbMapper,
  ...collapsibleCardMeliplusMapper,
};

export default featureMappers;
