import {
  FETCH_MELIPLUS_INFO_START,
  FETCH_MELIPLUS_INFO_COMPLETE,
  FETCH_MELIPLUS_INFO_ERROR,
} from '../../../../actions/actions';

const FEATURE_NAME = 'loyalty_collapsible_card';

const fetchMeliplusInfoStart = state => ({
  ...state,
  components: {
    ...state.components,
    [FEATURE_NAME]: {
      ...state.components[FEATURE_NAME],
      loading: true,
      error: null,
    },
  },
});

const fetchMeliplusInfoComplete = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    [FEATURE_NAME]: {
      ...state.components[FEATURE_NAME],
      loading: false,
      data: action.payload,
    },
  },
});

const fetchMeliplusInfoError = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    [FEATURE_NAME]: {
      ...state.components[FEATURE_NAME],
      loading: false,
      data: action.payload,
      error: null,
    },
  },
});

const collapsibleCardMeliplusMapper = {
  [FETCH_MELIPLUS_INFO_START]: fetchMeliplusInfoStart,
  [FETCH_MELIPLUS_INFO_COMPLETE]: fetchMeliplusInfoComplete,
  [FETCH_MELIPLUS_INFO_ERROR]: fetchMeliplusInfoError,
};

export default collapsibleCardMeliplusMapper;
