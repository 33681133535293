import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import Questions from './questions';
import QuestionsList from './questions-list';
import NoQuestions from './questions-no-questions';
import QuestionWarningMessage from '../message/question-warning';
import OthersQuestionsDesktop from './questions-others-questions.desktop';
import QuestionsAiFormDesktop from './components/questions-ai-form/questions-ai-form';
import QuestionsAiSuggestionDesktop from './components/questions-ai-suggestion/questions-ai-suggestion';

const namespace = 'ui-pdp-questions';

const QuestionsDesktop = ({ quick_access, question_message, runCatchErrorBoundary, ...rest }) => {
  try {
    return (
      <>
        <Questions {...rest} quick_access={quick_access} question_message={question_message}>
          {rest.custom_message && (
            <QuestionWarningMessage
              customMessage={rest.custom_message}
              showVariationsError={rest.showVariationsError}
            />
          )}
          {rest.questions_ai && (
            <div className={`${namespace}__questions-ai`}>
              {rest.questions_ai?.questions_ai_form?.state === 'VISIBLE' && (
                <QuestionsAiFormDesktop
                  questionsAiForm={rest.questions_ai.questions_ai_form}
                  productId={rest.productId}
                  attributes={rest.attributes}
                  postQuestionFromAi={rest.postQuestionFromAi}
                  saveFrontendStatsd={rest.saveFrontendStatsd}
                  isFetching={rest.questions_ai.isFetching}
                />
              )}

              {rest.questions_ai?.questions_ai_suggestion?.state === 'VISIBLE' && (
                <QuestionsAiSuggestionDesktop
                  questionsAiSuggestion={rest.questions_ai.questions_ai_suggestion}
                  isUseful={rest.isUseful}
                  handleCreateQuestionFromAi={rest.onCreateQuestionFromAi}
                  showSnackbar={rest.showSnackbar}
                  saveFrontendStatsd={rest.saveFrontendStatsd}
                />
              )}
            </div>
          )}
          {rest.user_questions && (
            <QuestionsList
              isUsersQuestions
              showTitle
              questionsList={rest.user_questions}
              scrollToQuestion={rest.scrollToQuestion}
            />
          )}
          {rest.others_questions && (
            <OthersQuestionsDesktop
              title={rest.title}
              show_more={rest.show_more}
              questionsList={rest.others_questions}
            />
          )}
          {rest.empty_state && (
            <NoQuestions empty_state={rest.empty_state} className={`${namespace}__no-questions--desktop`} />
          )}
        </Questions>
      </>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

QuestionsDesktop.propTypes = {
  quick_access: shape({
    title: shape({ text: string.isRequired }).isRequired,
    actions: arrayOf(
      shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
      }),
    ),
  }),
  question_message: shape({
    title: shape({ text: string.isRequired }).isRequired,
    subtitle: shape({ text: string.isRequired }).isRequired,
    background_color: shape({ text: string.isRequired }).isRequired,
  }),
  saveFrontendStatsd: func.isRequired,
  runCatchErrorBoundary: func,
};

QuestionsDesktop.defaultProps = {
  question_message: null,
  quick_access: null,
  runCatchErrorBoundary: () => {},
};

export default QuestionsDesktop;
