import React from 'react';
import { string, shape, number, oneOfType, func } from 'prop-types';
import { Image } from 'nordic/image';
import StyledLabel from '../styled-label';
import { constants } from '../../utils/constants';
import { trackEvent } from '../../lib/tracking';

const namespace = 'ui-pdp-header-official-store';
const { VISIBILITY } = constants;

const HeaderOfficialStore = ({
  store_title,
  store_subtitle,
  store_picture,
  background,
  state,
  track,
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const visible = state === VISIBILITY.VISIBLE;
    React.useEffect(() => {
      if (visible && !!Object.keys(track).length) {
        trackEvent(track);
      }
    }, [track, visible]);

    if (visible) {
      return (
        <div className={`${namespace}-header`}>
          <div className={`${namespace}__bg-image`}>
            <div className={`${namespace}__image-wrapper`}>
              <Image src={background} alt="" role="presentation" lazyload="off" preload />
            </div>
            <div className={`${namespace}__logo-container`}>
              <div className={`${namespace}-card`}>
                {!!Object.keys(store_picture)?.length && (
                  <div className={`${namespace}-card__image-container`}>
                    <Image
                      className={`${namespace}-card__image-container__image`}
                      src={store_picture.url.src}
                      alt={store_picture?.alt}
                    />
                  </div>
                )}
                <div className={`${namespace}-card__title-container`}>
                  <StyledLabel as="p" {...store_title} />
                  <StyledLabel as="p" {...store_subtitle} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

HeaderOfficialStore.displayName = 'HeaderOfficialStore';
HeaderOfficialStore.propTypes = {
  store_title: shape({
    text: string,
    color: string,
    font_size: string,
  }).isRequired,
  state: string.isRequired,
  store_subtitle: shape({
    text: string,
    color: string,
    font_size: string,
  }).isRequired,
  store_picture: shape({
    url: shape({
      src: string,
    }),
    width: oneOfType([string, number]),
    height: oneOfType([string, number]),
  }),
  background: string.isRequired,
  track: shape({}),
  runCatchErrorBoundary: func,
};

HeaderOfficialStore.defaultProps = {
  title: null,
  logo: null,
  background: null,
  sub_title: null,
  track: {},
  store_picture: {},
  runCatchErrorBoundary: () => {},
};

export default HeaderOfficialStore;
