/**
 * Module dependencies
 */
import React from 'react';
import { StoreConsumer } from './context';
import ErrorBoundary from './error';
import getDisplayName from '../lib/get-display-name';
import getCatchErrorBoundaryConfig from '../error-boundary/get-config';
import get from 'lodash/get';
import catchErrorBoundary from '../error-boundary/catch-error-boundary';
import componentPrint from '../error-boundary/component-print';
import { UNKNOWN_VALUE } from '../services/frontend-statsd/config/allowed-tags';

const noop = () => {};

/**
 * connect
 */
const connect = (
  mapStateToProps = noop,
  mapDispatchToProps = noop,
  hardcodedComponentId = UNKNOWN_VALUE,
) => WrappedComponent => {
  const ConnectedComponent = ownProps => {
    const { featureName, id } = ownProps;
    return (
      <StoreConsumer>
        {({ history, state, dispatch }) => {
          const stateProps = {
            app: state?.app || UNKNOWN_VALUE,
            deviceType: state?.deviceType || UNKNOWN_VALUE,
          };
          const catchErrorBoundaryConfig = getCatchErrorBoundaryConfig({
            componentId: featureName || id || hardcodedComponentId,
            backendConfig: state?.errorBoundaryGeneralConfiguration,
            extraConfig: stateProps,
            componentInfo: get(state, `components.${featureName}`, false),
          });

          const runCatchErrorBoundary = error => catchErrorBoundary({ error, catchErrorBoundaryConfig });

          componentPrint({ catchErrorBoundaryConfig });

          return (
            <ErrorBoundary metricDataConfig={catchErrorBoundaryConfig}>
              <>
                <WrappedComponent
                  history={history}
                  runCatchErrorBoundary={runCatchErrorBoundary}
                  {...mapStateToProps(state, ownProps)}
                  {...(typeof mapDispatchToProps === 'function' ? mapDispatchToProps(dispatch, ownProps) : null)}
                />
              </>
            </ErrorBoundary>
          );
        }}
      </StoreConsumer>
    );
  };
  ConnectedComponent.displayName = `Connect(${getDisplayName(WrappedComponent || {})})`;
  return ConnectedComponent;
};

/**
 * Expose connect
 */
export default connect;
