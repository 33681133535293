import { trackEvent } from '@vpp-frontend-components/common';

const ID_COMPATS_WIDGET = 'compats_widget';
const SELLER = 'seller';
const COMPATS = 'compats';

const trackEventCompatsInResponse = (response, isMobile, feature_name = null, isClean = false) => {
  let widgetTrack;
  const getWidgetTrack = (component, id_component = null) => {
    if (Array.isArray(component)) {
      return id_component
        ? component.find(it => it.id === id_component)?.components?.find(it => it.id === ID_COMPATS_WIDGET).widget_data
            ?.track
        : component.find(item => item.id === ID_COMPATS_WIDGET)?.widget_data?.track;
    }
    return null;
  };

  if (isClean) {
    widgetTrack = isMobile
      ? getWidgetTrack(response.components)
      : getWidgetTrack(response.components?.head ?? response?.components);
  } else {
    widgetTrack = isMobile
      ? getWidgetTrack(response.components, feature_name ?? 'all_features')
      : getWidgetTrack(
          response.components?.content_left ?? response?.components,
          feature_name ?? 'technical_specifications',
        );
  }
  widgetTrack =
    widgetTrack ||
    (isMobile
      ? getWidgetTrack(response.components, SELLER)
      : getWidgetTrack(response.components?.short_description ?? response?.components, SELLER));
  widgetTrack =
    widgetTrack ||
    (isMobile
      ? getWidgetTrack(response.components, COMPATS)
      : getWidgetTrack(response.components?.short_description ?? response?.components, COMPATS));
  if (widgetTrack) {
    trackEvent(widgetTrack);
  }
};

export default trackEventCompatsInResponse;
