import React from 'react';
import { shape, string } from 'prop-types';
import classnames from 'classnames';
import colornames from '../../lib/colornames';
import componentEnhance from '../../lib/component-enhance';
import StyledLabel from '../styled-label';
import IconFactory from '../icons/factory-builder';
import Message from '../message';
import Media from '../media';
import { sizenames } from '@vpp-frontend-components/common';

const QuestionsMessageLocation = ({ icon, hierarchy, message_type, title, type }) => {
  const namespace = 'ui-pdp-questions';
  return (
    <div>
      {type === 'SIMPLE_TEXT' && (
        <Media
          className={classnames(`${namespace}__message-location--simple`, colornames(icon))}
          title={title}
          figure={IconFactory(icon, classnames(sizenames(icon.size)))}
        />
      )}
      {type === 'UI_MESSAGE' && (
        <Message className={`${namespace}__message-location--container`} hierarchy={hierarchy} type={message_type}>
          <div className={`${namespace}__message-location__text`}>
            <StyledLabel as="span" text={title && componentEnhance.jsx(title.text || title, title.values)} />
          </div>
        </Message>
      )}
    </div>
  );
};

QuestionsMessageLocation.propTypes = {
  hierarchy: string,
  icon: shape({ id: string, color: string }),
  message_type: string,
  title: shape(),
  type: string,
};

QuestionsMessageLocation.defaultProps = {
  hierarchy: null,
  icon: null,
  message_type: null,
  title: null,
  type: null,
};

export default QuestionsMessageLocation;
