import React from 'react';
import { generateKeyToComponent } from '../../../utils/validators';
import { Button } from '@andes/button';
import { namespace } from '../model';

export const buildMotorsQuestionButton = actions =>
  actions.map(action => (
    <Button
      key={generateKeyToComponent(action)}
      className={`${namespace}__button`}
      hierarchy="loud"
      href={action.target}
    >
      {action.label?.text}
    </Button>
  ));
