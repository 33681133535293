/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';

import { arrayOf, shape, string, func } from 'prop-types';
import classNames from 'classnames';
import { Button } from '@andes/button';
import Iframe from '../iframe';
import { isShippingCalculator, getShippingCalculatorProps } from './questions-shipping-calculator-props';
import { isMultipleVariations } from './questions-buy-multiple-variations-props';
import useTooltipWithFrequencyStatus from '../../hooks/use-tooltip-frequency';
import TooltipContent from '../tooltip/tooltip-content';
import availableComponents from '../tooltip/tooltip-available-components';
import { generateKeyToComponent } from '../../utils/validators';
import ActionModal from '../action-modal';

const namespace = 'ui-pdp-questions';

const localStorageQuestionsTooltipId = 'TOOLTIP_QUESTIONS';

const QuestionsQuickAccess = ({ actions, className, title, onIframeMessage, tooltip, onScrollComponent }) => {
  const [showTooltip, toggleTooltipVisibility] = useTooltipWithFrequencyStatus(localStorageQuestionsTooltipId, -1);
  const getTarget = target => (isShippingCalculator(target) ? `${target}&quick_access=true` : target);
  const handleTooltipPrimaryAction = toggleVisibility => {
    localStorage.setItem(localStorageQuestionsTooltipId, Math.floor(Date.now() / 1000));
    toggleVisibility(false);
  };

  return (
    <>
      {title && <h3 className={`${namespace}__subtitle`}>{title.text}</h3>}
      <div className={`${namespace}__list-container`}>
        <ul className={classNames(`${namespace}__list`, className)}>
          {actions.map((action, index) => {
            const actionKey = generateKeyToComponent(action);
            if (action.action_type === 'SCROLL_TO') {
              return (
                <li key={actionKey} data-testid={`quick-access-${index}`} className={`${namespace}__item`}>
                  <Button
                    onClick={() => onScrollComponent({ target: action.target.replace('#', ''), focus: true })}
                    hierarchy="quiet"
                    size="medium"
                  >
                    {action.label.text}
                  </Button>
                </li>
              );
            }
            return (
              <li key={actionKey} data-testid={`quick-access-${index}`} className={`${namespace}__item`}>
                <ActionModal
                  messageId="quick-access-modal"
                  label={action.label}
                  url={getTarget(action.target)}
                  linkClassName={`andes-button andes-button--quiet andes-button--medium ${namespace}__link`}
                  modalClassName={classNames('ui-pdp-iframe-modal', `ui-pdp-questions-${index}`, {
                    'ui-pdp-generic-summary-modal ui-pdp-shipping-modal': isShippingCalculator(action.target),
                    'ui-pdp-action-modal__buy-multiple-variations': isMultipleVariations(action.target),
                  })}
                  modalUrl={title ? `#questions-${index}` : null}
                  modalTitle={action.modal_title}
                  autoHeight={false}
                  track={action.track}
                  closeTrack={action.close_track}
                  {...getShippingCalculatorProps(action.target)}
                  closeModalLabel={action.close_modal_label}
                  shouldUseSeoAnchor
                >
                  <Iframe
                    id="quick-access-iframe"
                    title={action.label.text}
                    src={getTarget(action.target)}
                    onMessage={onIframeMessage}
                    scrolling="no"
                  />
                </ActionModal>
              </li>
            );
          })}
        </ul>
        {tooltip && showTooltip && (
          <div id="questionTooltip" className={`${namespace}__tooltip`}>
            <div>
              {tooltip.title && <p className={`${namespace}__tooltip__primary`}>{tooltip.title.text}</p>}
              <TooltipContent
                availableComponents={availableComponents}
                content={tooltip.content}
                components={tooltip.components}
              />
            </div>
            <span
              className={`${namespace}__tooltip__close`}
              onClick={() => handleTooltipPrimaryAction(toggleTooltipVisibility)}
            />
          </div>
        )}
      </div>
    </>
  );
};

QuestionsQuickAccess.propTypes = {
  actions: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
      }),
      target: string.isRequired,
    }),
  ).isRequired,
  className: string,
  title: shape({ text: string.isRequired }).isRequired,
  onIframeMessage: func,
  tooltip: shape({
    title: shape({ text: string }),
    content: shape({}),
    components: arrayOf(shape({})),
  }),
  onScrollComponent: func,
};

QuestionsQuickAccess.defaultProps = {
  className: null,
  onIframeMessage: null,
  tooltip: null,
  onScrollComponent: null,
};

export default QuestionsQuickAccess;
