import { useMemo, startTransition, useCallback } from 'react';
import classnames from 'classnames';
import { bool, func, number, shape, string, arrayOf } from 'prop-types';
import * as Tracking from '../../lib/tracking';
import { Text } from '@andes/typography';
import { Pill } from '@andes/badge';
import { MoneyAmount } from '@andes/money-amount';
import HistoryService from '../../services/history';
import { runSchedulerTask } from '../../utils/validators';
import { componentEnhance } from '@vpp-frontend-components/component-enhance';
import { formatMoneyByLocale } from '../../utils/format-money-by-locale';

const namespace = 'ui-vpp-price-per-quantity-shortcuts';
const shortcutNamespace = `${namespace}__shortcut`;

const QUANTITY_DEFAULT = 1;

const PricePerQuantityShortcuts = ({ className, id, onSetQuantity, shortcuts, runCatchErrorBoundary, locale }) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const handleClickShortcut = useCallback(shortcut => {
      const quantitySelected = shortcut.selected ? QUANTITY_DEFAULT : shortcut.quantity;

      startTransition(() => {
        onSetQuantity(quantitySelected);
        Tracking.trackQuantityEvent(shortcut.track, quantitySelected);
        runSchedulerTask(() => {
          HistoryService.pushParam('quantity', quantitySelected, true);
        }, 'background');
      });
    }, [onSetQuantity]);

    const handleKeydownShortcut = useCallback((e, shortcut) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        handleClickShortcut(shortcut);
      }
    }, [handleClickShortcut]);

    const validPrices = useMemo(
      () =>
        shortcuts?.every(shortcut => {
          const value = shortcut.price?.value;
          return typeof value === 'number' && value > 0;
        }),
      [shortcuts],
    );

    if (!validPrices) {
      return null;
    }

    return (
      <div className={classnames(namespace, className)} id={id}>
        {shortcuts.map(shortcut => {
          const { fraction, cents } = formatMoneyByLocale(shortcut.price.value, locale);

          return (
            <div
              className={classnames(`${shortcutNamespace}`, { [`${shortcutNamespace}--selected`]: shortcut.selected })}
              key={shortcut.header.text}
              role="button"
              tabIndex="0"
              onClick={() => handleClickShortcut(shortcut)}
              onKeyDown={e => handleKeydownShortcut(e, shortcut)}
              data-testid={`shortcut-${shortcut.quantity}-units`}
            >
              <Text size="xs">{componentEnhance.jsx(shortcut.header.text, shortcut.header.values)}</Text>
              <div className={classnames(`${shortcutNamespace}--price`)}>
                <MoneyAmount
                  className={classnames(`${shortcutNamespace}--price-value`)}
                  size={18}
                  weight="semibold"
                  centsType="superscript"
                  symbol={shortcut.price.currency_symbol}
                  currency_id={shortcut.price.currency_id}
                  value={{
                    fraction,
                    cents,
                  }}
                />
                <Text size="xs">{shortcut.price.suffix.text}</Text>
                <Text className={classnames(`${shortcutNamespace}--price-discount`)} size="s" color="positive">
                  {` ${shortcut.discount_label.value}% off`}
                </Text>
              </div>
              {shortcut.best_offer && (
                <Pill
                  className={classnames(`${shortcutNamespace}--best-offer`)}
                  size="small"
                  color="green"
                  hierarchy="quiet"
                  contentType="text"
                  roundedCorners={['bottom-left']}
                  data-testid={`shortcut-${shortcut.quantity}-best-offer`}
                >
                  {shortcut.best_offer.text}
                </Pill>
              )}
            </div>
          );
        })}
      </div>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

PricePerQuantityShortcuts.propTypes = {
  className: string,
  shortcuts: arrayOf(
    shape({
      selected: bool,
      quantity: number,
      header: shape({
        text: string,
        values: shape({
          equivalence: shape({
            text: string,
          }),
        }),
      }),
      best_offer: shape({
        text: string,
      }),
      price: shape({
        type: string,
        value: number,
        currency_symbol: string,
        currency_id: string,
        decimal_style: string,
        grouping_separator: string,
        suffix: shape({
          text: string,
          accessibility_text: string,
        }),
      }),
      discount_label: shape({
        value: number,
      }),
      track: shape({
        melidata_event: shape({
          path: string,
          event_data: shape({
            item_id: string,
            quantity: string,
          }),
        }),
      }),
    }),
  ),
  id: string,
  locale: string,
  onSetQuantity: func.isRequired,
  runCatchErrorBoundary: func,
};

PricePerQuantityShortcuts.defaultProps = {
  className: null,
  id: null,
  locale: null,
  shortcuts: null,
  runCatchErrorBoundary: () => {},
};

export default PricePerQuantityShortcuts;
