import HistoryService from '../../../services/history';
import getAttributesParam from '../lib/get-attributes-param';
import { trackEvent } from '../../../lib/tracking';
import { DECORATION_BLOCKED, DECORATION_SELECTED } from './types';
import { runSchedulerTask } from '../../../utils/validators';

const namespace = 'ui-pdp-variations';

const setLabel = (id, selected) => {
  const currentSelectedLabel = document.getElementById(id);
  const label = document.createElement('span');
  let textNode = document.createTextNode('');

  if (selected) {
    textNode = document.createTextNode(selected.text);

    if (selected.color) {
      label.className += ` ui-pdp-color--${selected.color}`;
    }
  }
  label.appendChild(textNode);

  const icon = document.getElementById(`icon-${id}`);

  if (icon) {
    if (selected && selected.text_icon) {
      icon.style.display = 'inline';
    } else {
      icon.style.display = 'none';
    }
  }

  if (currentSelectedLabel) {
    if (!selected) {
      currentSelectedLabel.style.marginRight = '0px';
    } else {
      currentSelectedLabel.style.marginRight = '4px';
    }
    if (currentSelectedLabel.childNodes.length) {
      currentSelectedLabel.replaceChild(label, currentSelectedLabel.childNodes[0]);
    } else {
      currentSelectedLabel.appendChild(label);
    }
  }
};

const setSelectedSubtitle = (id, selectedSubtitle) => {
  const currentSelectedSubtitle = document.getElementById(id);
  const label = document.createElement('span');
  let textNode = document.createTextNode('');

  if (selectedSubtitle) {
    textNode = document.createTextNode(selectedSubtitle.text);
  }
  label.appendChild(textNode);

  if (currentSelectedSubtitle?.childNodes.length) {
    currentSelectedSubtitle.replaceChild(label, currentSelectedSubtitle.childNodes[0]);
  } else {
    currentSelectedSubtitle.appendChild(label);
  }
};

const showError = picker => picker.error_message && !picker.error_message.is_deferred;

const onMouseEnter = (id, pickerSelectedSubtitleId, selected, selectedSubtitle, src, srcSet, setHovered) => {
  setHovered(true);
  // srcSet has src as fallback

  /*
  TODO: Remove this code after fixing the blank image issue

  if (src) {
    const blankImage = document.getElementById('variation-gallery');
    if (blankImage) {
      blankImage.setAttribute('src', src);
      blankImage.setAttribute('srcset', srcSet);
      blankImage.parentNode.setAttribute('style', 'display: flex');
    }
  }
  */
  setLabel(id, selected);
  if (pickerSelectedSubtitleId) {
    setSelectedSubtitle(pickerSelectedSubtitleId, selectedSubtitle);
  }
};

const onMouseLeave = (id, pickerSelectedSubtitleId, selected, selectedSubtitle, shouldUpdateToBlank, setHovered) => {
  setHovered(false);

  /*
  TODO: Remove this code after fixing the blank image issue

  if (shouldUpdateToBlank) {
    const blankImage = document.getElementById('variation-gallery');
    if (blankImage) {
      blankImage.parentNode.setAttribute('style', 'display: none');
    }
  }
  */

  setLabel(id, selected);
  if (pickerSelectedSubtitleId) {
    setSelectedSubtitle(pickerSelectedSubtitleId, selectedSubtitle);
  }
};

const handleOnSelectVariation = (
  product,
  selectedQuantity,
  selectedAttributes,
  pickerId,
  hasDeferredErrors,
  onSelectVariation,
  platform,
  setIsLoading,
) => {
  const permalink = product?.permalink?.toString();
  const params = permalink && new URLSearchParams(permalink.substring(permalink.indexOf('?')));
  const showed_trade_in = params && params.get('showed_trade_in');

  if (product.decoration_type !== DECORATION_SELECTED && product.decoration_type !== DECORATION_BLOCKED) {
    const attributesToReset = product.attributes || [];
    if (selectedAttributes) {
      attributesToReset.forEach(attribute => {
        if (selectedAttributes[attribute]) {
          delete selectedAttributes[attribute];
        }
      });
    }
    onSelectVariation(
      product.id,
      {
        attributes: getAttributesParam(selectedAttributes, pickerId, product.attribute_id),
        quantity: selectedQuantity,
        has_deferred_errors: hasDeferredErrors,
        platform,
        showed_trade_in,
      },
      setIsLoading,
    );
    runSchedulerTask(() => {
      trackEvent(product.track);
      HistoryService.replaceProductAndParam(product.permalink, { productId: product.id }, 'quantity', selectedQuantity);
    }, 'background');
  }
};

export { setLabel, setSelectedSubtitle, showError, onMouseEnter, onMouseLeave, handleOnSelectVariation, namespace };
