import React from 'react';
import { shape, string, func } from 'prop-types';
import classNames from 'classnames';
import Typography from '@andes/typography';

const namespace = 'ui-pdp-accessibility-texts';
const accessibilityText = 'jump_to_purchase_section';

const JumpToPurchase = ({ className, accesibilities, runCatchErrorBoundary }) => {
  try {
    return (
      <div className={classNames(namespace, className)}>
        {/* Passing component="a" and href should do the job */}
        <Typography component="a" color="link" href="#buybox-form" className={`${namespace}__buy`}>
          {accesibilities[`${accessibilityText}`]}
        </Typography>
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

JumpToPurchase.propTypes = {
  className: string,
  accesibilities: shape({
    jump_to_purchase_section: string,
  }).isRequired,
  runCatchErrorBoundary: func,
};

JumpToPurchase.defaultProps = {
  className: null,
  accesibilities: [],
  runCatchErrorBoundary: () => {},
};

export default JumpToPurchase;
