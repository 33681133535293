/**
 * Styles
 */
import '../../../pages/vpp/styles/vpp.desktop.scss';

/**
 * Polyfills
 */
import 'drift-zoom/dist/Drift.min';

/**
 * Module dependencies
 */
import bootstrap from '../../bootstrap';

import Vpp from '../../../pages/vpp/components/views/main/main.desktop';

bootstrap(Vpp);
