import React from 'react';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';
import { trackEvent } from '../../../lib/tracking';

const useEnhanceVideo = (media, device, placeholderRef) => {
  const playerRef = React.useRef(null);
  const timerRef = React.useRef(null);
  const {
    track_viewing: trackViewing,
    track_duration: trackDuration,
    track_complete: trackComplete,
    play: { accesibility: accesibilityPlayText = '' },
  } = media;

  const [isOver, setIsOver] = React.useState(false);
  // eslint-disable-next-line react/hook-use-state
  const [, setTracksSended] = React.useState({ viewing: false, duration: false, complete: false });
  const [isIntersecting] = useIntersectionObserver(placeholderRef, { threshold: 0.1 });

  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isMounting, setIsMounting] = React.useState(true);

  React.useEffect(() => {
    const options = {
      controls: true,
      preload: 'auto',
      loadingSpinner: true,
      autoplay: false,
      playsinline: device === 'mobile',
      controlBar: {
        pictureInPictureToggle: false,
        ...(device === 'desktop' ? { skipButtons: { forward: 10, backward: 10 } } : {}),
      },
      sources: [
        {
          src: media?.url,
          type: media?.video_type,
        },
      ],
      poster: media?.thumbnail?.url?.src,
    };
    let player = playerRef.current;

    const placeholderEl = placeholderRef.current;
    const videoElement = placeholderEl.appendChild(document.createElement('video-js'));

    if (!player) {
      if (!videoElement) {
        return;
      }
      // eslint-disable-next-line no-undef
      player = videojs(videoElement, options);

      playerRef.current = player;
    }

    player.autoplay(options.autoplay);
    player.src(options.sources);

    player.on('ready', () => {
      setIsMounting(false);
    });

    player.on('touchstart', e => {
      if (device === 'desktop' || isPlaying || e.target.className === 'vjs-icon-placeholder') {
        return;
      }

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      setIsOver(true);
      timerRef.current = setTimeout(() => {
        setIsOver(false);
      }, 2500);
    });

    player.on('play', () => {
      setIsPlaying(true);
    });
    player.on('pause', () => {
      setIsPlaying(false);
      setTracksSended(prev => {
        if (prev.duration) {
          return prev;
        }
        trackEvent({
          ...trackDuration,
          melidata_event: {
            ...trackDuration?.melidata_event,
            event_data: {
              ...trackDuration?.melidata_event?.event_data,
              duration_viewing: player.currentTime(),
            },
          },
        });
        return { ...prev, duration: true };
      });
    });

    player.on('ended', () => {
      setTracksSended(prev => {
        if (prev.complete) {
          return prev;
        }
        trackEvent(trackComplete);
        return { ...prev, complete: true };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media, device, trackComplete, trackDuration]);

  React.useEffect(() => {
    if (!isIntersecting && !isMounting) {
      playerRef?.current.pause();
    }
  }, [isIntersecting, isMounting]);

  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const play = () => {
    setIsOver(false);
    playerRef.current.play();
    setIsPlaying(true);
    setTracksSended(prev => {
      if (prev.viewing) {
        return prev;
      }
      trackEvent(trackViewing);
      return { ...prev, viewing: true };
    });
  };
  const pause = () => {
    playerRef.current.pause();
    setIsPlaying(false);
  };

  const forward = () => playerRef.current.currentTime(playerRef.current.currentTime() + 10);
  const backward = () => playerRef.current.currentTime(playerRef.current.currentTime() - 10);

  return {
    playerRef,
    isPlaying,
    play,
    pause,
    forward,
    backward,
    isOver,
    placeholderRef,
    isMounting,
    setIsOver,
    accesibilityPlayText,
  };
};

export default useEnhanceVideo;
