import { useEffect, useRef } from 'react';
import { string } from 'prop-types';
import { feStatsdHelper } from '../../../../utils/frontendStatsdHelper';

import {
  VPP_BUY_BOX_WINNER_DATADOG_KEY_PREFIX,
  VPP_BUY_BOX_WINNER_DATADOG_KEY_CASES,
} from '../../../../services/frontend-statsd/config/allowed-keys';

const MetricWinner = ({ itemId, siteId }) => {
  const statsdConfigRef = useRef({
    keyPrefix: VPP_BUY_BOX_WINNER_DATADOG_KEY_PREFIX,
  });

  useEffect(() => {
    const fragment = window?.location?.hash?.substring(1);
    if (!fragment) {
      return;
    }
    const fragmentObject = fragment.split?.('&').reduce((acc, pair) => {
      const [key, value] = pair.split('=');
      acc[key] = value;
      return acc;
    }, {});

    if (!fragmentObject.wid) {
      return;
    }

    const isDifferentWinner = fragmentObject?.wid && itemId && itemId !== fragmentObject.wid;

    feStatsdHelper({
      key: VPP_BUY_BOX_WINNER_DATADOG_KEY_CASES.DISCREPANCY,
      statsdConfig: statsdConfigRef.current,
      additionalTags: {
        is_different_from_origin: isDifferentWinner,
        origin: fragmentObject.sid,
        site_id: siteId,
      },
    });
  }, [itemId, siteId]);

  return null;
};

MetricWinner.propTypes = {
  itemId: string,
  siteId: string,
};

export default MetricWinner;
