import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import GalleryWrapper from '../../../../components/gallery';
import jsonToProps from '../../../../components/gallery/json-to-props';

const FEATURE_NAME = 'gallery';

const mapStateToProps = ({ components: { [FEATURE_NAME]: gallery, header } }) => ({
  ...jsonToProps({ ...gallery, titleForAlt: header?.title }),
  app: 'pdp',
  title: header?.title,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(GalleryWrapper);
