import React from 'react';
import { bool, func, number, string, shape, oneOfType, arrayOf } from 'prop-types';
import classnames from 'classnames';
import useZoom from '../../hooks/use-zoom';
import Thumbnail from '../../../thumbnail';
import figureProviderFactory from '../../../figure';
import { PROVIDER } from '../../../figure/constants';
import StyledOverlay from '../../../styled-overlay';

const namespace = 'ui-pdp-gallery';

const GallerySlide = props => {
  const {
    index,
    container,
    zoomOptions,
    selected,
    title,
    onMouseHover,
    onClick,
    onMute,
    onFullscreen,
    showThumbnail,
    seeMoreAmount,
    provider,
    figure,
    app,
    autoplay,
    overlayAlpha,
    onKeyDown,
  } = props;
  const { alt, id, zoom, thumbnail, url, a11yLabel } = figure;
  const FigureProvider = figureProviderFactory(provider);
  const ref = useZoom(container, id, zoomOptions);

  const thumbnailPicture = thumbnail
    ? {
        alt,
        id,
        src: url ? thumbnail : thumbnail.src,
        src2x: url ? thumbnail : thumbnail.src2x,
        a11yLabel,
      }
    : null;

  const zoomSrc = zoom ? zoom.src2x || zoom.src : null;
  const seeMoreLabel = seeMoreAmount ? <span className={`${namespace}__see-more`}>+{seeMoreAmount}</span> : null;
  const preload = index === 0;

  const figureContent = (
    <FigureProvider.Figure
      {...figure}
      key={`gallery-figure-${id}`}
      className={`${namespace}__figure__${provider.toLowerCase()}`}
      index={index}
      zoom={zoomSrc}
      onClick={onClick}
      onMute={onMute}
      onFullscreen={onFullscreen}
      preload={preload}
      lazyload={!preload}
      autoplay={autoplay}
      width={app === 'pdp' ? 410 : 700}
      height={500}
      fetchPriority={preload ? 'high' : null}
    />
  );

  /* istanbul ignore next */
  const handleClick = () => {
    if (seeMoreLabel) {
      if (provider === PROVIDER.CLIP) {
        onFullscreen();
      } else {
        onClick();
      }
    }
  };

  return (
    <>
      <input
        className={`${namespace}__input`}
        type="radio"
        name="gallery-radio"
        id={`gallery-thumbnail-${id}`}
        value={index}
        checked={selected}
        readOnly
      />
      <span className={`${namespace}__wrapper`}>
        {thumbnailPicture && showThumbnail && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/mouse-events-have-key-events
          <label htmlFor={`gallery-thumbnail-${id}`} className={`${namespace}__label`} onMouseOver={onMouseHover}>
            <Thumbnail
              key={`gallery-thumbnail-img-${id}`}
              className={`${namespace}__thumbnail`}
              picture={thumbnailPicture}
              label={title}
              alt={alt}
              data-testid={`thumbnail-${id}`}
              overlay={
                seeMoreLabel ||
                (FigureProvider.Icon && (
                  <FigureProvider.Icon className={`${namespace}__thumbnail-icon`} {...figure.icon} />
                ))
              }
              onClick={handleClick}
              onKeyDown={onKeyDown}
              width={44}
              height={44}
            />
          </label>
        )}
        <figure
          className={classnames(`${namespace}__figure`, { [`${namespace}__figure__with-overlay`]: !!overlayAlpha })}
          ref={ref}
        >
          {figureContent}
          {overlayAlpha && (
            <StyledOverlay className={`${namespace}__overlay`} color="black" overlayAlpha={overlayAlpha} app={app} />
          )}
        </figure>
      </span>
    </>
  );
};

GallerySlide.propTypes = {
  app: string,
  alt: string,
  container: shape({}).isRequired,
  index: number.isRequired,
  showThumbnail: bool.isRequired,
  selected: bool,
  seeMoreAmount: number,
  src: string,
  src2x: string,
  title: string,
  url: string,
  videoConfig: shape({
    alt: string,
    title: string,
    allow: string,
    frameBorder: number,
    showSpinner: bool,
  }),
  zoom: shape({
    src2x: string,
    src: string.isRequired,
  }),
  zoomOptions: shape({
    zoomFactor: number,
    hoverBoundingBox: bool,
    onShow: func,
    onHide: func,
  }),
  onClick: func.isRequired,
  onMouseHover: func.isRequired,
  onKeyDown: func.isRequired,
  onMute: func,
  onFullscreen: func,
  width: number,
  height: number,
  multimedia: bool,
  provider: string,
  figure: shape({
    id: oneOfType([string, number]),
    alt: string,
    zoom: shape({
      src: string,
      src2x: string,
    }),
    thumbnail: oneOfType([
      string,
      shape({
        src2x: string,
        src: string,
      }),
    ]),
    url: string,
    tracks: arrayOf(
      shape({
        melidata_event: shape({}),
      }),
    ),
  }).isRequired,
  autoplay: bool,
  overlayAlpha: string,
};

GallerySlide.defaultProps = {
  app: null,
  provider: PROVIDER.IMAGE,
  selected: false,
  videoConfig: null,
  src: null,
  src2x: null,
  seeMoreAmount: null,
  url: null,
  zoomOptions: {},
  zoom: null,
  title: null,
  width: 0,
  height: 0,
  alt: null,
  multimedia: false,
  autoplay: false,
  overlayAlpha: null,
};

export default GallerySlide;
