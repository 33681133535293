import { bool, number, func, oneOfType, shape, string, arrayOf } from 'prop-types';

// Desktop
export const namespaceDesktop = 'ui-pdp-quantity-selector';
export const quantitySelectorDesktopPropTypes = {
  message: string,
  picker: shape({
    description: string,
    title: string.isRequired,
    selected: number,
    selected_label: shape({
      text: string.isRequired,
    }).isRequired,
    track: shape({}),
  }).isRequired,
  quantitySelector: shape({
    type: string,
    available_quantity: number,
    selector: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      template: shape({
        singular: string,
        plural: string,
        more: string,
        site_code: string,
        sales_unit: shape({
          unit: string,
          unit_measure: string,
          singular: string,
          plural: string,
        }),
        volume_discount_options: arrayOf(
          shape({
            quantity: shape({
              text: string,
            }),
            amount: shape({
              fraction: string,
              cents: string,
              suffix: shape({
                text: string,
              }),
            }),
          }),
        ),
        custom_options: arrayOf(
          shape({
            type: string,
            quantity: number,
            display_value: shape({
              quantity: shape({
                text: string,
                value: number,
                values: shape({
                  equivalence: shape({
                    text: string,
                  }),
                }),
              }),
              amount: shape({
                type: string,
                value: number,
                package_value: number,
                currency_id: string,
                decimal_style: string,
                suffix: shape({
                  text: string,
                  accessibility_text: string,
                }),
              }),
            }),
          }),
        ),
      }).isRequired,
      rows: number,
    }).isRequired,
    input: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      placeholder: string,
      confirm_button: shape({ label: shape({ text: string }) }),
      error_message: shape({
        text: string,
        color: string,
      }),
    }).isRequired,
    track: shape({}),
  }),
  setQuantity: func.isRequired,
  showInput: bool.isRequired,
  trigger: oneOfType([func, shape({ current: shape() })]),
  onShowInput: func.isRequired,
  buttonFocus: bool,
};

export const quantityInputItemPropTypes = {
  showInput: bool,
  hasCustomOptions: bool,
  listPickerRef: shape({}),
  picker: shape({
    title: string.isRequired,
  }).isRequired,
  quantitySelector: shape({
    available_quantity: number.isRequired,
    input: shape({
      confirm_button: shape({
        label: shape({
          text: string.isRequired,
        }).isRequired,
      }).isRequired,
      error_message: string,
      min_error_message: string,
      placeholder: string,
    }).isRequired,
    selector: shape({
      template: shape({
        more: string,
        title: string,
      }),
      rows: number.isRequired,
    }).isRequired,
  }).isRequired,
  minimumQuantity: number,
  bulkSale: bool,
  localQuantity: number.isRequired,
  formatBulkQuantity: func.isRequired,
  onClickListItem: func.isRequired,
  inputRef: shape({}),
  closeTooltip: func.isRequired,
  onShowInput: func.isRequired,
  customOptionsItems: arrayOf({}),
  yieldValue: number,
};

export const quantityListItemPropTypes = {
  index: number.isRequired,
  offsetIndex: number.isRequired,
  localQuantity: number.isRequired,
  quantitySelector: shape({}).isRequired,
  onClickListItem: func.isRequired,
  closeTooltip: func.isRequired,
  labelElement: func.isRequired,
  showTitle: bool,
  amount: shape({}).isRequired,
  quantity: shape({}).isRequired,
  hasBulkCustomOptions: bool,
};

// Mobile
export const namespaceMobile = 'ui-pdp-buybox__quantity';
export const quantitySelectorPropTypes = {
  quantitySelector: shape({
    type: string,
    available_quantity: number,
    selector: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      template: shape({
        singular: string,
        plural: string,
        more: string,
        volume_discount_options: arrayOf(
          shape({
            quantity: shape({
              text: string,
            }),
            amount: shape({
              fraction: string,
              cents: string,
              suffix: shape({
                text: string,
              }),
            }),
          }),
        ),
        custom_options: arrayOf(
          shape({
            type: string,
            quantity: number,
            display_value: shape({
              quantity: shape({
                text: string,
                value: number,
                values: shape({
                  equivalence: shape({
                    text: string,
                  }),
                }),
              }),
              amount: shape({
                type: string,
                value: number,
                package_value: number,
                currency_id: string,
                decimal_style: string,
                suffix: shape({
                  text: string,
                  accessibility_text: string,
                }),
              }),
            }),
          }),
        ),
      }).isRequired,
      rows: number,
    }).isRequired,
    input: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      placeholder: string,
      confirm_button: shape({ label: shape({ text: string }) }),
      error_message: shape({
        text: string,
        color: string,
      }),
    }).isRequired,
    track: shape({}),
  }),
  selected: string.isRequired,
  setQuantity: func.isRequired,
  showInput: bool.isRequired,
  onShowInput: func.isRequired,
};

export const quantitytInputButtonPropTypes = {
  className: string.isRequired,
  confirm: string.isRequired,
  disabled: bool.isRequired,
  onClick: func.isRequired,
};

export const quantityStyledLabelsPropTypes = {
  subtitles: arrayOf(
    shape({
      text: string.isRequired,
      values: shape({}).isRequired,
    }),
  ).isRequired,
};
