import { formatMoneyByLocale } from './format-money-by-locale';
import { parseBulkQuantity } from './formatBulkQuantity';

const PRICE_PER_QUANTITY = 'price_per_quantity';
const PRICE_PER_QUANTITY_BULK_SALE = 'price_per_quantity_bulk_sale';
const MAX_QUANTITY = 100000;
const MAX_QUANTITY_BULK = 10000;
const ABOVE_MAX_QUANTITY_LABEL = '+100.000';
const ABOVE_MAX_QUANTITY_LABEL_BULK = '+10.000';
const EQUIVALENCE_COLOR = 'GRAY';
const EQUIVALENCE_SIZE = 'SMALL';

export default {
  has(customOptions) {
    return this.filterItemsPricePerQuantity(customOptions).length > 0;
  },
  hasBulk(yieldValue, customOptions) {
    return Boolean(yieldValue) && this.has(customOptions);
  },
  filterItemsPricePerQuantity(customOptions) {
    return customOptions?.filter(item => [PRICE_PER_QUANTITY, PRICE_PER_QUANTITY_BULK_SALE].includes(item.type)) ?? [];
  },
  getItemsFormatted(customOptions, locale) {
    const pricePerQuantityItems = this.filterItemsPricePerQuantity(customOptions);
    return pricePerQuantityItems.map(({ quantity, display_value }) => {
      const { fraction, cents } = formatMoneyByLocale(display_value?.amount?.value, locale);

      return {
        quantity: {
          value: Number(quantity),
          text: display_value.quantity.text,
          values: {
            equivalence: {
              text: display_value?.quantity?.values?.equivalence?.text,
              color: display_value?.quantity?.values?.equivalence?.color,
              font_size: display_value?.quantity?.values?.equivalence?.font_size,
            },
          },
        },
        amount: {
          value: display_value?.amount?.value,
          fraction,
          cents,
          decimal_style: display_value?.amount?.decimal_style?.toLowerCase(),
          currency_id: display_value?.amount?.currency_id,
          suffix: display_value?.amount?.suffix,
          package_value: display_value?.amount?.package_value,
        },
      };
    });
  },
  verifyQuantity(quantity, { yield: yieldValue, custom_options } = {}) {
    if (yieldValue) {
      return this.verifyBulkQuantity(custom_options, quantity, yieldValue);
    }

    return this.verifyItemQuantity(custom_options, quantity);
  },
  verifyItemQuantity(customOptions, quantity, compare = quantity) {
    return this.filterItemsPricePerQuantity(customOptions).some(item => item.quantity === compare)
      ? ''
      : String(quantity);
  },
  verifyBulkQuantity(customOptions, quantity, yieldValue) {
    const parsedQuantity = Number(quantity?.replace(',', '.'));
    const bulkQuantity = parseBulkQuantity(parsedQuantity, yieldValue);

    return this.verifyItemQuantity(customOptions, quantity, bulkQuantity);
  },
  getQuantityText(value, selectorTemplate) {
    const { yield: yieldValue, sales_unit, site_code } = selectorTemplate;

    if (yieldValue) {
      return this.getBulkQuantityText(value, yieldValue, sales_unit, site_code);
    }

    return this.getItemQuantityText(value, selectorTemplate);
  },
  getItemQuantityText(value, { singular, plural } = {}) {
    if (value > MAX_QUANTITY) {
      return { text: plural?.replace('{quantity}', ABOVE_MAX_QUANTITY_LABEL) || '' };
    }

    const quantityText = value > 1 ? plural : singular;

    return { text: quantityText?.replace('{quantity}', value) || '' };
  },
  getBulkQuantityText(value, yieldValue, sales_unit, locale) {
    const parsedValue = Number(value.replace(',', '.'));
    const totalQuantity = parseBulkQuantity(parsedValue, yieldValue);
    const { totalValueFormatted } = formatMoneyByLocale(totalQuantity * yieldValue, locale);

    const unitMeasureText = sales_unit?.unit_measure || '';
    const typeText = totalQuantity > 1 ? sales_unit?.plural : sales_unit?.singular;

    if (parsedValue > MAX_QUANTITY_BULK) {
      return { text: `${unitMeasureText.replace('{unit}', ABOVE_MAX_QUANTITY_LABEL_BULK)}` };
    }

    const finalText = {
      text: `${unitMeasureText.replace('{unit}', totalValueFormatted)} {equivalence} `,
      values: {
        equivalence: {
          text: typeText?.replace('{quantity}', totalQuantity),
          color: EQUIVALENCE_COLOR,
          font_size: EQUIVALENCE_SIZE,
        },
      },
    };

    return finalText;
  },
  getAmountByQuantity(value, { yield: yieldValue, custom_options, site_code } = {}) {
    if (yieldValue) {
      return this.getBulkAmountByQuantity(custom_options, yieldValue, value, site_code);
    }

    return this.getItemAmountByQuantity(custom_options, value, site_code);
  },
  getItemAmountByQuantity(customOptions, quantity, locale) {
    const result = this.getItemsFormatted(customOptions, locale)
      .reverse()
      .find(item => item.quantity.value <= quantity);

    return result ? result.amount : {};
  },
  getBulkAmountByQuantity(customOptions, yieldValue, quantity, locale) {
    if (!quantity) {
      return {};
    }

    const bulkQuantity = Number(quantity.replace(',', '.'));
    const totalQuantity = parseBulkQuantity(bulkQuantity, yieldValue);

    const result = this.getItemsFormatted(customOptions, locale)
      .reverse()
      .find(item => item.quantity.value <= totalQuantity);

    if (!result) {
      return {};
    }

    const totalAmountValue = result.amount.package_value * totalQuantity;
    const { fraction, cents } = formatMoneyByLocale(totalAmountValue, locale);

    const totalAmount = {
      fraction,
      cents,
      decimal_style: result.amount.decimal_style,
      currency_id: result.amount.currency_id,
      suffix: '',
    };

    return totalAmount;
  },
  getAmountSuffix({ sales_unit } = {}) {
    return sales_unit?.unit || '';
  },
};
