import get from 'lodash/get';
import connect from '../../../../store/connect';
import { TrackPageVariation } from '../../../../components/tracking';

const FEATURE_NAME = 'track';

const mapStateToProps = ({ id, components }) => ({
  melidata_event: get(components, `${FEATURE_NAME}.melidata_event`, null),
  analytics_event: get(components, `${FEATURE_NAME}.analytics_event`, null),
  id,
});

export default connect(
  mapStateToProps,
  null,
  'tracking',
)(TrackPageVariation);
