import React from 'react';
import { string, arrayOf, shape, func } from 'prop-types';
import classnames from 'classnames';

// Basics
import ComponentList from '../component-list';

import withMappedProps from '../../utils/withMappedProps';

// Highlighted Specs Components
import StyledLabel from '../styled-label';

import labelToProps from '../styled-label/label-to-props';
import compatsToProps from '../compats-widget/block/compats-to-props';
import HSHighlightedAttributes from './components/hs-highlighted-attributes';
import HSHighlightedAttributesList from './components/hs-highlighted-attributes-list';
import CompatsWidget from '../compats-widget/block';
import HSDiscreteBar from './components/hs-discrete-bar';
import HSActionCollapsible from './components/hs-action-collapsible';
import Grid from '../grid';
import useTrackView from '../../hooks/use-track-view';
import HSAttributes from './components/hs-attributtes';

const namespace = 'ui-vpp-highlighted-specs';
const HEIGHT_BOX = 400;
const HEIGHT_MIN = 1;

const availableComponentsTop = {};
availableComponentsTop.label_component = withMappedProps(StyledLabel)(labelToProps);
availableComponentsTop.compats_widget_block = withMappedProps(CompatsWidget)(compatsToProps);
availableComponentsTop.composed_highlight = withMappedProps(CompatsWidget)(compatsToProps);
availableComponentsTop.highlighted_attributes = HSHighlightedAttributes;
availableComponentsTop.highlighted_attributes_list = HSHighlightedAttributesList;
availableComponentsTop.discrete_bar = HSDiscreteBar;

const HIGHLIGHTED_NEW_EXPERIENCE = 'highlighted_specs_attrs_new';

const HighlightedSpecsAttrs = ({
  component_id,
  components,
  viewport_track,
  className,
  getDispatch,
  feature_name,
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const initialState = { components: components?.map(c => ({ ...c, getDispatch, feature_name })) };
    const viewRef = useTrackView(viewport_track, { threshold: 0.45 });

    const leftAttributes = components.filter(
      c => (c.type === 'continuous_bar' || c.type === 'key_value') && c.column === 'LEFT',
    );

    const rightAttributes = components.filter(
      c => (c.type === 'continuous_bar' || c.type === 'key_value') && c.column === 'RIGHT',
    );

    const techSpecs = components.find(c => c.id === 'technical_specifications');
    const existAttributes = leftAttributes.length > 0 || rightAttributes.length > 0;

    return (
      <section
        ref={viewRef}
        id={component_id}
        className={classnames(namespace, className, {
          'ui-vpp-highlighted-specs-rex': feature_name === HIGHLIGHTED_NEW_EXPERIENCE,
        })}
      >
        <ComponentList availableComponents={availableComponentsTop} initialState={initialState} />
        {existAttributes ? (
          <Grid.Row modifier="attributes">
            <div className={`${namespace}__attribute-columns`}>
              <HSAttributes dataList={leftAttributes} namespace={namespace} />
              <HSAttributes dataList={rightAttributes} namespace={namespace} />
            </div>
          </Grid.Row>
        ) : null}
        {techSpecs ? (
          <Grid.Row modifier="technical-specifications">
            <HSActionCollapsible {...techSpecs} maxCollapsed={existAttributes ? HEIGHT_MIN : HEIGHT_BOX} />
          </Grid.Row>
        ) : null}
      </section>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

HighlightedSpecsAttrs.propTypes = {
  className: string,
  component_id: string,
  components: arrayOf(shape()).isRequired,
  viewport_track: shape({}),
  getDispatch: func,
  feature_name: string,
  runCatchErrorBoundary: func,
};

HighlightedSpecsAttrs.defaultProps = {
  className: 'pl-45 pr-45',
  viewport_track: null,
  runCatchErrorBoundary: () => {},
};

export default React.memo(HighlightedSpecsAttrs);
