import { startTransition } from 'react';
import { trackEvent } from '../../../lib/tracking';
import { GALLERY_TABS } from '../constants';
import { MULTIMEDIA_PROVIDERS } from '../../figure/constants';

const useGalleryHandlers = ({
  clipsRef,
  clipsConfig,
  track,
  setModalVisibility,
  setHeaderUnclickeable,
  setActiveModalTab,
  clipHandlerAsync,
}) => {
  const handleFullScreen = () => {
    setModalVisibility(true);
  };
  const handleClip = () => {
    if (clipsRef.current) {
      clipsRef.current.clipHandler();
    }
  };
  const handleTap = (type = 'picture', event = null) => {
    setActiveModalTab(type);
    if (event) {
      trackEvent(event);
    }
  };

  const handleClipsAsync = async provider => {
    await clipHandlerAsync(provider);
  };

  const handleTapFigure = (figure, e) => {
    const { provider, button } = figure;
    startTransition(() => {
      e.preventDefault();

      setModalVisibility(true);

      if (MULTIMEDIA_PROVIDERS.includes(provider)) {
        handleTap(GALLERY_TABS.MULTIMEDIA, button?.track);
      } else {
        setHeaderUnclickeable(true);
        handleTap(GALLERY_TABS.PICTURE, track);
      }
    });

    // Ejecuta el handler asíncrono fuera del bloque crítico
    if (clipsConfig?.hasClips) {
      setTimeout(() => handleClipsAsync(provider), 0);
    }
  };

  return {
    handleFullScreen,
    handleClip,
    handleTapFigure,
  };
};
export default useGalleryHandlers;
