/**
 * Component dependencies
 */
import React from 'react';
import { string } from 'prop-types';

/**
 * Component
 */

const Asset = ({
  // @ts-expect-error We will not add types to temp-illustrations
  srLabel,
}) => (
  <svg
    aria-hidden={srLabel ? undefined : !srLabel}
    aria-label={srLabel || undefined}
    role={srLabel ? 'img' : undefined}
    width="320"
    height="128"
    viewBox="0 0 320 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {srLabel && <title>{srLabel}</title>}
    <g clipPath="url(#clip0_1804_83024)">
      <rect width="320" height="128" fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.472 1.41828C163.674 2.16514 150.577 7.46295 143.412 17.2077C135.544 27.9041 128.914 34.6792 118.87 39.608C109.28 44.3169 90.354 48.58 82.7042 63.107C73.178 81.1952 85.5327 99.4599 106.162 108.915C129.214 119.483 170.246 125.677 202.08 105.538C233.205 85.8463 235.067 53.539 227.662 35.862C217.395 11.3513 191.616 0.394082 175.472 1.41828"
        fill="black"
        fillOpacity="0.04"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.116 91.6228C104.116 91.6228 104.115 91.6232 105.889 93.6772C107.663 95.7313 107.662 95.7318 107.662 95.7318L107.66 95.7342L107.654 95.7391L107.635 95.7552C107.62 95.7685 107.598 95.7872 107.57 95.811C107.514 95.8584 107.434 95.926 107.331 96.0113C107.127 96.1817 106.833 96.4232 106.465 96.716C105.732 97.3004 104.697 98.0953 103.49 98.9398C101.149 100.577 97.8827 102.599 94.8176 103.456C91.0453 104.51 87.6548 103.342 85.2264 102.505C84.9987 102.427 84.7795 102.351 84.5693 102.281C81.8795 101.379 80.3731 101.116 78.714 102.123C77.2361 103.021 76.9229 104.501 76.5919 107.763C76.5815 107.866 76.571 107.972 76.5603 108.08C76.2883 110.82 75.8658 115.078 71.8887 117.492L69.073 112.851C70.551 111.954 70.8642 110.474 71.1951 107.212C71.2056 107.109 71.216 107.003 71.2267 106.895C71.4987 104.154 71.9213 99.8969 75.8983 97.4826C79.8509 95.0833 83.5735 96.2237 86.2904 97.1347C86.4405 97.1851 86.5879 97.2347 86.7328 97.2835C89.4392 98.1946 91.2705 98.811 93.3552 98.2283C95.4832 97.6335 98.1183 96.0729 100.38 94.4907C101.475 93.7255 102.417 93.0014 103.085 92.4692C103.418 92.2037 103.681 91.9875 103.859 91.8398C103.947 91.766 104.014 91.7094 104.058 91.6725C104.079 91.654 104.095 91.6405 104.105 91.6322L104.115 91.6236L104.116 91.6228Z"
        fill="white"
      />
      <path
        d="M71.8887 117.492C75.8658 115.078 76.2883 110.82 76.5603 108.08C76.571 107.972 76.5815 107.866 76.5919 107.763C76.9229 104.501 77.2361 103.021 78.714 102.123C80.3731 101.116 81.8795 101.379 84.5693 102.281C84.7795 102.351 84.9987 102.427 85.2264 102.505C87.6548 103.342 91.0453 104.51 94.8176 103.456C97.8827 102.599 101.149 100.577 103.49 98.9398C104.697 98.0953 105.732 97.3004 106.465 96.716C106.833 96.4232 107.127 96.1817 107.331 96.0113C107.434 95.926 107.514 95.8584 107.57 95.811C107.598 95.7872 107.62 95.7685 107.635 95.7552L107.654 95.7391L107.66 95.7342L107.662 95.7318C107.662 95.7318 107.663 95.7313 105.889 93.6772C104.115 91.6232 104.116 91.6228 104.116 91.6228L104.115 91.6236L104.105 91.6322C104.095 91.6405 104.079 91.654 104.058 91.6725C104.014 91.7094 103.947 91.766 103.859 91.8398C103.681 91.9875 103.418 92.2037 103.085 92.4692C102.417 93.0014 101.475 93.7255 100.38 94.4907C98.1183 96.0729 95.4832 97.6335 93.3552 98.2283C91.2705 98.811 89.4392 98.1946 86.7328 97.2835C86.5879 97.2347 86.4405 97.1851 86.2904 97.1347C85.1168 96.7412 83.7556 96.3049 82.2693 96.1295M69.073 112.851C70.551 111.954 70.8642 110.474 71.1951 107.212C71.2056 107.109 71.216 107.003 71.2267 106.895C71.4987 104.154 71.9213 99.8969 75.8983 97.4826C76.577 97.0707 77.2489 96.763 77.9101 96.5407"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.451 36.5779C206.451 36.5779 206.452 36.5775 204.678 34.5235C202.905 32.4694 202.905 32.4689 202.905 32.4689L202.908 32.4666L202.914 32.4616L202.932 32.4456C202.948 32.4322 202.97 32.4135 202.998 32.3897C203.054 32.3423 203.134 32.2747 203.236 32.1894C203.441 32.0189 203.735 31.7774 204.102 31.4845C204.836 30.9001 205.87 30.105 207.078 29.2603C209.419 27.6226 212.685 25.6003 215.75 24.7432C219.522 23.6883 222.912 24.8562 225.341 25.6927C225.568 25.7711 225.787 25.8466 225.998 25.9171C228.687 26.8188 230.193 27.0814 231.853 26.074C233.331 25.1766 233.644 23.6961 233.975 20.4339C233.986 20.3309 233.996 20.2253 234.007 20.1173C234.279 17.3763 234.702 13.1186 238.679 10.7038L241.494 15.3446C240.016 16.2419 239.703 17.7225 239.372 20.9846C239.361 21.0877 239.351 21.1933 239.34 21.3013C239.068 24.0423 238.645 28.3 234.668 30.7148C230.715 33.1147 226.993 31.9746 224.276 31.0638C224.126 31.0135 223.979 30.9638 223.834 30.9151C221.128 30.0042 219.296 29.3879 217.212 29.9709C215.084 30.5659 212.449 32.1269 210.187 33.7094C209.093 34.4747 208.15 35.199 207.482 35.7313C207.149 35.9969 206.886 36.2131 206.708 36.3608C206.62 36.4346 206.553 36.4912 206.509 36.5281C206.488 36.5466 206.472 36.5601 206.462 36.5685L206.452 36.577L206.451 36.5779Z"
        fill="white"
      />
      <path
        d="M238.679 10.7038C234.702 13.1186 234.279 17.3763 234.007 20.1173C233.996 20.2253 233.986 20.3309 233.975 20.4339C233.644 23.6961 233.331 25.1766 231.853 26.074C230.193 27.0814 228.687 26.8188 225.998 25.9171C225.787 25.8466 225.568 25.7711 225.341 25.6927C222.912 24.8562 219.522 23.6883 215.75 24.7432C212.685 25.6003 209.419 27.6226 207.078 29.2603C205.87 30.105 204.836 30.9001 204.102 31.4845C203.735 31.7774 203.441 32.0189 203.236 32.1894C203.134 32.2747 203.054 32.3423 202.998 32.3897C202.97 32.4135 202.948 32.4322 202.932 32.4456L202.914 32.4616L202.908 32.4666L202.905 32.4689C202.905 32.4689 202.905 32.4694 204.678 34.5235C206.452 36.5775 206.451 36.5779 206.451 36.5779L206.452 36.577L206.462 36.5685C206.472 36.5601 206.488 36.5466 206.509 36.5281C206.553 36.4912 206.62 36.4346 206.708 36.3608C206.886 36.2131 207.149 35.9969 207.482 35.7313C208.15 35.199 209.093 34.4747 210.187 33.7094C212.449 32.1269 215.084 30.5659 217.212 29.9709C219.296 29.3879 221.128 30.0042 223.834 30.9151C223.979 30.9638 224.126 31.0135 224.276 31.0638C225.169 31.363 226.17 31.687 227.252 31.902M241.494 15.3446C240.016 16.2419 239.703 17.7225 239.372 20.9846C239.361 21.0877 239.351 21.1933 239.34 21.3013C239.168 23.0276 238.937 25.3556 237.796 27.4459M231.789 31.9029C232.727 31.6854 233.69 31.3084 234.668 30.7148C234.756 30.6614 234.842 30.6071 234.926 30.552"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M138.707 58.0283C137.725 56.4092 138.241 54.2998 139.86 53.3168L152.39 45.7052C154.009 44.7222 156.116 45.2379 157.098 46.8569V46.8569C158.08 48.476 157.564 50.5854 155.945 51.5684L143.415 59.18C141.796 60.163 139.689 59.6473 138.707 58.0283V58.0283Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="5.76268"
        y2="-0.75"
        transform="matrix(-0.518474 -0.855094 0.854677 -0.51916 154.568 41.3848)"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="5.75996"
        y2="-0.75"
        transform="matrix(0.854677 -0.51916 0.518474 0.855094 159.931 44.7646)"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="5.76364"
        y2="-0.75"
        transform="matrix(0.323933 -0.94608 0.9459 0.324458 157.821 42.2002)"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M152.968 81.5487C153.95 83.1673 156.057 83.6828 157.674 82.7L170.208 75.086C171.826 74.1032 172.342 71.9944 171.36 70.3758V70.3758C170.379 68.7571 168.272 68.2417 166.654 69.2244L154.121 76.8385C152.503 77.8212 151.987 79.9301 152.968 81.5487V81.5487Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M168.275 30.2197L191.005 16.4127C195.017 13.9755 200.243 15.2539 202.676 19.2681L216.811 42.5791C219.245 46.5933 217.965 51.8231 213.953 54.2603L191.223 68.0673L168.275 30.2197Z"
        fill="#EEEEEE"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M166.215 29.7158L190.227 15.13C194.947 12.2628 201.095 13.7668 203.958 18.4894L218.093 41.8004C220.956 46.5229 219.451 52.6757 214.731 55.5429L190.719 70.1287L166.215 29.7158Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M99.9965 90.5993C99.4239 89.6548 99.7249 88.4243 100.669 87.8508L117.138 77.8467L125.405 91.4799L108.935 101.484C107.991 102.057 106.762 101.757 106.189 100.812L99.9965 90.5993Z"
        fill="#EEEEEE"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M127.261 58.526L147.201 91.4126L136.292 98.0392C135.414 98.5724 134.347 98.6929 133.373 98.3689L120.212 93.9913L112.516 81.2986L114.712 67.5924C114.875 66.5778 115.474 65.6858 116.352 65.1526L127.261 58.526Z"
        fill="#EEEEEE"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M110.948 81.607L113.231 67.3563C113.464 65.907 114.32 64.6327 115.574 63.871L127.765 56.4656L149.261 91.9175L137.07 99.3228C135.816 100.085 134.291 100.257 132.899 99.7938L119.215 95.2423L110.948 81.607Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <rect
        width="19.446"
        height="57.4056"
        rx="5"
        transform="matrix(0.854677 -0.51916 0.518474 0.855094 119.272 52.2949)"
        fill="white"
      />
      <rect
        width="19.446"
        height="57.4056"
        rx="5"
        transform="matrix(0.854677 -0.51916 0.518474 0.855094 119.272 52.2949)"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M194.104 30.8257C193.272 29.4531 193.709 27.6647 195.081 26.8313V26.8313C196.453 25.998 198.24 26.4351 199.073 27.8078L206.724 40.4265C207.556 41.7992 207.119 43.5875 205.747 44.4209V44.4209C204.375 45.2543 202.588 44.8171 201.755 43.4445L194.104 30.8257Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <rect
        width="19.446"
        height="57.4056"
        rx="5"
        transform="matrix(0.854677 -0.51916 0.518473 0.855094 161.847 26.4341)"
        fill="white"
      />
      <rect
        width="19.446"
        height="57.4056"
        rx="5"
        transform="matrix(0.854677 -0.51916 0.518473 0.855094 161.847 26.4341)"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <line
        y1="-0.75"
        x2="15.9435"
        y2="-0.75"
        transform="matrix(0.518473 0.855094 -0.854677 0.51916 106.343 84.481)"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <line
        y1="-0.75"
        x2="15.9435"
        y2="-0.75"
        transform="matrix(0.518473 0.855094 -0.854677 0.51916 102.21 87.0112)"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="5.76402"
        y2="-0.75"
        transform="matrix(0.202752 0.97923 0.979157 -0.203106 173.159 77.3569)"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="5.75862"
        y2="-0.75"
        transform="matrix(0.979157 -0.203106 -0.202753 -0.97923 174.781 71.2256)"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="5.75968"
        y2="-0.75"
        transform="matrix(0.881769 0.471681 0.471017 -0.882124 174.984 74.541)"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1804_83024">
        <rect width="320" height="128" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Asset.propTypes = {
  srLabel: string.isRequired,
};

/**
 * Export component
 */
export default Asset;
