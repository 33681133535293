import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../../components/grid/row/with-row';
import Promotions from '../../../../../components/promotions';

const FEATURE_NAME = 'meliplus_label_summary';

const MELIPLUS_LABEL_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: meliplusLabel } }, ownProps) => ({
  ...meliplusLabel,
  ...ownProps,
  ...MELIPLUS_LABEL_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...MELIPLUS_LABEL_PROPS,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const noFeatureFlagConnect = connect(
  serializeOwnProps,
  null,
  `no-ff-${FEATURE_NAME}`,
);

export default enhance(Promotions);
export const noFeatureFlag = noFeatureFlagConnect(Promotions);
