import React, { useEffect, useContext, useState, useRef } from 'react';
import classnames from 'classnames';
import { Button } from '@andes/button';
import { TextField } from '@andes/textfield';
import { Checkbox } from '@andes/checkbox';
import { Form } from '@andes/form';
import { AndesProvider } from '@andes/context';
import { trackEvent, trackEventWithCustomFields } from '../../lib/tracking';
import StaticPropsContext from '../context/static-props';
import useTrackView from '../../hooks/use-track-view';
import BaseModal from '../modals/base-modal';
import QuestionsMessage from './questions-message';
import Media from '../media';
import QuestionsMessageLocation from './questions-message-location';
import PreventionFraudMessage from './questions-message-fraud';
import { constants } from '../../utils/constants';
import { namespace, questionsPropTypes } from './model';
import { buildMotorsQuestionButton } from './helpers/build-motors-question-button';
import QuestionsQuickAccess from './components/questions-quick-access/questions-quick-access';

const DEFAULT_DELAY_TIME = 3000;
const { devices } = constants;
const { MOBILE } = devices;

// eslint-disable-next-line complexity
const Questions = ({
  className = null,
  children = null,
  disabled = false,
  form = null,
  id,
  messages = null,
  title = null,
  show_track = null,
  onCreateQuestion,
  url,
  stockModal: stockModalProps = null,
  track,
  question_message = null,
  quick_access = null,
  condition = null,
  message_location = null,
  message_prevention_fraud = null,
  focus = false,
  onRequestFocus = null,
  enableRedirectAction = false,
  onScrollComponent = null,
  closeModalLabel = null,
  showSnackbar = null,
}) => {
  const { deviceType, flashMessage } = useContext(StaticPropsContext);
  const [isFocus, setIsFocus] = useState(focus);
  const creditCheckbox = typeof form?.leads_credit_checkbox?.text === 'string' ? false : null;
  const [creditsInterestChecked, setCreditsInterestChecked] = useState(creditCheckbox);
  const [shouldShowSnackbar, setShouldShowSnackbar] = useState(messages && messages.text);
  const showTrackType = show_track?.melidata_event?.type;
  // -------------------------------------
  // ----- BEGIN GUEST QUESTON LOGIC -----
  // -------------------------------------
  const [activeFlashMessage, setActiveFlashMessage] = useState(flashMessage);
  let stockModal = flashMessage && flashMessage.stock_modal;

  if (stockModalProps) {
    stockModal = stockModalProps;
  }

  if (activeFlashMessage) {
    if (flashMessage.stock_track) {
      trackEvent(flashMessage.stock_track);
    }

    if (!stockModal) {
      const extraFields = flashMessage.extra_post_event_data || {};
      extraFields.failed = !flashMessage.success;
      trackEventWithCustomFields(track, extraFields);
    }

    setActiveFlashMessage(null);
  }
  // ---------------------------------
  // ----- END GUEST QUESTON LOGIC -----
  // -----------------------------------

  const [modalVisible, setModalVisible] = useState(true);
  const [value, setValue] = useState((form && form.default_question_text) || '');
  const inputRef = useRef(null);

  const onInputChange = e => {
    setValue(e.target.value);
  };

  const onInputFocus = () => {
    trackEvent(form.focus_track);
  };

  const creditsInterestToggle = e => {
    setCreditsInterestChecked(e.target.checked);
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (value.trim() !== '') {
      onCreateQuestion({
        itemId: form.confirm.item_id,
        text: value,
        track,
        checkbox: creditsInterestChecked,
      });
      setValue('');
    } else {
      inputRef.current.focus();
    }
  };

  if (shouldShowSnackbar) {
    showSnackbar({
      message: messages.text,
      type: messages.type,
      delay: DEFAULT_DELAY_TIME,
    });
    setShouldShowSnackbar(false);
  }

  useEffect(() => {
    setShouldShowSnackbar(messages && messages.text);
  }, [messages]);

  useEffect(
    () => () => {
      if (stockModal) {
        setModalVisible(true);
      }
    },
    [stockModal],
  );

  // Thresholds are defined to know when te component is usable
  const refQuestions = useTrackView(show_track, { threshold: 0.2 }, showTrackType && showTrackType.toLowerCase());

  useEffect(() => {
    if (focus) {
      refQuestions.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        setIsFocus(true);
      }, 500);
    }
  }, [focus, refQuestions]);

  return (
    <div ref={refQuestions} className={classnames(namespace, className)} id={id}>
      {stockModal && (
        <BaseModal
          data-testid="stock-modal"
          key={`${namespace}__stock-modal-${Math.random()}`}
          id={`${namespace}__stock-modal`}
          isOpen={modalVisible}
          onClose={() => setModalVisible(false)}
          closable
          closeModalLabel={closeModalLabel}
          autoHeight={false}
          title={stockModal.title.text}
          type={deviceType === 'mobile' ? 'card' : 'large'}
          maxWidth={500}
        >
          <p data-testid="stock-modal-text" className={`${namespace}__stock-modal__text`}>
            {stockModal.content.text}
          </p>
          <Button
            data-testid="stock-modal-confirm"
            className={`${namespace}__stock-modal__button`}
            onClick={e => {
              e.preventDefault();
              setModalVisible(false);
            }}
            hierarchy="loud"
          >
            {stockModal.confirm.label.text}
          </Button>
        </BaseModal>
      )}
      {title && <h2 className={`${namespace}__title`}>{title.text}</h2>}
      {quick_access && (
        <QuestionsQuickAccess
          deviceType={deviceType}
          quick_access={quick_access}
          onScrollComponent={onScrollComponent}
        />
      )}
      {question_message && <QuestionsMessage {...question_message} />}
      {message_location && <QuestionsMessageLocation {...message_location} />}
      {message_prevention_fraud && <PreventionFraudMessage {...message_prevention_fraud} />}
      {form && (
        <>
          {form.title && (
            <h3 aria-hidden="true" className={`${namespace}__subtitle--question`}>
              {form.title.text}
            </h3>
          )}
          <AndesProvider>
            <Form className={`${namespace}__form`} action={url} method="POST" onSubmit={handleOnSubmit}>
              {form.confirm && <input type="hidden" name="itemId" value={form.confirm.item_id} />}
              <div
                className={classnames(`${namespace}__content`, {
                  [`${namespace}__checkbox-credits-interest-content`]: form.leads_credit_checkbox,
                })}
              >
                {form.title && (
                  <label className="ui-pdp--hide" htmlFor="question">
                    {form.title.text}
                  </label>
                )}
                <TextField
                  id="question"
                  name="question"
                  data-testid="questions-input"
                  className={`${namespace}__input`}
                  placeholder={form.placeholder.text}
                  setField={innerRef => {
                    inputRef.current = innerRef;
                  }}
                  autoComplete="off"
                  value={value}
                  message={form.subtitles && form.subtitles.map(s => s.text).join('')}
                  onChange={onInputChange}
                  onFocus={onInputFocus}
                  messageShow
                  messageFixed
                  maxLength={2000}
                  multiline
                  focus={isFocus}
                  onBlur={() => {
                    if (onRequestFocus) {
                      setIsFocus(false);
                      onRequestFocus({ focus: false });
                    }
                  }}
                />
                {form.leads_credit_checkbox && (
                  <div className={`${namespace}__checkbox-credits-interest__wrapper`}>
                    <Checkbox
                      data-testid="checkbox-leads"
                      label={form.leads_credit_checkbox.text}
                      className={`${namespace}__checkbox-credits-interest`}
                      onChange={creditsInterestToggle}
                      checked={creditsInterestChecked}
                    />
                  </div>
                )}
                {form.confirm && (
                  <Button
                    data-testid="questions-button"
                    className={`${namespace}__button`}
                    hierarchy="loud"
                    type="submit"
                    disabled={disabled}
                    fullWidth={deviceType === MOBILE}
                  >
                    {form.confirm.label.text}
                  </Button>
                )}
              </div>
              {enableRedirectAction && form.additional_actions && buildMotorsQuestionButton(form.additional_actions)}
              {condition && <Media className={`${namespace}__conditions`} title={condition} />}
            </Form>
          </AndesProvider>
        </>
      )}
      {children}
    </div>
  );
};

Questions.propTypes = questionsPropTypes;

export default Questions;
