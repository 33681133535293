import React, { Children, cloneElement, isValidElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';
import DotsNavigation from '../dots-navigation';
import StyledOverlay from '../styled-overlay';

const namespace = 'ui-pdp-carousel-snapped';

const CarouselSnappedComp = props => {
  const { accessibility_text, children, counter, onSlide, setCounter, shapes = [], overlayAlpha, app } = props;

  // Render slides
  const renderContent = component => {
    if (!isValidElement(component)) {
      return null;
    }
    return (
      <CarouselSnappedSlide key={component.key} className={`${namespace}__slide`}>
        {cloneElement(component, null)}
      </CarouselSnappedSlide>
    );
  };
  /* istanbul ignore next */
  const slideChange = newIndex => {
    if (counter !== newIndex) {
      setCounter(newIndex);
      onSlide?.(newIndex);
    }
  };
  return (
    <div className={classnames(namespace)}>
      {useMemo(
        () => (
          <>
            <CarouselSnapped
              afterChange={slideChange}
              srLabel={accessibility_text}
              arrows={false}
              arrowsVisible={false}
              initialSlide={counter}
              pagination={false}
              type="full"
            >
              {Children.map(children, renderContent)}
            </CarouselSnapped>
            {overlayAlpha && (
              <StyledOverlay className={`${namespace}__overlay`} color="black" overlayAlpha={overlayAlpha} app={app} />
            )}
          </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [children],
      )}
      <div className={classnames(`${namespace}__counter`)}>
        <span className="pagination-current">{counter + 1} </span>/
        <span className="pagination-total">
          {' '}
          {Children.count((Array.isArray(children) ? children : [children]).filter(Boolean))}
        </span>
      </div>
      {shapes?.length > 1 && <DotsNavigation index={counter} shapes={shapes} />}
    </div>
  );
};

CarouselSnappedComp.propTypes = {
  accessibility_text: PropTypes.string,
  children: PropTypes.node,
  counter: PropTypes.number,
  onSlide: PropTypes.func,
  setCounter: PropTypes.func,
  shapes: PropTypes.arrayOf(PropTypes.string),
  overlayAlpha: PropTypes.string,
  app: PropTypes.string,
};

export default CarouselSnappedComp;
