import {
  VPP_BOUNDARY_DATADOG_KEY_PREFIX,
  VPP_BOUNDARY_DATADOG_KEY_CASES,
} from '../services/frontend-statsd/config/allowed-keys';

const componentPrint = ({ catchErrorBoundaryConfig = {} } = {}) => {
  const { deviceType, componentId, catchContext } = catchErrorBoundaryConfig;

  const isClientSide = catchContext === 'client_side';

  if (isClientSide) {
    return;
  }

  const metricDataConfig = {
    key: `${VPP_BOUNDARY_DATADOG_KEY_PREFIX}.${VPP_BOUNDARY_DATADOG_KEY_CASES.ERROR_BOUNDARY_COMPONENT_PRINT}`,
    tags: {
      device_type: deviceType,
      component_id: componentId,
    },
    info: null,
    messageTitle: 'Vpp-ErrorBoundary - ComponentPrint',
  };

  // Send metrics and logs:
  // SSR Case: This use statsd lib on the server/node using global
  // CSR Case: This use the api on client using window
  if (global?.catchErrorBoundary && typeof global?.catchErrorBoundary === 'function') {
    global.catchErrorBoundary(metricDataConfig);
  }
};

export default componentPrint;
