import React, { useState, useRef, useEffect } from 'react';
import { bool, shape, func, string } from 'prop-types';
import { ProgressButton, ButtonText } from '@andes/button';
import Textfield from '@andes/textfield';
import { Form } from '@andes/form';
import { AndesProvider } from '@andes/context';
import { trackEvent } from '../../../../lib/tracking';
import {
  QUESTIONS_AI_DATADOG_KEY_PREFIX,
  QUESTIONS_AI_DATADOG_KEY_CASES,
} from '../../../../services/frontend-statsd/config/allowed-keys';
import IconFactory from '../../../icons/factory-builder';

const namespace = `ui-vpp-questions-ai-form`;

const QuestionsAiFormDesktop = ({
  questionsAiForm: { item_id: itemId, form },
  productId,
  attributes,
  postQuestionFromAi,
  saveFrontendStatsd,
  isFetching,
}) => {
  const [value, setValue] = useState('');
  const [clearInput, setClearInput] = useState(false);
  const inputRef = useRef(null);
  const { textarea, action_button, is_ask_seller } = form;

  useEffect(() => {
    if (saveFrontendStatsd) {
      const dataStatsd = {
        key: `${QUESTIONS_AI_DATADOG_KEY_PREFIX}.${QUESTIONS_AI_DATADOG_KEY_CASES.QUESTION_LOAD}`,
        tags: {
          source: 'vpp',
        },
      };

      saveFrontendStatsd(dataStatsd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (is_ask_seller) {
      setValue('');
      setClearInput(false);
    }
  }, [is_ask_seller]);

  const onInputChange = e => {
    setValue(e.target.value);
  };

  const handleOnSubmit = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (value.trim() !== '') {
      postQuestionFromAi({ productId, itemId, text: value, track: action_button.track, attributes, source: 'vpp' });
      setClearInput(true);
    } else {
      inputRef.current.focus();
      setClearInput(false);
    }
  };

  const handleOnFocus = () => {
    if (textarea.track) {
      trackEvent(textarea.track);
    }
  };

  return (
    <div className={namespace}>
      <AndesProvider>
        <Form className={`${namespace}__andes-form`} method="POST" onSubmit={handleOnSubmit}>
          <input type="hidden" name="itemId" value={itemId} />
          <div className={`${namespace}__content`}>
            <Textfield
              id="questions-ai-form-input"
              name="question"
              data-testid="question-form-input"
              className={`${namespace}__input`}
              placeholder={textarea.placeholder}
              setField={innerRef => {
                inputRef.current = innerRef;
              }}
              autoComplete="off"
              value={value}
              onChange={onInputChange}
              onFocus={handleOnFocus}
            />
            {clearInput && (
              <span
                data-testid="questions-ai-form-close"
                role="presentation"
                onClick={e => {
                  e.preventDefault();
                  setValue('');
                  setClearInput(false);
                }}
                className={`${namespace}__clear`}
              >
                {IconFactory({ id: 'close' })}
              </span>
            )}
          </div>
          <div className={`${namespace}__actions`}>
            <ProgressButton
              progressLabel={
                <div className={`${namespace}__actions-span-progress`}>
                  {action_button.icon &&
                    IconFactory(action_button.icon.id, `ui-pdp-action-icon--${action_button.icon.color}`)}
                  <ButtonText>{action_button.label.text_asking}</ButtonText>
                </div>
              }
              data-testid="question-form-button"
              className={`${namespace}__actions-button`}
              hierarchy="loud"
              type="submit"
              loading={isFetching}
              fullWidth
            >
              <div className={`${namespace}__actions-span-progress`}>
                {action_button.icon &&
                  IconFactory(action_button.icon.id, `ui-pdp-action-icon--${action_button.icon.color}`)}
                <ButtonText>{action_button.label.text}</ButtonText>
              </div>
            </ProgressButton>
          </div>
        </Form>
      </AndesProvider>
    </div>
  );
};

QuestionsAiFormDesktop.propTypes = {
  questionsAiForm: shape({
    item_id: string.isRequired,
    form: shape().isRequired,
  }).isRequired,
  productId: string.isRequired,
  attributes: string,
  postQuestionFromAi: func.isRequired,
  saveFrontendStatsd: func.isRequired,
  isFetching: bool,
};

QuestionsAiFormDesktop.defaultProps = {
  attributes: null,
  isFetching: false,
};

export default QuestionsAiFormDesktop;
