import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import * as actions from '../../../../actions';

const TradeIn = loadable(() => import('../../../../components/trade-in'));

const FEATURE_NAME = 'trade_in';

const mapStateToProps = ({ components: { [FEATURE_NAME]: tradeIn }, id: itemId }, ownProps) => ({
  ...tradeIn,
  ...ownProps,
  itemId,
});

const mapDispatchToProps = dispatch => ({
  onUpdateTradeIn: trade_in_id => dispatch(actions.updateTradeIn(trade_in_id)),
  onRemoveTradeIn: () => dispatch(actions.removeTradeIn()),
  showSnackbar: trade_snackbar => dispatch(actions.showSnackbar(trade_snackbar)),
  redirectToLoginAction: (itemId, loginType = 'TradeIn') =>
    dispatch(actions.redirectToLogin({ itemId, featureName: FEATURE_NAME, loginType })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(TradeIn);
