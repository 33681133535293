import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import ShippingSummary from '../../../../../components/buybox/shipping';

const FEATURE_NAME = 'special_event_shipping_summary';

const SECOND_SHIPPING_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: shippingSummary } }, ownProps) => ({
  ...shippingSummary,
  ...ownProps,
  ...SECOND_SHIPPING_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...SECOND_SHIPPING_PROPS,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const noFeatureFlagConnect = connect(
  serializeOwnProps,
  null,
  `no-ff-${FEATURE_NAME}`,
);

export default enhance(ShippingSummary);
export const noFeatureFlag = noFeatureFlagConnect(ShippingSummary);
