import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import { checkOnBulkSaleSetQuantity, hideHiQuantityError, redirectToLogin } from '../../../../actions/vip';
import { trackEvent } from '../../../../lib/tracking';
import calculatorWrapper from '../../../../components/calculator/calculator-wrapper';

const FEATURE_NAME = 'bulk_sale_quantity';
const mapStateToProps = ({ components: { [FEATURE_NAME]: bulkSaleQuantity }, deviceType, redirect_context }) => ({
  ...bulkSaleQuantity,
  device: deviceType?.toLowerCase(),
  redirect_context,
});

const mapDispatchToProps = dispatch => ({
  onSetQuantity: data => dispatch(checkOnBulkSaleSetQuantity(data)),
  onOpenModal: () => dispatch(hideHiQuantityError()),
  tracker: data => trackEvent(data),
  redirectToLogin: itemId => dispatch(redirectToLogin({ itemId, loginType: 'CALCULATOR', featureName: FEATURE_NAME })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const calculator = enhance(calculatorWrapper);

export default calculator;
