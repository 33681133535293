import { useEffect, useState, useCallback } from 'react';
import { shape, string, arrayOf, number, func } from 'prop-types';
import { CoachMarks } from '@andes/coach-marks';
import { trackEvent } from '@vpp-frontend-components/common';

const PAGE_UPP = 'upp';

const Coachmarks = ({
  userNickname = '',
  locale,
  steps,
  component_status,
  track,
  page = '',
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const [runCoachMarks, setRunCoachMarks] = useState(false);
    const coachmarkStorageKey = `coachmarks_user_${userNickname}`;

    useEffect(() => {
      try {
        const hasCoachmarks = localStorage.getItem(coachmarkStorageKey);
        if (hasCoachmarks !== 'true') {
          trackEvent(track);
          setRunCoachMarks(true);
        }
      } catch (error) {
        setRunCoachMarks(false);
      }
    }, [coachmarkStorageKey, track]);

    const handleHiddenCoachmarks = useCallback(({ status, lifecycle }) => {
      if (['skipped', 'finished'].includes(status) && lifecycle === 'complete') {
        try {
          localStorage.setItem(coachmarkStorageKey, 'true');
        } catch {
          return;
        }

        const statusTrack = component_status.find(({ id }) => id === status);
        if (statusTrack) {
          trackEvent(statusTrack.track);
        }

        setRunCoachMarks(false);
      }
    }, [component_status, coachmarkStorageKey]);

    return (
      <CoachMarks
        run={runCoachMarks}
        onChange={handleHiddenCoachmarks}
        locale={locale}
        steps={steps}
        disableScrolling={[PAGE_UPP].includes(page)}
      />
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Coachmarks.propTypes = {
  userNickname: string,
  locale: shape({
    next: string,
    back: string,
    last: string,
  }),
  steps: arrayOf(
    shape({
      content: string,
      element: string,
      position: string,
      title: string,
      button_text: string,
    }),
  ),
  component_status: arrayOf(
    shape({
      id: string,
      track: shape({
        melidata_event: shape({
          type: string,
          path: string,
          event_data: shape({
            status: string,
          }),
        }),
      }),
    }),
  ),
  track: shape({
    melidata_event: shape({
      type: string,
      path: string,
      event_data: shape({
        view_count: number,
      }),
    }),
  }),
  page: string,
  runCatchErrorBoundary: func,
};

Coachmarks.defaultProps = {
  coachmarks: null,
  runCatchErrorBoundary: () => {},
};

export default Coachmarks;
