import React from 'react';
import QuestionsQuickAccessMobile from '../../questions-quick-access.mobile';
import QuestionsQuickAccessDesktop from '../../questions-quick-access.desktop';
import { constants } from '../../../../utils/constants';
import { onIframeModalResize } from '@vpp-frontend-components/common';
import { questionsQuickAccessPropTypes } from './model';

const { devices } = constants;
const { MOBILE } = devices;
const defaultOnScrollComponent = () => {};

const QuestionsQuickAccess = ({ deviceType, quick_access, onScrollComponent = defaultOnScrollComponent }) => {
  const commonProps = {
    ...quick_access,
    onScrollComponent,
    onIframeMessage: onIframeModalResize(
      'vip:modal-content:loaded',
      'height',
      '.ui-pdp-iframe-modal',
      '.andes-modal__header',
      deviceType !== MOBILE && 'width',
    ),
  };

  return deviceType === MOBILE ? (
    <QuestionsQuickAccessMobile {...commonProps} />
  ) : (
    <QuestionsQuickAccessDesktop {...commonProps} />
  );
};

QuestionsQuickAccess.propTypes = questionsQuickAccessPropTypes;

export default QuestionsQuickAccess;
