import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import { Card, CardContent } from '@andes/card';
import ExtendedMenu from '@seo-frontend-components/extended-menu';

const namespace = 'ui-pdp-extended-menu';

const ExtendedMenuDesktop = ({ menu, title, seeLess, seeMore, runCatchErrorBoundary }) => {
  try {
    return (
      menu?.length > 0 && (
        <div className={namespace}>
          <Card paddingSize={24}>
            <CardContent>
              <ExtendedMenu title={title} menu={menu} seeLess={seeLess} seeMore={seeMore} />
            </CardContent>
          </Card>
        </div>
      )
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

ExtendedMenuDesktop.namespace = namespace;

ExtendedMenuDesktop.propTypes = {
  title: string.isRequired,
  menu: arrayOf(
    shape({
      title: string.isRequired,
      url: string,
      children: arrayOf(
        shape({
          text: string.isRequired,
          url: string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  seeLess: string,
  seeMore: string,
  runCatchErrorBoundary: func,
};

ExtendedMenuDesktop.defaultProps = {
  classNames: '',
  menu: [],
  seeLess: '',
  seeMore: '',
  runCatchErrorBoundary: () => {},
};

export default ExtendedMenuDesktop;
