import React, { useEffect, useRef, useLayoutEffect } from 'react';
/**
 * Custom hook to manage expandable content.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {number} params.maxShowPixels - Maximum height in pixels to show when content is collapsed.
 * @returns {Object} - Returns an object containing:
 * @returns {boolean} isExpanded - State indicating whether the content is expanded.
 * @returns {Function} handleExpanse - Function to toggle the expanded state.
 * @returns {React.RefObject} expandableRef - Ref to the expandable content element.
 */
const useExpandable = ({ maxShowPixels = 300, action }) => {
  const expandableRef = useRef(null);

  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleExpanse = () => {
    setIsExpanded(!isExpanded);
    if (action) {
      action();
    }
  };

  useLayoutEffect(() => {
    const { current } = expandableRef;

    if (current?.clientHeight <= maxShowPixels) {
      setIsExpanded(true);
    }
    current.style.maxHeight = `${maxShowPixels}px`;
    current.style.overflow = 'hidden';
  }, [maxShowPixels]);

  useEffect(() => {
    if (isExpanded) {
      expandableRef.current.style.maxHeight = '100%';
    }
  }, [isExpanded]);

  return {
    isExpanded,
    handleExpanse,
    expandableRef,
  };
};

export default useExpandable;
