import React from 'react';
import { Button } from '@andes/button';
import classnames from 'classnames';
import { namespace, quantityInputButtonPropTypes } from './model';

const QuantityInputButton = ({
  confirm,
  onSubmit,
  error = null,
  value = '',
  bulkSale = false,
  hasCustomOptions = false,
  ...props
}) => (
  <Button
    data-testid="quantity-input-button"
    className={classnames(`${namespace}__button`, {
      [`${namespace}__button-bulk`]: bulkSale && !hasCustomOptions,
      [`${namespace}__button-custom-options`]: !bulkSale && hasCustomOptions,
      [`${namespace}__button-bulk-custom-options`]: bulkSale && hasCustomOptions,
    })}
    onClick={onSubmit}
    disabled={!!error || value === ''}
    {...props}
  >
    {confirm}
  </Button>
);

QuantityInputButton.propTypes = quantityInputButtonPropTypes;

export default QuantityInputButton;
