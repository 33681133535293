/* eslint-env browser */
import React, { useContext } from 'react';
import classnames from 'classnames';
import { loadable } from 'nordic/lazy';
import colornames from '../../../lib/colornames';
import ActionModal from '../../action-modal';
import Shipping from '../../shipping';
import StaticPropsContext from '../../context/static-props';
import Iframe from '../../iframe';
import transformActionTarget from '../../../utils/transformActionTarget';
import IconFactory from '../../icons/factory-builder';
import onIframeModalResize from '../../../lib/on-iframe-modal-resize';
import Message from '../../message';
import { stringIncludes } from '../../../lib/includes';
import { PriceFontSize } from '../../../lib/num-font-size-enum';
import TooltipWrapper from '../../tooltip/tooltip-wrapper';
import useTooltipProps from '../../tooltip/hooks/useTooltipProps';
import { getEventClickShippingCalculateTrack, getCoachCardTooltipsProps } from '../../../utils/coachTooltip';
import { isMshops } from '../../../utils/mercadoShopsUtils';
import { namespace, shippingSummaryPropTypes } from './model';
import { isSummaryTitleValid } from '../../../utils/summary';

const CardTooltip = loadable(({ show }) => (show ? import('../../card-tooltip') : null));

const getAction = (
  action,
  deviceType,
  onCloseModal,
  meliplusUpsellingOnSuscription,
  meliplusOnDeliveryDayChange,
  noTitle,
) => {
  /* Enviar modal true desde backend y luego borrar esto */
  const hasAction = action && action.target;
  const symbol = hasAction && stringIncludes(action.target, '?') ? '&' : '?';
  const actionTarget =
    action && action.target && stringIncludes(action.target, 'modal=false')
      ? action.target.replace('modal=false', 'modal=true')
      : `${action.target}${symbol}modal=true`;

  const lowEndUrl = { ...action, target: actionTarget };
  transformActionTarget(lowEndUrl, { modal: false });

  if (deviceType === 'desktop' || deviceType === 'tablet') {
    return (
      <ActionModal
        icon={action.icon ? IconFactory(action.icon, `${namespace}__action-icon`) : null}
        label={action.label}
        modalUrl="#shipping"
        url={actionTarget}
        modalClassName="ui-pdp-iframe-modal ui-pdp-shipping-modal"
        autoHeight={false}
        closeModalLabel={action.close_modal_label}
        track={action.track}
        closeTrack={action.close_track}
        onCloseModal={onCloseModal}
        meliplusUpsellingOnSuscription={meliplusUpsellingOnSuscription}
        meliplusOnDeliveryDayChange={meliplusOnDeliveryDayChange}
        modalTitle={noTitle && false}
      >
        <Iframe
          src={actionTarget}
          title={action.label.text}
          onMessage={onIframeModalResize(
            'nav:cp:updateSize',
            'height',
            '.ui-pdp-iframe-modal',
            '.andes-modal__header',
            'width',
            deviceType,
          )}
          scrolling="no"
        />
      </ActionModal>
    );
  }

  return (
    <ActionModal
      deviceType="mobile"
      label={action.label}
      modalTitle={noTitle && false}
      modalClassName={classnames(
        `${namespace}-modal ${action.modal_title ? null : 'modal-close-button-mobile'}`,
        'ui-pdp-iframe-modal',
      )}
      className={`${namespace}__action`}
      track={action.track}
      closeTrack={action.close_track}
      url={lowEndUrl.target}
      closeModalLabel={action.close_modal_label}
      controlModalHeader
      showCloseButton
      onCloseModal={onCloseModal}
      meliplusUpsellingOnSuscription={meliplusUpsellingOnSuscription}
      meliplusOnDeliveryDayChange={meliplusOnDeliveryDayChange}
    >
      <Iframe
        title={action.label ? action.label.text : null}
        onMessage={onIframeModalResize(
          'nav:cp:updateSize',
          'height',
          '.ui-pdp-iframe-modal',
          '.andes-modal__header',
          'width',
          deviceType,
        )}
        src={actionTarget}
        scrolling="no"
        width="100%"
      />
    </ActionModal>
  );
};

const GAP = -16;

const ShippingSummary = ({
  action = null,
  action_tooltip,
  className = null,
  id = null,
  isFetching = false,
  subtitles = null,
  title,
  information_tooltip = null,
  remaining = null,
  onShippingPromiseUpdate = null,
  custom_message = null,
  tooltip = null,
  num_font_size = PriceFontSize.MEDIA_SUBTITLE,
  onCloseModal,
  meliplusUpsellingOnSuscription,
  meliplusOnDeliveryDayChange,
  noTitle,
  shipping_disclaimer = null,
  runCatchErrorBoundary = () => {},
  ...rest
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { cpCookie, coachMarkCookie, deviceType, page, site } = useContext(StaticPropsContext);
    const tooltipProps = useTooltipProps(information_tooltip);

    const cardTooltip = getCoachCardTooltipsProps(action_tooltip);
    const trackPage = page || 'vip';

    const isShops = isMshops({ platform: { id: site } });

    const sendShippingCoachTracks = isShops && (coachMarkCookie || cardTooltip?.id);

    const mobileShippingCoachTracks =
      deviceType === 'mobile' && isShops
        ? getEventClickShippingCalculateTrack(trackPage, sendShippingCoachTracks, cpCookie)
        : null;
    if (action && mobileShippingCoachTracks) {
      action.mobileShippingCoachTracks = mobileShippingCoachTracks;
    }

    return (
      <>
        {isSummaryTitleValid(title) && (
          <TooltipWrapper
            className="ui-vpp-shipping_summary"
            tooltip={tooltipProps ? { ...tooltipProps, offsetX: GAP, offsetY: undefined } : null}
          >
            <Shipping
              action={
                action
                  ? getAction(
                      action,
                      deviceType,
                      onCloseModal,
                      meliplusUpsellingOnSuscription,
                      meliplusOnDeliveryDayChange,
                      noTitle,
                    )
                  : null
              }
              className={classnames(`${namespace}--md`, className, colornames(title.color))}
              title={{ ...title, onHover: tooltip }}
              subtitles={subtitles}
              remaining={remaining}
              onShippingPromiseUpdate={onShippingPromiseUpdate}
              num_font_size={num_font_size}
              {...rest}
              isFetching={isFetching}
              showChevron={!!tooltip && tooltip.behaviour !== 'FIXED'}
              tooltip={tooltip}
              card_tooltip={cardTooltip}
              showFetchingSpinner={isFetching && deviceType === 'desktop' && id !== 'second_shipping_summary'}
              upsellingMeliPlusCashback={id === 'upselling_meliplus_cashback'}
            />
          </TooltipWrapper>
        )}
        {custom_message ? (
          <Message
            className={`${namespace}__message`}
            {...{
              closable: custom_message.closable,
              hierarchy: custom_message.hierarchy,
              type: custom_message.message_type,
            }}
          >
            <div className={`${namespace}__message__text`}>{custom_message.body.text}</div>
          </Message>
        ) : null}
        {shipping_disclaimer && (
          <CardTooltip
            show={shipping_disclaimer}
            closeable={shipping_disclaimer.closeable}
            className="ui-pdp-shipping-disclaimer"
            title={shipping_disclaimer.title}
            has_shadow={shipping_disclaimer.has_shadow}
            arrow_type={shipping_disclaimer.arrow_type}
            storable={shipping_disclaimer.storable}
            id={shipping_disclaimer.id}
            component_id={shipping_disclaimer.component_id}
          />
        )}
      </>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

ShippingSummary.propTypes = shippingSummaryPropTypes;

export default ShippingSummary;
