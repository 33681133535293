import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'card_with_thumbnails';

const CARD_WITH_THUMBNAILS_PROPS = {
  id: FEATURE_NAME,
};
const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }, ownProps) => ({ ...props, ...ownProps });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...CARD_WITH_THUMBNAILS_PROPS,
});

const noFeatureFlagConnect = connect(
  serializeOwnProps,
  null,
  `no-ff-${FEATURE_NAME}`,
);

const cardWithThumbnails = CardWithThumbnails => enhance(CardWithThumbnails);
const noFeatureFlag = CardWithThumbnails => noFeatureFlagConnect(CardWithThumbnails);

export default cardWithThumbnails;
export { noFeatureFlag };
