import {
  SHOW_QUANTITY_ERROR,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  QUANTITY_SHOW_INPUT_CHANGE,
  FETCH_ON_SET_QUANTITY_START,
  FETCH_SHIPPING_PROMISE_START,
  FETCH_SHIPPING_PROMISE_COMPLETE,
} from '../../../../actions/actions';

const showQuantityError = state => {
  let errorMessage;
  if (state.components.available_quantity.picker.error_message) {
    errorMessage = {
      ...state.components.available_quantity.picker.error_message,
      is_deferred: state.components.available_quantity.picker.error_message.is_deferred
        ? null
        : state.components.available_quantity.picker.error_message.is_deferred,
    };
  }
  return {
    ...state,
    components: {
      ...state.components,
      available_quantity: {
        ...state.components.available_quantity,
        picker: {
          ...state.components.available_quantity.picker,
          border_color: errorMessage ? 'red' : null,
          error_message: errorMessage,
        },
      },
    },
  };
};

const fetchOnSetQuantityComplete = (state, action) => ({
  ...state,
  parentUrl: action.payload.parentUrl,
  selected_offer_type: action.payload.selected_offer_type,
  components: {
    ...state.components,
    ...action.payload.components,
    payment_summary: {
      styles: state.components.payment_summary.styles,
      ...action.payload.components.payment_summary,
    },
    ...(action.payload.installations
      ? {
          installations: {
            ...action.payload.installations,
          },
        }
      : {}),
  },
  buttonFocus: true,
  preload: action.payload.preload,
});

const quantityShowInputChange = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    available_quantity: {
      ...state.components.available_quantity,
      showInput: action.payload.show,
    },
  },
});

const fetchOnSetQuantityStart = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    available_quantity: {
      ...state.components.available_quantity,
      isFetching: true,
      quantity_selector: {
        ...state.components.available_quantity?.quantity_selector,
      },
      selected: action.payload.quantity,
    },
    shipping_summary: {
      ...state.components.shipping_summary,
      isFetching: true,
    },
    second_shipping_summary: {
      ...state.components.second_shipping_summary,
      isFetching: true,
    },
    meliplus_summary: {
      ...(state.components.meliplus_summary || { state: 'hidden' }),
      isFetching: true,
    },
    upselling_meliplus_summary: {
      ...(state.components.upselling_meliplus_summary || { state: 'hidden' }),
      isFetching: true,
    },
    upselling_meliplus_cashback: {
      ...(state.components.upselling_meliplus_cashback || { state: 'hidden' }),
      isFetching: true,
    },
    pick_up_summary: {
      ...state.components.pick_up_summary,
      isFetching: true,
    },
    returns_summary: {
      ...state.components.returns_summary,
      isFetching: true,
    },
    installations: {
      ...state.components.installations,
      isFetching: true,
    },
    generic_banner_price: {
      ...state.components.generic_banner_price,
      isFetching: true,
    },
    generic_banner_installments: {
      ...state.components.generic_banner_installments,
      isFetching: true,
    },
  },
  buttonFocus: false,
});

const quantityMapper = {
  [QUANTITY_SHOW_INPUT_CHANGE]: quantityShowInputChange,
  [FETCH_ON_SET_QUANTITY_START]: fetchOnSetQuantityStart,
  [FETCH_SHIPPING_PROMISE_START]: fetchOnSetQuantityStart,
  [FETCH_ON_SET_QUANTITY_COMPLETE]: fetchOnSetQuantityComplete,
  [FETCH_SHIPPING_PROMISE_COMPLETE]: fetchOnSetQuantityComplete,
  [SHOW_QUANTITY_ERROR]: showQuantityError,
};

export { fetchOnSetQuantityComplete, showQuantityError, quantityShowInputChange, fetchOnSetQuantityStart };
export default quantityMapper;
