import React from 'react';
import { string, shape, func } from 'prop-types';
import { Image } from 'nordic/image';
import StyledLabel from '../styled-label';
import { trackEvent } from '../../lib/tracking';
import IconFactory from '../icons/factory-builder';
import applyClasses from '../../lib/apply-classes';

const namespace = 'ui-vpp-header-top-brand';

const HeaderTopBrand = ({
  title,
  sub_title,
  logo,
  url,
  background,
  track,
  official_store_icon,
  runCatchErrorBoundary,
}) => {
  try {
    return (
      <div className={namespace}>
        <div className={`${namespace}__bg-image`}>
          <div className={`${namespace}__image-wrapper`}>
            <Image src={background} alt="" role="presentation" lazyload="off" preload />
          </div>
          <div className={`${namespace}__logo-container`}>
            <a href={url} onClick={() => trackEvent(track)}>
              <div className={`${namespace}-card`}>
                <div className={`${namespace}-card__image-container`}>
                  <Image
                    alt=""
                    className={`${namespace}-card__image-container__image`}
                    src={logo?.url?.src}
                    {...logo}
                  />
                </div>
                <div className={`${namespace}-card__title-container`}>
                  <div className={`${namespace}-card__title-and-icon`}>
                    <p
                      className={applyClasses({ size: title.font_size, family: title.font_family, color: title.color })}
                      data-testid="apparel-size-specs__label"
                    >
                      {title.text}
                      {official_store_icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
                    </p>
                  </div>
                  <StyledLabel as="p" {...sub_title} />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

HeaderTopBrand.propTypes = {
  title: shape({
    text: string,
    color: string,
    font_size: string,
  }).isRequired,
  official_store_icon: string,
  sub_title: shape({
    text: string,
    color: string,
    font_size: string,
  }).isRequired,
  logo: string.isRequired,
  url: string,
  background: string.isRequired,
  track: shape({}),
  runCatchErrorBoundary: func,
};

HeaderTopBrand.defaultProps = {
  title: null,
  logo: null,
  url: null,
  background: null,
  sub_title: null,
  track: null,
  runCatchErrorBoundary: () => {},
};

export default HeaderTopBrand;
