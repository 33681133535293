import React from 'react';
import { string, shape, bool, number, arrayOf, oneOfType, func } from 'prop-types';
import { Banner as AdvertisingBanner } from 'advertising-banners-frontend';
import classNames from 'classnames';
import useShowLine from './hooks';

const namespace = 'ui-vpp-advertising';

const Advertising = ({
  googleAd,
  segmentation,
  slotId,
  className,
  viewportMargin,
  personalDataAdsDenied,
  caption,
  title,
  melidataEvent,
  loadSecondAd,
  userConsentCookie,
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const [adLoaded, setAdLoaded] = React.useState(false);
    const showLine = useShowLine(adLoaded, className);
    const handleAdLoad = () => {
      setAdLoaded(true);
    };

    const handleAdLoadSecondAd = () => {
      setAdLoaded(true);
    };

    const userConsentCookieAd = userConsentCookie?.find(category => category === 'advertising')?.consent;

    return (
      <div id={className} className={classNames('container-advertising', className, { loaded: adLoaded })}>
        {title && adLoaded && <h2 className="title-advertising">{title.text}</h2>}
        <AdvertisingBanner
          slotId={slotId}
          googleAd={googleAd}
          viewportMargin={viewportMargin}
          personalDataAdsDenied={personalDataAdsDenied}
          segmentation={segmentation}
          className={classNames(namespace, className)}
          caption={caption}
          onAdLoad={handleAdLoad}
          melidataTrack={melidataEvent}
          userConsentCookie={userConsentCookieAd}
        />
        {loadSecondAd && (
          <>
            <div className={classNames(namespace, className, { loaded: showLine }, 'line')} />
            <AdvertisingBanner
              slotId={`${slotId}_second`}
              googleAd={googleAd}
              viewportMargin={viewportMargin}
              personalDataAdsDenied={personalDataAdsDenied}
              segmentation={segmentation}
              className={classNames(namespace, className, ` ${namespace}--second `)}
              caption={caption}
              onAdLoad={handleAdLoadSecondAd}
              userConsentCookie={userConsentCookieAd}
              data-testid="second-ad"
            />
          </>
        )}
      </div>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Advertising.propTypes = {
  className: string,
  googleAd: shape({
    unit: string,
    size: oneOfType([string, arrayOf(number)]),
    ppid: string,
    enabled: bool,
  }).isRequired,
  segmentation: shape({
    custID: string,
    Posiciones: string,
    platform: string,
    CAR_BRAND: string,
    Model: string,
    VEHICLE_YEAR: string,
    ITEM_CONDITION: string,
  }).isRequired,
  title: shape({
    color: string,
    font_family: string,
    font_size: string,
    text: string,
  }),
  loadSecondAd: bool,
  melidataEvent: shape({
    path: string,
    event_data: shape(),
    experiments: shape(),
  }),
  slotId: string.isRequired,
  viewportMargin: number,
  personalDataAdsDenied: bool,
  caption: string,
  userConsentCookie: shape({
    category: string,
    consent: bool,
  }),
  runCatchErrorBoundary: func,
};

Advertising.defaultProps = {
  className: '',
  viewportMargin: null,
  personalDataAdsDenied: false,
  caption: null,
  title: null,
  melidataEvent: null,
  loadSecondAd: false,
  runCatchErrorBoundary: () => {},
};

export default Advertising;
