import React from 'react';
import { arrayOf, shape, string, oneOf, func } from 'prop-types';
import classNames from 'classnames';
import BreadcrumbAndes, { BreadcrumbLevel } from '@andes/breadcrumb';
import { trackEvent } from '../../lib/tracking';

const namespace = 'ui-pdp-breadcrumb';

const DOTS = '...';
const maxChars = 82; // Amount of `A` characters that fit inside the breadcrumb section.
const chevronSize = 3; // measured in `A` characters
const ellipsisSize = 2; // measured in `A` characters
const minCategoriesSize = 5; // This also configures max items showed in "compact" mode.

const getFirstVisibleCategory = categories => {
  if (categories.length > minCategoriesSize) {
    let charsLength = (categories.length - 1) * chevronSize + categories.length * ellipsisSize;
    const newCategories = Array.from(categories);
    return newCategories.reduceRight((index, elem) => {
      charsLength += elem.label.text.length - ellipsisSize;
      if (charsLength < maxChars) {
        return index - 1;
      }
      return index;
    }, categories.length - 1);
  }
  return 0;
};

const isDotForCompactMode = (i, length) => length > minCategoriesSize && i > 0 && i < length - (minCategoriesSize - 1);
const isDotForFullMode = (i, firstVisibleCategory) => i < firstVisibleCategory;

const getTextOrDots = (mode, firstVisibleCategory, text, i, length) => {
  const isDot = mode === 'compact' ? isDotForCompactMode(i, length) : isDotForFullMode(i, firstVisibleCategory);
  return isDot ? DOTS : text;
};

const formatCategories = (categories, mode) => {
  const firstVisibleCategory = getFirstVisibleCategory(categories);
  return categories.map((category, i) => ({
    href: category.target,
    text: getTextOrDots(mode, firstVisibleCategory, category.label.text, i, categories.length),
    title: category.label.text,
    id: `${category.label.text}-${i}`,
  }));
};

const Breadcrumb = ({ className, categories, back: action, mode, ariaLabel, runCatchErrorBoundary }) => {
  try {
    const formattedCategories = formatCategories(categories, mode);

    const onClickHandler = () => {
      trackEvent(action.track);
    };

    return (
      <div
        data-testid={namespace}
        className={classNames(namespace, className, { [`${namespace}--compact`]: mode === 'compact' })}
      >
        {action && (
          <a className={`${namespace}__link`} href={action.target} onClick={onClickHandler}>
            {action.label.text}
          </a>
        )}
        <BreadcrumbAndes srLabel={ariaLabel}>
          {formattedCategories.map(path => (
            <React.Fragment key={`breadcrumb-lvl-${path?.title}`}>
              <BreadcrumbLevel {...path} />
            </React.Fragment>
          ))}
        </BreadcrumbAndes>
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Breadcrumb.namespace = namespace;
Breadcrumb.formatCategories = formatCategories;

Breadcrumb.propTypes = {
  ariaLabel: string,
  className: string,
  categories: arrayOf(
    shape({
      target: string.isRequired,
      label: shape({ text: string.isRequired }).isRequired,
    }),
  ),
  back: shape({ target: string, label: shape({ text: string }) }),
  mode: oneOf(['full', 'compact']),
  runCatchErrorBoundary: func,
};

Breadcrumb.defaultProps = {
  ariaLabel: ' ',
  className: null,
  categories: [],
  back: null,
  mode: 'full',
  runCatchErrorBoundary: () => {},
};

export default Breadcrumb;
