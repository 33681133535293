import { bool, func, number, shape, string, arrayOf, oneOf, node } from 'prop-types';

// Quantity
export const quantityPropTypes = {
  className: string,
  picker: shape({
    title: string,
    selected_label: shape({
      text: string,
      color: string,
    }),
    border_color: string,
    description: string,
    disclaimer: shape({
      state: string,
      hierarchy: string,
      message_type: string,
      closeable: bool,
      body: shape({
        text: string,
      }),
    }),
  }),
  children: node.isRequired,
  hasCustomOptions: bool,
};

// Desktop
export const quantityDesktopPropTypes = {
  className: string,
  picker: shape({
    title: string,
    selected_label: shape({
      text: string,
      color: string,
    }),
    description: string,
    messages: arrayOf(
      shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
    ),
    track: shape({}),
  }).isRequired,
  type: oneOf(['label_component', 'available_quantity']).isRequired,
  label: shape({
    text: string.isRequired,
    font_family: string.isRequired,
    font_size: string.isRequired,
    color: string.isRequired,
  }),
  quantity_selector: shape({
    available_quantity: number,
    selector: shape({
      title: shape({
        text: string,
        color: string,
      }),
      template: shape({
        singular: string,
        plural: string,
        more: string,
        site_code: string,
        sales_unit: shape({
          unit: string,
          unit_measure: string,
          singular: string,
          plural: string,
        }),
        custom_options: arrayOf(
          shape({
            type: string,
            quantity: number,
            display_value: shape({
              quantity: shape({
                text: string,
                value: number,
                values: shape({
                  equivalence: shape({
                    text: string,
                  }),
                }),
              }),
              amount: shape({
                type: string,
                value: number,
                package_value: number,
                currency_id: string,
                decimal_style: string,
                suffix: shape({
                  text: string,
                  accessibility_text: string,
                }),
              }),
            }),
          }),
        ),
        volume_discount_options: arrayOf(
          shape({
            quantity: shape({
              text: string,
            }),
            amount: shape({
              fraction: string,
              cents: string,
              suffix: shape({
                text: string,
              }),
            }),
          }),
        ),
      }).isRequired,
      rows: number,
    }).isRequired,
    input: shape({
      title: shape({
        text: string,
        color: string,
      }),
      error_message: shape({
        text: string,
        color: string,
      }),
    }).isRequired,
  }),
  showQuantityInput: bool,
  showInput: bool,
  showInputLowend: bool,
  onSetQuantity: func.isRequired,
  onShowInput: func.isRequired,
  buttonFocus: bool,
  runCatchErrorBoundary: func,
};

// Mobile
export const quantityMobilePropTypes = {
  className: string,
  closeModalLabel: string,
  isFetching: bool,
  picker: shape({
    title: string.isRequired,
    selected: number.isRequired,
    selected_label: shape({
      text: string,
      color: string,
      font_family: string,
    }),
    description: string,
    messages: arrayOf(
      shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
    ),
    track: shape({}),
  }).isRequired,
  picker_message: shape({
    text: string.isRequired,
    color: string.isRequired,
  }),
  type: oneOf(['label_component', 'available_quantity']).isRequired,
  label: shape({
    text: string.isRequired,
    font_family: string.isRequired,
    font_size: string.isRequired,
    color: string.isRequired,
  }),
  quantity_selector: shape({
    available_quantity: number,
    selector: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      template: shape({
        singular: string,
        plural: string,
        more: string,
        site_code: string,
        sales_unit: shape({
          unit: string,
          unit_measure: string,
          singular: string,
          plural: string,
        }),
        custom_options: arrayOf(
          shape({
            type: string,
            quantity: number,
            display_value: shape({
              quantity: shape({
                text: string,
                value: number,
                values: shape({
                  equivalence: shape({
                    text: string,
                  }),
                }),
              }),
              amount: shape({
                type: string,
                value: number,
                package_value: number,
                currency_id: string,
                decimal_style: string,
                suffix: shape({
                  text: string,
                  accessibility_text: string,
                }),
              }),
            }),
          }),
        ),
        volume_discount_options: arrayOf(
          shape({
            quantity: shape({
              text: string,
            }),
            amount: shape({
              fraction: string,
              cents: string,
              suffix: shape({
                text: string,
              }),
            }),
          }),
        ),
      }).isRequired,
      rows: number,
    }).isRequired,
    input: shape({
      title: shape({
        text: string,
        color: string,
      }),
      confirm_button: shape({ label: shape({ text: string }) }),
      error_message: shape({
        text: string,
        color: string,
      }),
    }).isRequired,
  }),
  showInput: bool,
  showQuantityInput: bool,
  onSetQuantity: func.isRequired,
  onShowInput: func.isRequired,
  runCatchErrorBoundary: func,
};
