import mainMappers from './root';
import featureMappers from './features';
import hiQuantityMapper from '../vip/core/hi-quantity';

const rootMapper = {
  ...mainMappers,
  ...featureMappers,
  ...hiQuantityMapper,
};

export default rootMapper;
