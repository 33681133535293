import React from 'react';
import { string, number, shape, func } from 'prop-types';
import { Card, CardContent } from '@andes/card';
import { Image } from '@vpp-frontend-components/image';
import { ActionModalUI, withBusinessLogic } from '@vpp-frontend-components/action-modal';
import { getActionModalConfig } from './utils/getActionModalConfig';
import { Iframe } from '@vpp-frontend-components/iframe';
import onIframeModalResize from '../../../lib/on-iframe-modal-resize';
import useTrackView from '../../../hooks/use-track-view';

const namespace = 'ui-pdp-banner';

const ActionModal = withBusinessLogic(ActionModalUI, getActionModalConfig);

const BannerDesktop = ({
  className,
  picture,
  accessibility_text,
  onCloseModal,
  meliplusUpsellingOnSuscription,
  deviceType,
  viewport_track,
  height,
  width,
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { url, action } = picture;

    const ActionModalRef = React.useRef(null);
    const viewRef = useTrackView(viewport_track, { threshold: 0.45 });

    if (!url?.src) {
      return null;
    }

    const noTitle = false;

    return (
      <div className={namespace}>
        <button
          style={{ height: `${height}px`, width: `${width}px` }}
          type="button"
          className={`${className} ${namespace}-container`}
          onClick={() => ActionModalRef.current.openModal()}
          aria-label={accessibility_text}
        >
          <Card className={(className, namespace, `${namespace}-card`)} key={accessibility_text} ref={viewRef}>
            <CardContent className={`${namespace}-card-conten`}>
              <Image alt={accessibility_text} src={url.src} lazyload="off" preload className={`${namespace}-imagen`} />
            </CardContent>
          </Card>
        </button>
        <ActionModal
          modalClassName={`${namespace}__action-modal ui-pdp-iframe-modal ui-pdp-shipping-modal`}
          deviceType={deviceType}
          modalTitle={noTitle && false}
          onCloseModal={onCloseModal}
          meliplusUpsellingOnSuscription={meliplusUpsellingOnSuscription}
          track={action.track}
          customRef={ActionModalRef}
          maxWidth="100%"
        >
          <Iframe
            maxWidth="100%"
            title={action.label}
            onMessage={onIframeModalResize(
              'nav:cp:updateSize',
              'height',
              '.ui-pdp-iframe-modal',
              '.andes-modal__header',
              'width',
            )}
            src={action.target}
            scrolling="no"
          />
        </ActionModal>
      </div>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

BannerDesktop.propTypes = {
  className: string,
  viewport_track: shape().isRequired,
  onCloseModal: func,
  meliplusUpsellingOnSuscription: func,
  picture: shape({
    id: string,
    url: shape({ src: string }),
    width: number,
    height: number,
    alt: string,
    action: shape({ target: string, track: string }),
  }),
  accessibility_text: string,
  deviceType: string,
  width: number,
  height: number,
  runCatchErrorBoundary: func,
};

BannerDesktop.defaultProps = {
  id: null,
  className: null,
  picture: shape({
    id: null,
    url: shape({ src: null }),
    width: null,
    height: null,
    alt: null,
    tooltip: null,
    action: shape({ target: null, track: null }),
  }),
  accessibility_text: null,
  deviceType: null,
  width: null,
  height: null,
  runCatchErrorBoundary: () => {},
};
export default BannerDesktop;
