import { useState } from 'react';
import customOptions from '../../../../utils/quantity-custom-options';

const useQuantityValue = (hasCustomOptions, selectorTemplate, quantity, bulkSale, length) => {
  const [value, setValue] = useState(() => {
    if (hasCustomOptions) {
      return customOptions.verifyQuantity(quantity, selectorTemplate);
    }
    if (bulkSale || quantity > length) {
      return String(quantity);
    }
    return '';
  });

  return [value, setValue];
};

export default useQuantityValue;
