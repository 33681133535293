/* eslint-disable no-console */
/* UNMINIFIED RULES

const RULES = `
@keyframes noop {
  0% { zoom: 1; }
}

@-webkit-keyframes noop {
  0% { zoom: 1; }
}

.drift-zoom-pane.drift-open {
  display: block;
}

.drift-zoom-pane.drift-opening, .drift-zoom-pane.drift-closing {
  animation: noop 1ms;
  -webkit-animation: noop 1ms;
}

.drift-zoom-pane {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.drift-zoom-pane-loader {
  display: none;
}

.drift-zoom-pane img {
  position: absolute;
  display: block;
  max-width: none;
  max-height: none;
}

.drift-bounding-box {
  position: absolute;
  pointer-events: none;
}
`;

*/

const RULES =
  '.drift-bounding-box,.drift-zoom-pane{position:absolute;pointer-events:none}@keyframes noop{0%{zoom:1}}@-webkit-keyframes noop{0%{zoom:1}}.drift-zoom-pane.drift-open{display:block}.drift-zoom-pane.drift-closing,.drift-zoom-pane.drift-opening{animation:noop 1ms;-webkit-animation:noop 1ms}.drift-zoom-pane{overflow:hidden;width:100%;height:100%;top:0;left:0}.drift-zoom-pane-loader{display:none}.drift-zoom-pane img{position:absolute;display:block;max-width:none;max-height:none}';

export default function injectBaseStylesheet() {
  if (document.querySelector('.drift-base-styles')) {
    return;
  }

  const styleEl = document?.createElement('style');

  if (styleEl) {
    styleEl.type = 'text/css';
    styleEl?.classList.add('drift-base-styles');
    styleEl.appendChild(document.createTextNode(RULES));
  } else {
    console.error('InjectBaseStyleSheet: styleEl is null');
  }

  const head = document?.head;

  if (head) {
    const firstChild = head?.firstChild;
    if (firstChild && firstChild?.parentNode === head) {
      head?.insertBefore(styleEl, firstChild);
    } else {
      console.error('InjectBaseStyleSheet: firstChild is not a valid node. Appending to the end of the head.');
      head?.appendChild(styleEl);
    }
  } else {
    console.error('InjectBaseStyleSheet: head is null');
  }
}
