import React, { useMemo } from 'react';
import { func, number, string, shape, arrayOf } from 'prop-types';
import CarouselSnapped from '../../../carousel-snapped';
import getFilteredFigures from './events/get-filtered-figures';
import { PROVIDER, MULTIMEDIA_PROVIDERS } from '../../../figure/constants';

const GalleryCarousel = ({
  id: galleryId,
  figures,
  clipsConfig,
  galleryType,
  minHeight,
  maxHeight,
  shapes,
  accessibility_text,
  counter,
  setCounter,
  onTapFigure,
  app,
  overlayAlpha,
}) => {
  const MOBILE_PROVIDERS_ALLOWED = [PROVIDER.CLIP, PROVIDER.IMAGE, ...MULTIMEDIA_PROVIDERS];
  const filterFiguresForMobile = figures?.filter(item => MOBILE_PROVIDERS_ALLOWED.includes(item.provider));

  const filteredFigures = useMemo(
    () => getFilteredFigures({ filterFiguresForMobile, clipsConfig, galleryType, minHeight, onTapFigure, maxHeight }),
    [filterFiguresForMobile, clipsConfig, galleryType, minHeight, onTapFigure, maxHeight],
  );
  return (
    <CarouselSnapped
      key={galleryId}
      accessibility_text={accessibility_text}
      counter={counter}
      setCounter={setCounter}
      shapes={shapes}
      overlayAlpha={overlayAlpha}
      app={app}
    >
      {filteredFigures}
    </CarouselSnapped>
  );
};

GalleryCarousel.propTypes = {
  id: string.isRequired,
  figures: arrayOf(shape({})).isRequired,
  accessibility_text: string.isRequired,
  counter: number.isRequired,
  setCounter: func.isRequired,
  shapes: arrayOf(shape({})).isRequired,
  onTapFigure: func.isRequired,
  clipsConfig: shape({}),
  galleryType: string.isRequired,
  maxHeight: string,
  minHeight: string,
  app: string,
  overlayAlpha: string,
};
export default GalleryCarousel;
