import React, { useMemo } from 'react';
import useGallery from '../hooks/use-gallery';
import { node } from 'prop-types';

const GalleryPropsContext = React.createContext();

const GalleryProvider = ({ children, ...rest }) => {
  const { figures, pictures, clipsConfig } = rest;

  const {
    position,
    snap,
    isCurrentClips,
    isPreviousClips,
    isFullscreen,
    setCurrentIndex,
    setIsFullscreen,
  } = useGallery({
    items: figures || pictures || {},
    clipsConfig,
  });

  const providerProps = useMemo(
    () => ({
      position,
      snap,
      isCurrentClips,
      isPreviousClips,
      isFullscreen,
      setCurrentIndex,
      setIsFullscreen,
    }),
    [position, snap, isCurrentClips, isPreviousClips, isFullscreen, setCurrentIndex, setIsFullscreen],
  );

  return <GalleryPropsContext.Provider value={providerProps}>{children}</GalleryPropsContext.Provider>;
};

GalleryProvider.propTypes = {
  children: node.isRequired,
};

export default GalleryPropsContext;
export { GalleryProvider };
