// Solution about 'Loading Chunk Failed Error' based on: https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
const lazyComponentLoader = (lazyComponent, attempts = 2, delay = 500) =>
  new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (attempts === 1) {
            reject(error);
            return;
          }
          lazyComponentLoader(lazyComponent, attempts - 1, delay).then(resolve, reject);
        }, delay);
      });
  });

export default lazyComponentLoader;
