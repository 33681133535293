const OWNERSHIP_CORE = 'ownership_core';
const OWNERSHIP_RECOMMENDATIONS = 'ownership_recommendations';
const OWNERSHIP_CPG = 'ownership_cpg';
const OWNERSHIP_COMPATS = 'ownership_compats';
const OWNERSHIP_LOYALTY = 'ownership_loyalty';
const OWNERSHIP_APPAREL = 'ownership_apparel';

const ownershipMap = {
  // We use this in order to set the ownership of certains components
  // fit_as_expected_component_key: ownership_apparel, // Example
  // price: ownership_price_team, // Example
  carousel_cheaper: OWNERSHIP_RECOMMENDATIONS,
  carousel_v2p_not_active_item: OWNERSHIP_RECOMMENDATIONS,
  carousel_neighbour_top: OWNERSHIP_RECOMMENDATIONS,
  header_cpg: OWNERSHIP_CPG,
  compats_widget: OWNERSHIP_COMPATS,
  compats_feedback: OWNERSHIP_COMPATS,
  loyalty_collapsible_card: OWNERSHIP_LOYALTY,
  cpg_summary: OWNERSHIP_CPG,
  compats: OWNERSHIP_COMPATS,
  loyalty_tooltip: OWNERSHIP_LOYALTY,
  advertising_vip_right: OWNERSHIP_RECOMMENDATIONS,
  advertising_vip_right_mshops: OWNERSHIP_RECOMMENDATIONS,
  carousel_up_web: OWNERSHIP_RECOMMENDATIONS,
  carousel_above_swap: OWNERSHIP_RECOMMENDATIONS,
  carousel_above: OWNERSHIP_RECOMMENDATIONS,
  carousel_above_pads: OWNERSHIP_RECOMMENDATIONS,
  compatibilities: OWNERSHIP_COMPATS,
  apparel_size_specs_preview: OWNERSHIP_APPAREL,
  carousel_vis_cross_selling_acc: OWNERSHIP_RECOMMENDATIONS,
  carousel_pom_top: OWNERSHIP_RECOMMENDATIONS,
  carousel_header_pdp_inactive: OWNERSHIP_RECOMMENDATIONS,
  carousel_up: OWNERSHIP_RECOMMENDATIONS,
  carousel_above_seller: OWNERSHIP_RECOMMENDATIONS,
  meliplus_label_summary: OWNERSHIP_LOYALTY,
  advertising_pdp_right: OWNERSHIP_RECOMMENDATIONS,
  advertising_pdp_right_mshops: OWNERSHIP_RECOMMENDATIONS,
  advertising_pdp_right_mshops_bottom: OWNERSHIP_RECOMMENDATIONS,
  default: OWNERSHIP_CORE,
};

export default ownershipMap;
