import React from 'react';
import { RecommendationsComparatorDesktop } from '@recommendations-fe/comparator';
import RecommendationsIconFactory from './icon-factory';

const ComparatorDesktop = ({ runCatchErrorBoundary = () => {}, ...props }) => {
  try {
    return (
      <RecommendationsComparatorDesktop
        IconFactory={RecommendationsIconFactory}
        runCatchErrorBoundary={runCatchErrorBoundary}
        {...props}
      />
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

export default ComparatorDesktop;
