import React from 'react';
import classnames from 'classnames';
import { Message } from '@andes/message';
import StyledLabel from '../../styled-label';
import componentEnhance from '../../../lib/component-enhance';
import { validateSnackbarColor } from '../../../utils/validators';
import { quantityPropTypes } from './model';

const namespace = 'ui-pdp-buybox__quantity';

const Quantity = ({ children, className, picker, hasCustomOptions = false }) => (
  <div
    className={classnames(namespace, className, { [`${namespace}__custom-options`]: hasCustomOptions })}
    id="buybox_available_quantity"
  >
    {children}
    {picker && (
      <>
        <input type="hidden" name="quantity" value={picker.selected} />
        {picker.messages && (
          <div className={`${namespace}__messages`}>
            {picker.messages.map(message => (
              <StyledLabel
                className={classnames(`${namespace}__messages__message`)}
                key={message.text}
                as="p"
                {...message}
                text={componentEnhance.jsx(message.text, message.values)}
              />
            ))}
          </div>
        )}
        {picker.disclaimer && picker.disclaimer.state === 'VISIBLE' && (
          <div className={`${namespace}__disclaimer`}>
            <Message
              hierarchy={picker.disclaimer.hierarchy}
              color={validateSnackbarColor(picker.disclaimer.message_type)}
              closeable={picker.disclaimer.closeable}
            >
              <p>{picker.disclaimer.body.text}</p>
            </Message>
          </div>
        )}
      </>
    )}
  </div>
);

Quantity.propTypes = quantityPropTypes;

export default Quantity;
