export const getQuantityPickerRowsLength = (
  hasNoOptions,
  hasVolumeDiscount,
  volumeDiscountCount,
  selectorRowCount,
  minQuantity,
) => {
  if (hasNoOptions) {
    return hasVolumeDiscount ? volumeDiscountCount : selectorRowCount + minQuantity - 1;
  }
  return selectorRowCount;
};
