import snackbarMapper from './snackbar';
import fetchStatusMapper from './fetch-status';
import bookmarkMapper from './bookmark';
import variationsMapper from './variations';
import fetchDeferredMapper from './fetch-deferred';

const mainMappers = {
  ...snackbarMapper,
  ...fetchStatusMapper,
  ...bookmarkMapper,
  ...fetchDeferredMapper,
  ...variationsMapper,
};

export default mainMappers;
