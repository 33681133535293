import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import mapStateToProps from '../../../../components/recommendations/state-mapper-base';

const RecommendationsListHorizontal = loadable(() => import('../../../../components/recommendations/list-horizontal'));

const enhance = compose(
  withFeatureFlag(),
  connect(
    mapStateToProps(),
    null,
    'recos_list_horizontal',
  ),
);

export default enhance(RecommendationsListHorizontal);
