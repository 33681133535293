import React from 'react';
import { string, bool, node, oneOf, func } from 'prop-types';
import { Message as AndesMessage } from '@andes/message';
import classnames from 'classnames';
import { validateSnackbarColor } from '../../utils/validators';

const namespace = 'ui-pdp-message';
const namecolorspace = 'ui-pdp-background-color';

const Message = ({
  type,
  title,
  hierarchy,
  closeable,
  className,
  children,
  text,
  actionsPosition,
  actions,
  bgColor,
  runCatchErrorBoundary,
}) => {
  try {
    return (
      <AndesMessage
        className={classnames(namespace, className, {
          [`${namecolorspace}--${bgColor}`]: bgColor,
          [`${namespace}--closeable`]: closeable,
        })}
        color={validateSnackbarColor(type)}
        hierarchy={hierarchy}
        closable={closeable}
        title={title}
        actionsPosition={actionsPosition}
        actions={actions}
      >
        {text || children}
      </AndesMessage>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Message.propTypes = {
  className: string,
  text: string,
  type: oneOf(['neutral', 'success', 'warning', 'error']),
  title: string,
  hierarchy: string,
  closeable: bool,
  children: node,
  actionsPosition: string,
  actions: node,
  bgColor: string,
  runCatchErrorBoundary: func,
};

Message.defaultProps = {
  className: null,
  title: null,
  text: null,
  type: 'neutral',
  hierarchy: 'quiet',
  closeable: false,
  actions: null,
  actionsPosition: 'bottom',
  bgColor: null,
  runCatchErrorBoundary: () => {},
};

export default Message;
