const formatBulkQuantity = quantity => {
  if (!Number(quantity) || quantity === 0) {
    return '';
  }

  return quantity.toLocaleString('de-DE', {
    maximumFractionDigits: 2,
    useGrouping: false,
  });
};

export const parseBulkQuantity = (quantity, yieldValue) => Math.ceil(Number((quantity / yieldValue).toFixed(4)));

export default formatBulkQuantity;
