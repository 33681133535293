import { createContext, useState, useMemo } from 'react';
import { node } from 'prop-types';

const VariationsContext = createContext({});

const VariationsProvider = ({ children }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const state = useMemo(() => ({ selectedProduct, setSelectedProduct }), [selectedProduct, setSelectedProduct]);
  return <VariationsContext.Provider value={state}>{children}</VariationsContext.Provider>;
};

export { VariationsContext, VariationsProvider };

VariationsProvider.propTypes = {
  children: node.isRequired,
};
