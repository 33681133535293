import React from 'react';
import { MoneyAmount } from '@andes/money-amount';
import { componentEnhance } from '@vpp-frontend-components/component-enhance';

import { quantityInputCustomLabelPropTypes } from './model';

const QuantityInputCustomLabel = ({
  namespace,
  label: { text, values = {} },
  amount: { fraction, cents, currency_id, decimal_style, suffix },
}) => {
  const customOptionsClassName = `${namespace}__input__custom-options`;

  return (
    <div className={`${customOptionsClassName}-quantity`}>
      <div className={`${customOptionsClassName}-quantity__label ${customOptionsClassName}-quantity__label-quantity`}>
        {componentEnhance.jsx(text, values)}
      </div>
      <span
        data-testid="input-label-amount"
        className={`${customOptionsClassName}-quantity__label ${customOptionsClassName}-quantity__label-amount`}
      >
        <MoneyAmount
          size={16}
          value={{ fraction, cents }}
          suffix={suffix.text}
          currencyId={currency_id}
          centsType={decimal_style || 'superscript'}
        />
      </span>
    </div>
  );
};

QuantityInputCustomLabel.propTypes = quantityInputCustomLabelPropTypes;

export default QuantityInputCustomLabel;
