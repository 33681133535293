import {
  BOOKMARK_MODAL_IFRAME_CLOSE,
  WISHLIST_LIST_SELECTOR_SET_CURRENT_STEP,
  GIFT_REGISTRY_CELEBRATION_DATE_SET_CURRENT_STEP,
} from './constants';

export const contentWindowPostMessage = (ref, origin) => {
  ref.current.contentWindow.postMessage({ type: BOOKMARK_MODAL_IFRAME_CLOSE }, origin);
};

export const watchCurrentStep = (e, setCurrentStepInfos) => {
  const { method, args } = e?.data || {};
  if (method === WISHLIST_LIST_SELECTOR_SET_CURRENT_STEP) {
    setCurrentStepInfos(old => ({
      ...old,
      stepName: args?.stepName,
    }));
  }

  if (method === GIFT_REGISTRY_CELEBRATION_DATE_SET_CURRENT_STEP) {
    setCurrentStepInfos(old => ({
      ...old,
      stepName: args?.stepName,
      redirectUrl: args?.redirectUrl,
    }));
  }
};
