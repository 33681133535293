import { idToUrl } from '../../../lib/id-to-url';
import { PROVIDER } from '../../figure/constants';

const jsonToProps = enhancedContent => {
  const configurePicture = (media, pictureConfig) => {
    const { titleForAlt = '' } = enhancedContent;
    if (media.url) {
      return {
        src: media.url.src,
        src2x: media.url.src2x,
        width: media.width || 500,
        height: media.height || 500,
        provider: PROVIDER.IMAGE,
        alt: titleForAlt,
        a11yLabel: media.alt,
      };
    }

    const { template, template_2x: template2x } = pictureConfig || {};

    return {
      ...media,
      src: idToUrl(media.id, template, media.sanitized_title),
      src2x: template2x ? idToUrl(media.id, template2x, media.sanitized_title) : null,
      width: media.width || 500,
      height: media.height || 500,
      provider: PROVIDER.IMAGE,
      alt: titleForAlt,
      a11yLabel: media.alt,
    };
  };

  const { picture_config } = enhancedContent;
  const mediaContent = enhancedContent.media_content.map(media => {
    if (media.type === 'image') {
      return configurePicture(media, picture_config);
    }
    return {
      ...media,
    };
  });

  return {
    ...enhancedContent,
    viewPortTrack: enhancedContent.viewport_track,
    maxShowPixels: enhancedContent.max_show_pixels,
    media: mediaContent,
  };
};

export default jsonToProps;
