import React from 'react';
import { string, shape } from 'prop-types';
import classnames from 'classnames';
import useEnhanceVideo from '../hooks/use-enhance-video';
import { Icon } from '@vpp-frontend-components/icon';

export const EnhancedVideo = props => {
  const namespace = 'pdp-enhanced-content';
  const placeholderRef = React.useRef(null);
  const { media } = props;
  const {
    play,
    isOver,
    pause,
    forward,
    backward,
    isPlaying,
    isMounting,
    setIsOver,
    accesibilityPlayText,
  } = useEnhanceVideo(media, 'mobile', placeholderRef);

  return (
    <div className={`${namespace}__wrapper`} ref={placeholderRef} style={{ position: 'relative' }}>
      {!isMounting && !isPlaying && (
        <button type="button" onClick={play} className={`${namespace}__play-button`} title={accesibilityPlayText}>
          <Icon id="play_button" className={`${namespace}__play-icon`} />
        </button>
      )}
      {isOver && isPlaying && (
        <div className={`${namespace}__buttons`}>
          <button type="button" title="backwards" onClick={backward} className={`${namespace}__skipper`}>
            <Icon id="backwards" className={`${namespace}__backwards`} />
          </button>
          <button type="button" title="pause" onClick={pause}>
            <Icon id="pause" className={`${namespace}__pause`} />
          </button>
          <button type="button" title="forwards" onClick={forward} className={`${namespace}__skipper`}>
            <Icon id="forwards" className={`${namespace}__forwards`} />
          </button>
        </div>
      )}
      {isPlaying && (
        <button
          type="button"
          title="blacker"
          onClick={() => setIsOver(false)}
          className={classnames({ [`${namespace}__blacker--hidden`]: !isOver, [`${namespace}__blacker`]: isOver })}
        />
      )}
    </div>
  );
};

EnhancedVideo.propTypes = {
  media: shape({
    id: string.isRequired,
    alt: string.isRequired,
    src: string.isRequired,
    src2x: string,
  }).isRequired,
};

export default EnhancedVideo;
