import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import Variations from '../../../../components/variations/variations.desktop';
import { fetchComponents, setPickersCollapse } from '../../../../actions';

const FEATURE_NAME = 'outside_variations';
const FEATURE_AVAILABLE_QUANTITY_NAME = 'available_quantity';
const FEATURE_TRACK_NAME = 'track';

const mapStateToProps = ({
  components: {
    [FEATURE_NAME]: variations,
    [FEATURE_AVAILABLE_QUANTITY_NAME]: availableQuantity,
    [FEATURE_TRACK_NAME]: track,
  },
}) => ({
  ...variations,
  ...track,
  selectedQuantity:
    availableQuantity && availableQuantity.state !== 'HIDDEN'
      ? (availableQuantity.picker && availableQuantity.picker.selected) || 1
      : 1,
});

const mapDispatchToProps = dispatch => ({
  onSelectVariation: (productId, params, setIsLoading) =>
    dispatch(fetchComponents(productId, params, setIsLoading, null)),
  setPickersCollapse: pickerId => dispatch(setPickersCollapse(pickerId)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Variations);
