import get from 'lodash/get';

const getSelectedAttributes = components => {
  let attributes = get(components, 'variations.selected_attributes', null);

  if (!attributes) {
    attributes = get(components, 'outside_variations.selected_attributes', null);
  }
  if (attributes) {
    attributes = JSON.stringify(attributes).replace(/[{()}]/g, '');
    attributes = attributes.replace(/"/g, '');
  }
  return attributes;
};

export default getSelectedAttributes;
