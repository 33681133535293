import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'compats';

const mapStateToProps = ({ components: { [FEATURE_NAME]: compats } }, ownProps) => {
  const position = ownProps.position?.toUpperCase();
  return compats && ((!compats.position && ownProps.default) || compats.position === position) ? compats : null;
};

const mapDispatchToProps = dispatch => ({
  onSubmitForm: (selectedInput, updateState, deviceType) =>
    new Promise(resolve => {
      resolve(
        dispatch(actions.getCompatsProduct({ selectedInput, updateState, feature_name: FEATURE_NAME, deviceType })),
      );
    }),
  onRemove: (deviceType, updateState) =>
    new Promise(resolve => {
      resolve(dispatch(actions.cleanFormCompats({ feature_name: FEATURE_NAME, deviceType, updateState })));
    }),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    'compats_widget',
  ),
);

const component = Component => enhance(Component);

export default component;
