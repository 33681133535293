import { canUseDOM } from '../lib/dom-utils';
import boundaryConfigMap from './config/config-map';
import ownershipMap from './config/ownership-map';
import criticalComponents from './config/critical-components';
import DEFAULT_CONFIG from './config/default-config';
import fallbackComponentsMap from './components/fallback-components-map';
import { UNKNOWN_VALUE } from '../services/frontend-statsd/config/allowed-tags';

const getCatchErrorBoundaryConfig = ({ componentId, backendConfig = {}, extraConfig = {}, componentInfo }) => {
  try {
    // Get the component boundary config...
    const { mapComponentInfoToProps, ...componentConfig } = boundaryConfigMap[componentId] || boundaryConfigMap.default;
    const { isCritical: isCriticalFromConfig, ownership: ownershipFromConfig } = componentConfig;
    // Get the critical config...
    const backCriticalComponents = backendConfig?.criticalComponents || [];
    const isCritical = isCriticalFromConfig || [...criticalComponents, ...backCriticalComponents].includes(componentId);
    // Get the fallback and ownership config...
    const fallbackProps = componentInfo && mapComponentInfoToProps && mapComponentInfoToProps(componentInfo);
    const ownership = ownershipFromConfig || ownershipMap[componentId] || ownershipMap.default || UNKNOWN_VALUE;
    // Get the catch context...
    const catchContext = canUseDOM ? 'client_side' : 'server_side';

    return {
      ...componentConfig,
      ...extraConfig,
      componentId: componentId || UNKNOWN_VALUE,
      isCritical: isCritical === 'undefined' ? UNKNOWN_VALUE : isCritical,
      fallbackProps,
      FallbackComponent: fallbackProps && fallbackComponentsMap[componentId],
      ownership,
      catchContext: catchContext || UNKNOWN_VALUE,
    };
  } catch (error) {
    // Return the default config if something goes wrong
    return {
      ...DEFAULT_CONFIG,
      ...extraConfig,
      componentId: componentId || UNKNOWN_VALUE,
    };
  }
};

export default getCatchErrorBoundaryConfig;
