import React from 'react';
import { string, arrayOf, func } from 'prop-types';
import classnames from 'classnames';
import { generateKeyToComponent } from '../../utils/validators';

const namespace = 'ui-pdp-features';

const Features = ({ className, features, runCatchErrorBoundary }) => {
  try {
    return (
      <ul className={classnames(namespace, className)}>
        {features.map(feature => (
          <li key={generateKeyToComponent(feature)} className={`${namespace}__item`}>
            {feature}
          </li>
        ))}
      </ul>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Features.propTypes = {
  className: string,
  features: arrayOf(string),
  runCatchErrorBoundary: func,
};

Features.defaultProps = {
  className: null,
  features: null,
  runCatchErrorBoundary: () => {},
};

export default React.memo(Features);
