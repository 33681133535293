import React, { useContext, useEffect, useRef } from 'react';
import { bool, string, func } from 'prop-types';
import classnames from 'classnames';
import { CompatsDesktop } from '@ui-autoparts-web/compats';
import StaticPropsContext from '../../context/static-props';
import { isMshops } from '../../../utils/mercadoShopsUtils';
import { useGetPositionCompatsSticky } from '../../../hooks/use-get-position-compats-sticky';
import { cleanCookie } from '../../installation-service/utils/cookies';
import {
  COMPATS_COMPLETE,
  COMPATS_CUSTOM,
  COMPATS_MESSAGE,
  COMPATS_MESSAGE_TOP,
  COOKIE_AUTOCOMPLETE,
} from '../compats.constants';

const CompatsWidgetStickyContainer = ({ runCatchErrorBoundary, ...props }) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const containerCompatsSticky = useRef(null);
    const [isIntersecting] = useGetPositionCompatsSticky(containerCompatsSticky);
    const { site } = useContext(StaticPropsContext);
    const isShops = isMshops({ platform: { id: site } });
    const [isVisibleMessage, setIsVisibleMessage] = React.useState(false);
    const [key, setKey] = React.useState(0);

    useEffect(() => {
      cleanCookie(COOKIE_AUTOCOMPLETE);
    }, []);

    React.useEffect(() => {
      const handleStorageChange = ev => {
        if (ev.key === COMPATS_MESSAGE_TOP) {
          setIsVisibleMessage(sessionStorage.getItem(COMPATS_MESSAGE_TOP));
          setKey(prevKey => prevKey + 1);
        }
      };
      window.addEventListener('storage', handleStorageChange);
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }, []);

    return (
      props && (
        <div
          className={classnames(
            { 'ui-pdp-container__row--compats-widget-sticky': !isShops },
            { 'ui-pdp-container__row--compats-widget-sticky-mshop': isShops },
            { 'ui-pdp-compats--store': props.isOfficialStore },
            { 'ui-pdp-compats--intersecting': isIntersecting },
            { 'ui-pdp-compats--message': props.type === COMPATS_MESSAGE || props.type === COMPATS_CUSTOM },
            {
              'compats-message-hidden': props.class_name?.includes(COMPATS_COMPLETE) && !isVisibleMessage,
              'compats-message-visible': props.class_name?.includes(COMPATS_COMPLETE) && isVisibleMessage,
            },
          )}
          id="compats-widget-top"
          ref={containerCompatsSticky}
          key={key}
        >
          <CompatsDesktop {...props} />
        </div>
      )
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

CompatsWidgetStickyContainer.propTypes = {
  isOfficialStore: bool,
  isWidgetSticky: bool,
  class_name: string,
  type: string,
  runCatchErrorBoundary: func,
};

CompatsWidgetStickyContainer.defaultProps = {
  isOfficialStore: false,
  isWidgetSticky: false,
  class_name: null,
  type: null,
  runCatchErrorBoundary: () => {},
};

export default CompatsWidgetStickyContainer;
