import React from 'react';
import { arrayOf, string, shape, oneOfType, func } from 'prop-types';
import classNames from 'classnames';
import IconFactory from '../icons/factory-builder';
import ActionModal from '../action-modal/action-modal';
import Iframe from '../iframe';
import onIframeModalResize from '../../lib/on-iframe-modal-resize';
import StyledLabel from '../styled-label';

const namespace = 'ui-pdp-returns';

const Returns = ({ action, className, icon, subtitles, title, runCatchErrorBoundary }) => {
  try {
    return (
      <div className={classNames(namespace, className)}>
        <div className={`${namespace}__card`}>
          {icon && IconFactory(icon)}
          <div className={classNames(`${namespace}__card__main`)}>
            <StyledLabel as="h3" className={`${namespace}__card__main__title`} {...title} />
            {subtitles.map(subtitle => (
              <StyledLabel key={subtitle.text} className={`${namespace}__card__main__subtitle`} {...subtitle} />
            ))}
            {action && (
              <ActionModal
                modalClassName={classNames(`${namespace}__card__main__modal`, 'ui-pdp-iframe-modal', namespace)}
                modalUrl="#returns"
                url={action.target}
                label={action.label}
                linkClassName={`${namespace}__card__main__link`}
                modalTitle={action.modal_title}
                autoHeight={false}
                closeModalLabel={action.close_modal_label}
              >
                <Iframe
                  title={action.modal_title}
                  src={action.target}
                  scrolling="no"
                  onMessage={onIframeModalResize(
                    'vip:modal-content:loaded',
                    'height',
                    '.ui-pdp-iframe-modal',
                    '.andes-modal__header',
                  )}
                />
              </ActionModal>
            )}
          </div>
        </div>
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Returns.propTypes = {
  action: oneOfType([
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string.isRequired,
      modalTitle: string.isRequired,
    }),
  ]).isRequired,
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }).isRequired,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
    }),
  ),
  title: shape({
    text: string.isRequired,
    color: string,
  }).isRequired,
  runCatchErrorBoundary: func,
};

Returns.defaultProps = {
  className: null,
  subtitles: null,
  runCatchErrorBoundary: () => {},
};

export default Returns;
